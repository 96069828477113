import { MetaInfo } from "../model/BaseItem";
import { BaseStyle } from "../model/BaseStyle";
import { PaletteColor } from "./PaletteSet";
import StyleButton from "@/components/elemental/button/view/default/Style";
import { Button } from "@/components/elemental/button/Button";
import StyleImage from "@/components/elemental/image/view/default/Style";
import { Image } from "@/components/elemental/image/Image";
import StyleProductPreview from "@/components/product/productPreview/view/default/Style";
import { ProductPreview } from "@/components/product/productPreview/ProductPreview";

export enum ItemStyle{
  Primary = "Primary",
  Secondary = "Secondary"
}

class StyleSet{
  meta = {typeName: ""} as MetaInfo;
  primary = undefined as BaseStyle|undefined;
  secondary = undefined as BaseStyle|undefined;

  getStyle(type: ItemStyle): BaseStyle|undefined{
    if (type === ItemStyle.Primary){
      return this.primary;
    } else if (type === ItemStyle.Secondary){
      return this.secondary;
    }
  }

  from(styleSet: StyleSet){
    this.meta = styleSet.meta;

    if(styleSet.primary == undefined){
      this.primary = undefined;
    } else {
      this.primary = styleSet.primary.clone() as BaseStyle;
    }
    if(styleSet.secondary == undefined){
      this.secondary = undefined;
    } else {
      this.secondary = styleSet.secondary.clone() as BaseStyle;
    }
  }

  clone(): StyleSet{
    const newSet = new StyleSet({typeName: ""} as MetaInfo);
    newSet.from(this);
    return newSet;
  }

  constructor(meta: MetaInfo){
    this.meta = meta;
  }
}

export default class ItemStyleSet{
  name = "";

  maker = "MZ";
  version = "1.0";

  buttonSet = new StyleSet(Button.meta);
  imageSet = new StyleSet(Image.meta);
  productPreviewSet = new StyleSet(ProductPreview.meta);

  // Gather all members with type StyleSet
  public getSets(): StyleSet[]{
    const sets = [];
    for (const value of Object.values(this)) {
      if (value instanceof StyleSet){
        sets.push(value);
      }
    }
    return sets;
  }

  public getItemStyleNames(meta: MetaInfo): string[]{
    const found = this.getSets().find((set) => {
      return set.meta == meta;
    });

    if (found){
      return [ItemStyle.Primary, ItemStyle.Secondary];
    } else {
      return [];
    }
  }

  public getItemStyle(meta: MetaInfo, styleName: string): BaseStyle|undefined{
    for (const value of Object.values(this)) {
      if (value instanceof StyleSet && value.meta === meta){
        return value.getStyle(styleName as ItemStyle);
      }
    }
  }

  public clone(): ItemStyleSet{
    const newItemStyleSet = new ItemStyleSet(this.name);
    newItemStyleSet.buttonSet = this.buttonSet.clone();
    return newItemStyleSet;
  }

  constructor(name: string){
    this.name = name;

    // Button
    {
      const style1 = new StyleButton();
      style1.contained.color.styleVariantName=PaletteColor.B2;
      style1.font.size.setValue("16px");
      style1.font.color.styleVariantName=PaletteColor.F2;
      const style2 = new StyleButton();
      style2.contained.color.styleVariantName=PaletteColor.B2;
      style2.font.size.setValue("12px");
      style2.font.color.styleVariantName=PaletteColor.F2;

      this.buttonSet.primary = style1;
      this.buttonSet.secondary = style2;
    }

    // Image
    {
      const style1 = new StyleImage();
      style1.contained.border.radius.setValue("10px");
      this.imageSet.primary = style1;
      const style2 = new StyleImage();
      this.imageSet.secondary = style2;
    }

    // ProductPreview
    {
      const style1 = new StyleProductPreview();
      this.productPreviewSet.primary = style1;
      const style2 = new StyleProductPreview();
      this.productPreviewSet.secondary = style2;
    }
  }
}