import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78e61021"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ThemeSelectorContainer" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "ThemeButtonContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.refTheme.getThemeMetas(), (themeParam) => {
      return (_openBlock(), _createElementBlock("button", {
        key: themeParam.name,
        class: "ThemePreview",
        onClick: ()=>{$setup.refTheme.initWith(themeParam)},
        style: _normalizeStyle({backgroundColor: $setup.refTheme.getPaletteSet(themeParam.paletteSet).get($setup.refTheme.paletteNameActive)?.b1.value,
      color: $setup.refTheme.getPaletteSet(themeParam.paletteSet).get($setup.refTheme.paletteNameActive)?.f1.value})
      }, [
        _createElementVNode("div", {
          class: "ThemePreviewBar",
          style: _normalizeStyle({backgroundColor: $setup.refTheme.getPaletteSet(themeParam.paletteSet).get($setup.refTheme.paletteNameActive)?.b3.value})
        }, null, 4),
        _createElementVNode("h1", {
          class: "ThemeTitle",
          style: _normalizeStyle({fontFamily: themeParam.fontHeader, fontWeight: themeParam.fontHeaderWeight})
        }, _toDisplayString(themeParam.name), 5),
        _createElementVNode("p", {
          class: "ThemeText",
          style: _normalizeStyle({fontFamily: themeParam.fontParagraph, fontWeight: themeParam.fontParagraphWeight})
        }, " Theme ", 4),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ComponentWrapper, {
            item: $setup.getButtonWithStyle($setup.Predefined.getItemStyleSet(themeParam.styleSet).buttonSet.primary),
            context: $setup.getContextWithTheme(themeParam)
          }, null, 8, ["item", "context"])
        ])
      ], 12, _hoisted_2))
    }), 128))
  ]))
}