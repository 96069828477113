
import MixinProps from '@/components/shared/view/MixinProps';
import DefaultEditor from "@/components/shared/util/PropertyEditor.vue"
import { Ref, toRef } from 'vue';
import { Collection } from '../../Collection';

export default {
  name: 'CollectionLayoutEditor',
  mixins: [MixinProps],
  components: {
    DefaultEditor
  },
  setup(props: any){
    const refCollection = toRef(props, 'item') as Ref<Collection>;

    return{
      props,
      refCollection,
    }
  },
}
