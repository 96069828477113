
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ContentEditable from '@/components/shared/util/ContentEditable.vue';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import { PropertyUrlUnique } from './PropertyUrlUnique';

export default {
  mixins: [MixinProps],
  name: 'PropertyUrlUnique',
  components:{
    ComponentContainer,
    ContentEditable
  },
  setup(props: any){
    const { getRefItem, getRefContext } = useAddReactivity();
    const refPropertyUrlUnique = getRefItem<PropertyUrlUnique>(props);
    const refContext = getRefContext(props);

    return {
      refPropertyUrlUnique,
      refContext
    }
  }
}
