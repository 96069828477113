import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultEditor = _resolveComponent("DefaultEditor")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.refCollection.elements, (element) => {
    return (_openBlock(), _createElementBlock("div", {
      id: "Element",
      key: element.ref
    }, [
      _createVNode(_component_DefaultEditor, {
        item: element,
        context: $setup.props.context
      }, null, 8, ["item", "context"])
    ]))
  }), 128))
}