import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Contained = _resolveComponent("Contained")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createBlock(_component_ComponentContainer, {
    item: $setup.refLocation,
    context: $setup.refContext
  }, {
    body: _withCtx(() => [
      _createVNode(_component_Contained, {
        styleContained: $setup.refStyleContained,
        context: $setup.refContext,
        ifContainedContentInheritSize: true
      }, {
        body: _withCtx(() => [
          _createElementVNode("iframe", {
            width: "100%",
            height: "100%",
            style: _normalizeStyle([{"border":"0"}, $setup.computedStyleMap]),
            loading: "lazy",
            allowfullscreen: "",
            referrerpolicy: "no-referrer-when-downgrade",
            src: `https://www.google.com/maps/embed/v1/place?key=AIzaSyDmYK_KG-nT4qzsBSIhvRlG-ayAi5FdnxA &q=${$setup.refLocation.address.getValueOf($setup.refContext.languageOption)}`
          }, null, 12, _hoisted_1)
        ]),
        _: 1
      }, 8, ["styleContained", "context"])
    ]),
    _: 1
  }, 8, ["item", "context"]))
}