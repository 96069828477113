
import { Collection } from '@/components/elemental/collection/Collection';
import { Button } from '@/components/elemental/button/Button';
// import { BaseItem } from '@/components/shared/model/BaseItem';
import MixinProps from '@/components/shared/view/MixinProps';
import { computed, PropType, Ref, toRef } from 'vue';
import { BaseItem } from '@/components/shared/model/BaseItem';

export default {
  name: 'ItemToolBar',
  mixins: [MixinProps],
  props: {
    collection: { // collection, in which is the item
      type: Object as PropType<Collection>,
      required: true,
    },
    idx: { // index of item
      type: Number,
      required: true,
      deep: true,
    }
  },
  components: {
  },
  setup(props: any){
    const refItem = toRef(props, 'item') as Ref<BaseItem>;
    const refIdx = toRef(props, 'idx');
    const refCollection = toRef(props, 'collection') as Ref<Collection>;

    const computeIfDisplayEditButton = computed((): boolean => {
      // return true;
      if (props.context.selection.item == null)
        return false;
      const editLayout = props.context.mode.editLayout;
      const selectItem = props.context.mode.selectItem;
      const isRelatedItemSelected = props.context.selection.item.ref==refItem.value.ref;
      const containsSelectedItem = refItem.value.contains(props.context.selection.item).length>0;
      return editLayout && selectItem && (isRelatedItemSelected || containsSelectedItem);
    })

    const tryCreateAndInsertNewItemAt = (idx: number) => {
      refCollection.value.addNewElementAt(new Button("1"), idx);
    };

    return{
      props,
      refItem,
      refIdx,
      refCollection,
      computeIfDisplayEditButton,
      tryCreateAndInsertNewItemAt,
    }
  },
}
