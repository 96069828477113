import {defineComponent, ref} from "vue"
import { BaseItem } from "../model/BaseItem";

export default defineComponent({
  emits: ['layoutInitialized', 'layoutMinHeightChanged', 'layoutMinWidthChanged'],
});

export function useSerialLayoutInitialization(item: BaseItem, context: any) {
  const emitLayoutInitialized = () => {
    context.emit('layoutInitialized', item)
  };

  const emitLayoutMinHeightChanged = (item: BaseItem, newHeight: number) => {
    context.emit('layoutMinHeightChanged', item, newHeight);
  };

  const emitLayoutMinWidthChanged = (item: BaseItem, newWidth: number) => {
    context.emit('layoutMinWidthChanged', item, newWidth);
  };

  const refDomContainer = ref<HTMLElement|null>(null);
  const refDomContent = ref<HTMLElement|null>(null);

  return {
    emitLayoutInitialized,
    emitLayoutMinHeightChanged,
    emitLayoutMinWidthChanged,
    refDomContainer,
    refDomContent
  };
}