
import MixinProps from '@/components/shared/view/MixinProps';
import { StyleVariableColor } from '../StyleVariableColor';
import { Ref, computed, ref, toRef } from 'vue';
import Context from '../../view/Context';
import { ColorPicker } from 'vue-accessible-color-picker';

export default {
  mixins: [MixinProps],
  name: 'EditorStyleVariableColor',
  components: {
    ColorPicker
  },
  setup(props: any){
    const refStyleVariableColor = toRef(props, 'item') as Ref<StyleVariableColor>;

    const onActiveChange = (color: string | null)=>{
      if (color==null){
        return;
      }
      refStyleVariableColor.value.setValue(color);
    }

    const computedIfColorInTheme = computed(()=>{
      const colors = (props.context as Context).selection.app!.theme.getPalette().getColors();
      colors.forEach((color)=>{
        if (color === refStyleVariableColor.value)
          return true;
      });
      return false;
    });

    const refShowPicker = ref(false);
    const refShowPalette = ref(false);

    const togglePicker = ()=>{
      refShowPicker.value = !refShowPicker.value;
      if (refShowPicker.value){
        refShowPalette.value = false;
      }
    }

    const togglePalette = ()=>{
      refShowPalette.value = !refShowPalette.value;
      if (refShowPalette.value){
        refShowPicker.value = false;
      }
    }

    return {
      props,
      refStyleVariableColor,
      onActiveChange,
      computedIfColorInTheme,
      refShowPicker,
      refShowPalette,
      togglePicker,
      togglePalette,
    }
  }
}
