
import MixinProps from '@/components/shared/view/MixinProps';
import { Image } from '../../Image';
import { Ref, toRef } from 'vue';
import Context from '@/components/shared/view/Context';
import ImagePicker from '@/components/shared/util/ImagePicker.vue';
import PropertyEditor from "@/components/shared/util/PropertyEditor.vue";

export default {
  mixins: [MixinProps],
  name: 'ImageEditor',
  components: {ImagePicker, PropertyEditor},
  setup(props: any){
    const refImage = toRef(props, 'item') as Ref<Image>;
    const refContext = toRef(props, 'context') as Ref<Context>;

    return {
      refImage,
      refContext
    }
  }
}
