
import MixinProps from '@/components/shared/view/MixinProps';
import { StyleVariableString } from '../StyleVariableString';
import { Ref, onMounted, ref, toRef } from 'vue';
import Context from '../../view/Context';

export default {
  mixins: [MixinProps],
  name: 'EditorStyleVariableFontFamily',
  components: {
  },
  setup(props: any){
    const refStyleVariableString = toRef(props, 'item') as Ref<StyleVariableString>;
    const refContext = toRef(props, 'context') as Ref<Context>;
    const refHasEnum = ref(refStyleVariableString.value.enums.length>0) as Ref<boolean>;
    const refShowModal = ref(false) as Ref<boolean>;
    const refDomInput = ref(null) as Ref<HTMLInputElement|null>;


    const editString = ()=>{
      refShowModal.value = true;

      // focus after the input modal is opened
      // TODO: does not work for mobile safari
      setTimeout(()=>
        {
          console.log(refDomInput);
          if (refDomInput.value != null){
            refDomInput.value.focus();
            refDomInput.value.setSelectionRange(0, 9999);
          }
        },
        10
      );
    }

    const refShowingEnum = ref(true) as Ref<boolean>;
    // If no enum is selected, then show the actual value after init.
    const refShowValueAfterInitialization = ref(true);
    onMounted(() => {
      if (refStyleVariableString.value.enumOnly){
        refShowingEnum.value = true;
        refShowValueAfterInitialization.value = true;
      }

      const currentValue = refStyleVariableString.value.getValue();
      for (let i = 0; i<refStyleVariableString.value.enums.length; i++){
        if (refStyleVariableString.value.enums[i].value === currentValue){
          refShowValueAfterInitialization.value = false;
          refShowingEnum.value = true;
          return;
        }
      }
      refShowingEnum.value = false;
    });

    return {
      refStyleVariableString,
      refContext,
      refHasEnum,
      refShowModal,
      refDomInput,
      editString,
      refShowingEnum,
      refShowValueAfterInitialization,
    }
  }
}
