export const
enum GlobalDomId{
  EditorToolbarText = "GLOBAL_ID_EDITOR_TOOLBAR_TEXT",
}

export const
enum GlobalZIndex{
  EditorSelectionHighlight = 10,
  EditorDraggableSlider = 15,
  EditorBlockToolBar = 20,
  EditorToolbar = 30,
}