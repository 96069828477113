
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import { BaseDefinition } from '@/components/shared/model/BaseDefinition';
import MixinProps from '@/components/shared/view/MixinProps';
import GenericDefinitionEditor from '@/components/shared/util/GenericDefinitionEditor.vue'
import ComponentFactory from '@/components/ComponentFactory'
import { computed, ref, watch } from 'vue';
import { Container } from '@/components/elemental/container/Container';
import { BaseItem } from '@/components/shared/model/BaseItem';
import Context from '@/components/shared/view/Context';
import { Predefined } from '../theme/Predefined';
import { Button } from '@/components/elemental/button/Button';

export default {
  mixins: [MixinProps],
  name: 'StyleEditor',
  components: {
    GenericDefinitionEditor,
    ComponentWrapper
  },
  setup(props: any){
    const refProps = props as {item: BaseItem, context: Context};

    const refKeyEditor = ref(0);

    const getItemStyleNames = (): String[] => {
      return ComponentFactory.getViewFactoryStylesForItem(refProps.item);
    };

    const changeToStyle = async(styleName: string) => {
      const newStyle = await ComponentFactory.getStyleForItem(refProps.item, styleName);
      refProps.item.setStyle(newStyle);
      ++refKeyEditor.value;
    };

    const getContainer = () : Container | null => {
      if (refProps.item instanceof BaseDefinition){
        const subItemContainer = (refProps.item as BaseDefinition).getSubItemWithMeta(Container.meta);
        if (subItemContainer != null){
          return subItemContainer as Container;
        }
      }

      // const containers = props.context.selection.page.contains(refProps.item);
      // if (containers.length>0 && containers[1].getMeta() == Container.meta){
      //   return containers[1];
      // }

      return null;
    };

    const refParentContainer = ref<Container|null>(null);

    watch(
      () => refProps.item,
      () => {
        refParentContainer.value = getContainer();
      },
      {immediate: true}
    );

    const computedIfContainer = computed( () => {
      return refProps.item.getMeta().typeName==="Container";
    });

    watch(
      () => refProps.item.styleVariantName,
      () => {
        ++refKeyEditor.value;
      }
    )

    return{
      props,
      refProps,
      refKeyEditor,
      getItemStyleNames,
      changeToStyle,
      refParentContainer,
      computedIfContainer,
      Predefined,
      Button
    };
  },
}
