import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import { EnumPair, StyleVariableString } from "@/components/shared/style/StyleVariableString";
import Context from "@/components/shared/view/Context";
import StyleContained from "@/components/shared/style/StyleContained";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Product Gallery Style"};

  static readonly nameWidth: string = "Width";

  public width = new StyleVariableString(Style.nameWidth, "150px");
  public contained = new StyleContained;

  public getStoreObjectItem(): Object{
    return {
      width: this.width.getStoreObject(),
      contained: this.contained.getStoreObject()
    };
  }

  getClassNames(): any{
    // TODO: complete css class names here and in the Viewer
    return {
    }
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");

    this.width.enums = [
      new EnumPair("S", "150px"),
      new EnumPair("M", "175px"),
      new EnumPair("L", "200px"),
    ];

    this.width.setEnum("M");
  }

  from(item: BaseItem): void{
    super.from(item);
    this.setStyleName((item as BaseStyle).getStyleName());
    this.width.from((item as Style).width);
    this.contained.from((item as Style).contained);
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }

  getPredefinedStyleObject(context: Context) {
    throw new Error("ProductGallery default style getPredefinedStyleObject() not implemented");
  }
}