
import { Collection } from '@/components/elemental/collection/Collection';
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue'
import { Ref, toRef } from 'vue';

export default {
  name: 'CollectionViewer',
  mixins: [MixinProps],
  components: {ComponentWrapper},
  setup(props: any){
    const refCollection = toRef(props, 'item') as Ref<Collection>;

    return{
      props,
      refCollection,
    }
  },
}
