import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppEditor = _resolveComponent("AppEditor")!

  return ($setup.refApp)
    ? (_openBlock(), _createBlock(_component_AppEditor, {
        app: $setup.refApp,
        key: $setup.refAppId
      }, null, 8, ["app"]))
    : _createCommentVNode("", true)
}