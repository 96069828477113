
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import {Text} from '@/components/elemental/text/Text';
import { useAddReactivity } from '../view/AddReactivity';
import { BaseItem } from '../model/BaseItem';

export default {
  name: 'ContentEditor',
  mixins: [MixinProps],
  components: {
    ComponentWrapper,
  },
  setup(props: any){
    const { getRefItem, getRefContext } = useAddReactivity();
    const refItem = getRefItem<BaseItem>(props);
    const refContext = getRefContext(props);
    return{
      refItem,
      refContext,
      Text
    }
  },
}
