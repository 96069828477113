import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import { Collection } from "../../elemental/collection/Collection";
import { Button } from "../../elemental/button/Button";
import Style from "./view/default/Style";
import { Language } from '@/components/shared/view/LanguageOption';
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class AppBar extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "AppBar", friendlyName: "App Bar"};

  static readonly nameTitle: string = "Title";
  static readonly nameButtons: string = "Buttons";

  title = new PropertyString(AppBar.nameTitle);

  public getStoreObjectItem(): Object{
    return {
      title: this.title.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.title.fromStoreObject(item.title);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
    this.title.setValueOf(Language.default, "Snappy");

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const appBar = item as AppBar;
    this.title.from(appBar.title);
  }

  getMeta(): MetaInfo{
    return AppBar.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new AppBar();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}