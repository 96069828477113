import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectItemArea = _resolveComponent("SelectItemArea")!

  return (_openBlock(), _createElementBlock("div", {
    ondragstart: "return false;",
    class: _normalizeClass(["BlockContainer", $setup.props.fitContent ? 'FitContent' : 'InheritSize'])
  }, [
    _renderSlot(_ctx.$slots, "body", {}, undefined, true),
    _createVNode(_component_SelectItemArea, {
      item: $setup.refItem,
      context: $setup.props.context
    }, null, 8, ["item", "context"])
  ], 2))
}