
import MixinProps from '@/components/shared/view/MixinProps';
import { GlobalZIndex } from '@/shared/GlobalVariables';
import { Ref, toRef } from 'vue';
import { BaseItem } from '../model/BaseItem';

export default {
  mixins: [MixinProps],
  name: "ComponentContainer",
  setup(props: any) {
    const refItem = toRef(props, 'item') as Ref<BaseItem>;

    const ifHighlight = ()=>{
      return props.context.mode.selectItem &&
        refItem != null && props.context.selection.item != null &&
        refItem.value.ref == props.context.selection.item.ref;
    }

    const zIndexEditorSelectionHighlight = GlobalZIndex.EditorSelectionHighlight;

    return {
      props,
      refItem,
      ifHighlight,
      zIndexEditorSelectionHighlight,
    };
  },
};
