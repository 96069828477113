
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import Contained from '@/components/shared/util/Contained.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { Image } from '../../Image';
import StyleImage from './Style';
import StyleContained from "@/components/shared/style/StyleContained";
import { Ref, toRef, computed, onMounted, watch} from 'vue';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';

// variable
export default {
  name: 'ImageDefaultViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
    Contained,
  },
  setup(props: any, context: any){
    const { getRefItem, getRefContext, getRefStyle } = useAddReactivity();
    const refImage = getRefItem<Image>(props);
    const refContext = getRefContext(props);
    const refStyleImage = getRefStyle<StyleImage>(props);
    const refStyleContained = toRef(refStyleImage.value, 'contained') as Ref<StyleContained>;

    const { emitLayoutInitialized, emitLayoutMinHeightChanged } = useSerialLayoutInitialization(refImage.value, context);

    const onContentResized = (size: {width: number, height: number})=>{
      if (refStyleImage.value.objectFit.value === "Contain"){
        emitLayoutMinHeightChanged(refImage.value, size.height);
      }
    }

    watch(()=>refStyleImage.value.objectFit.value, ()=>{
      if (refStyleImage.value.objectFit.value === "Cover"){
        emitLayoutMinHeightChanged(refImage.value, 0);
      }
    })

    onMounted(()=>{
      emitLayoutInitialized();
    });

    const computedStyleImage = computed(()=>{
      let objectFit = {};
      if (refStyleImage.value.objectFit.value === "Contain")
        objectFit = {display: 'block', width: 'auto', maxWidth: '100%', height: 'auto', maxHeight: '100%', objectFit: 'contain'};
      else
        objectFit = {display: 'block', width: '100%', height: '100%', objectFit: 'cover'};

      return {
        // objectPosition: refStyleImage.value.objectPosition.value,
        ...objectFit
      }
    });

    return {
      refImage,
      refContext,
      refStyleImage,
      refStyleContained,
      computedStyleImage,
      onContentResized,
    };
  }
}
