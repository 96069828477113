import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import { StyleVariableString, EnumPair } from "./StyleVariableString";
import Context from "../view/Context";

export default
class StylePadding extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "StylePadding", friendlyName: "Padding"};

  static readonly nameTop: string = "Top";
  static readonly nameRight: string = "Right";
  static readonly nameBottom: string = "Bottom";
  static readonly nameLeft: string = "Left";

  public top = new StyleVariableString(StylePadding.nameTop, "0px");
  public bottom = new StyleVariableString(StylePadding.nameBottom, "0px");
  public left = new StyleVariableString(StylePadding.nameLeft, "0px");
  public right = new StyleVariableString(StylePadding.nameRight, "0px");

  public getStoreObjectItem(): Object{
    return {
      top: this.top.getStoreObject(),
      bottom: this.bottom.getStoreObject(),
      left: this.left.getStoreObject(),
      right: this.right.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.top.fromStoreObject(item.top);
    this.bottom.fromStoreObject(item.bottom);
    this.left.fromStoreObject(item.left);
    this.right.fromStoreObject(item.right);
  }

  constructor(){
    super();
    this.setItemName("StylePadding");

    const enums = [
      new EnumPair("0", "0px"),
      new EnumPair("S", "4px"),
      new EnumPair("M", "8px"),
      new EnumPair("L", "16px")
    ];

    this.top.enums = enums;
    this.bottom.enums = enums;
    this.left.enums = enums;
    this.right.enums = enums;

    this.top.setEnum("0");
    this.bottom.setEnum("0");
    this.left.setEnum("0");
    this.right.setEnum("0");
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const stylePadding = item as StylePadding;
    this.top.from(stylePadding.top);
    this.bottom.from(stylePadding.bottom);
    this.left.from(stylePadding.left);
    this.right.from(stylePadding.right);
  }

  getMeta(): MetaInfo{
    return StylePadding.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new StylePadding();
  }

  getPredefinedStyleObject(context: Context) : {paddingTop: string, paddingRight: string, paddingBottom: string, paddingLeft: string, boxSizing: string}{
    return {
      paddingTop: this.top.getValue(),
      paddingRight: this.right.getValue(),
      paddingBottom: this.bottom.getValue(),
      paddingLeft: this.left.getValue(),

      boxSizing: 'border-box',
    };
  }
}