
import MixinProps from '@/components/shared/view/MixinProps';
import SelectItemArea from '@/components/shared/util/SelectItemArea.vue';
import { Ref, toRef } from 'vue';
import { BaseItem } from '../model/BaseItem';

export default {
  mixins: [MixinProps],
  props: {
    fitContent: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  name: "BlockContainer",
  components: {
    SelectItemArea
  },
  setup(props: any) {
    const refItem = toRef(props, 'item') as Ref<BaseItem>;

    return {
      props,
      refItem
    };
  },
};
