import { BaseItemPreset } from "./BaseItemPreset";
import { PaletteColor } from "../PaletteSet";
import StyleButton from "@/components/elemental/button/view/default/Style";

export
class ButtonPreset extends BaseItemPreset{
  constructor(){
    super();
    {
      const styleButton = new StyleButton();
      styleButton.contained.color.styleVariantName=PaletteColor.B1;
      styleButton.contained.border.color.styleVariantName=PaletteColor.B2;
      styleButton.contained.border.width.value="4px";
      styleButton.font.size.setValue("16px");
      styleButton.font.color.styleVariantName=PaletteColor.F1;
      this.map.set("1", styleButton);
    }
    {
      const styleButton = new StyleButton();
      styleButton.contained.color.styleVariantName="";
      styleButton.contained.color.setValue("#FFFFFF00")
      styleButton.contained.border.color.styleVariantName=PaletteColor.B2;
      styleButton.contained.border.radius.value="0px";
      styleButton.contained.border.width.value="2px 0px 2px 0px";
      styleButton.font.size.setValue("16px");
      styleButton.font.color.styleVariantName=PaletteColor.F1;
      this.map.set("2", styleButton);
    }
  }
}