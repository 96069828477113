
import MixinProps from '@/components/shared/view/MixinProps';
import StyleFont from '../StyleFont';
import { Ref, toRef } from 'vue';
import Context from '../../view/Context';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';

export default {
  mixins: [MixinProps],
  name: 'EditorStyleFont',
  components: {ComponentWrapper},
  setup(props: any){
    const refStyleFont = toRef(props, 'item') as Ref<StyleFont>;
    const refContext = toRef(props, 'context') as Ref<Context>;
    return {
      refStyleFont,
      refContext
    }
  }
}
