import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d975234"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "refDomContainer",
  class: "ProductGalleryContainer InheritSize"
}
const _hoisted_2 = { class: "ProductGalleryContainer InheritSize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!
  const _component_BlockContainer = _resolveComponent("BlockContainer")!
  const _component_Contained = _resolveComponent("Contained")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ComponentContainer, {
      item: $setup.refProductGallery,
      context: $setup.refContext
    }, {
      body: _withCtx(() => [
        _createVNode(_component_Contained, {
          styleContained: $setup.refStyleContained,
          context: $setup.refContext,
          onContentResized: $setup.onContentResized
        }, {
          body: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                ref: "refDomContent",
                class: "ElementsArea HideScrollBar",
                style: _normalizeStyle({gridTemplateColumns: `repeat(auto-fit, minmax(${$setup.refStyle.width.getValue()}, 1fr))`})
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.refProductGallery.products, (productPreview) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: productPreview.ref,
                    class: "Element",
                    style: _normalizeStyle({width: $setup.refStyle.width.getValue(), height: '200px'})
                  }, [
                    _createVNode(_component_BlockContainer, {
                      item: productPreview,
                      context: $setup.refContext
                    }, {
                      body: _withCtx(() => [
                        _createVNode(_component_ComponentWrapper, {
                          item: productPreview,
                          context: $setup.refContext
                        }, null, 8, ["item", "context"])
                      ]),
                      _: 2
                    }, 1032, ["item", "context"])
                  ], 4))
                }), 128))
              ], 4)
            ])
          ]),
          _: 1
        }, 8, ["styleContained", "context", "onContentResized"])
      ]),
      _: 1
    }, 8, ["item", "context"])
  ], 512))
}