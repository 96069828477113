
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import Contained from '@/components/shared/util/Contained.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import { Ref, ref, toRef } from 'vue';
import { AppBar } from '../../AppBar';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import StyleAppBar from './Style';
import StyleFont from '@/components/shared/style/StyleFont';
import StyleContained from '@/components/shared/style/StyleContained';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faBars);

import { getPathForPage } from '@/Router';

// variable
export default {
  name: 'HeaderDefaultViewer',
  mixins: [MixinProps],
  components:{
    ComponentContainer,
    Contained,
    FontAwesomeIcon
  },
  setup(props: any){
    const { getRefItem, getRefContext, getRefStyle } = useAddReactivity();
    const refAppBar = getRefItem<AppBar>(props);
    const refContext = getRefContext(props);
    const refStyleAppBar = getRefStyle<StyleAppBar>(props);
    const refStyleContained = toRef(refStyleAppBar.value, 'contained') as Ref<StyleContained>;
    const refStyleFont = toRef(refStyleAppBar.value, 'font') as Ref<StyleFont>;

    const refShowModal = ref(false);

    const getLinkButtonStyle = ()=>{
      const font = refStyleAppBar.value.font.getPredefinedStyleObject(refContext.value);
      font.fontFamily = font.fontFamily!="" ? font.fontFamily : refContext.value.selection.app!.theme.fontButton;
      font.fontWeight = font.fontWeight!="" ? font.fontWeight : refContext.value.selection.app!.theme.fontButtonWeight;
      return {
        ...font
      };
    }

    const getMenuButtonStyle = ()=>{
      const font = refStyleAppBar.value.font.getPredefinedStyleObject(refContext.value);
      return {
        fontSize: font.fontSize,
        color: refStyleAppBar.value.font.color.getColor(refContext.value)
      };
    }

    return {
      refAppBar,
      refContext,
      refStyleContained,
      refStyleFont,
      refShowModal,
      getLinkButtonStyle,
      getMenuButtonStyle,

      getPathForPage
    };
  }
}
