import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1ccccf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "BlockSelector InheritSize Unselectable" }
const _hoisted_2 = { class: "BlockSelector-Category" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "BlockSelector-ScrollArea HideScrollBar" }
const _hoisted_5 = {
  class: "GlobalTextLabel",
  style: {"font-size":"36px","margin-bottom":"20px"}
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!
  const _directive_resize = _resolveDirective("resize")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.categories, (category) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["BlockSelector-Category-Button", $setup.refCategory===category ? 'BlockSelector-Category-Button_Selected' : '']),
          key: category,
          onClick: ()=>{$setup.refCategory=category;}
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["BlockSelector-Category-Button-Text", $setup.refCategory===category ? 'BlockSelector-Category-Button-Text_Selected' : ''])
          }, _toDisplayString(category), 3)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString($setup.refCategory), 1),
      _withDirectives((_openBlock(), _createElementBlock("div", {
        ref: "refDomContainer",
        class: "BlockSelector-ScrollArea-List",
        style: _normalizeStyle({gridTemplateColumns: `repeat(${$setup.refNumColumns}, 1fr)`})
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.templatesText, (template) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "BlockSelector-ScrollArea-List-Preview",
            key: template.name,
            onClick: ()=>{$setup.selectItem(template());}
          }, [
            _createVNode(_component_ComponentWrapper, {
              style: {"pointer-events":"none"},
              item: template(),
              context: $setup.refSelectorContext
            }, null, 8, ["item", "context"])
          ], 8, _hoisted_6))
        }), 128))
      ], 4)), [
        [_directive_resize, $setup.onContainerResized]
      ])
    ])
  ]))
}