import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Contained = _resolveComponent("Contained")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createBlock(_component_ComponentContainer, {
    item: $setup.refImage,
    context: $setup.refContext
  }, {
    body: _withCtx(() => [
      _createVNode(_component_Contained, {
        styleContained: $setup.refStyleContained,
        context: $setup.refContext,
        onContentResized: $setup.onContentResized,
        ifContainedContentInheritSize: $setup.refStyleImage.objectFit.value === 'Cover'
      }, {
        body: _withCtx(() => [
          _createElementVNode("img", {
            draggable: "false",
            src: $setup.refImage.url.getValueOf($setup.refContext.languageOption),
            alt: $setup.refImage.altText.getValueOf($setup.refContext.languageOption),
            class: "Unselectable",
            style: _normalizeStyle([{"margin-left":"auto","margin-right":"auto"}, $setup.computedStyleImage])
          }, null, 12, _hoisted_1)
        ]),
        _: 1
      }, 8, ["styleContained", "context", "onContentResized", "ifContainedContentInheritSize"])
    ]),
    _: 1
  }, 8, ["item", "context"]))
}