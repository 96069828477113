import { BaseVars } from "@/components/shared/model/BaseVars";
import { Language } from '@/components/shared/view/LanguageOption';
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import Style from "./view/default/Style";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export class Text extends BaseVars{
  static readonly meta: MetaInfo = {typeName: "Text", friendlyName: "Text"};

  public getStoreObjectItem(): Object{ return {}; }

  public fromStoreObjectItem(item: any){item;}

  constructor(itemName = "") {
    super();
    this.setItemName(itemName);

    this.values.set(Language.zh, '<h1 style="text-align: center;">你好</h1><p style="text-align: center;">滚滚长江东逝水，浪花淘尽英雄。是非成败转头空，青山依旧在，几度夕阳红。白发渔樵江渚上，惯看秋月春风。一壶浊酒喜相逢，古今多少事， 都付笑谈中。</p>');
    this.values.set(Language.default, '<h1 style="text-align: center;">Hello</h1><p style="text-align: center;">April is the cruellest month, breeding<br/>Lilacs out of the dead land, mixing<br/>Memory and desire, stirring<br/>Dull roots with spring rain.<br/>Winter kept us warm, covering<br/>Earth in forgetful snow, feeding<br/>A little life with dried tubers.</p>');

    this.setStyle(new Style);
  }

  public getMeta(): MetaInfo{
    return Text.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Text();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}