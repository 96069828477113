import { BaseItem, MetaInfo } from "../model/BaseItem";
import { BaseVar } from "../model/BaseVar";
import Context from "../view/Context";
import { PaletteColor } from "@/components/shared/theme/PaletteSet";

export class StyleVariableColor extends BaseVar{
  static readonly meta: MetaInfo = {typeName: "StyleVariableColor", friendlyName: "Color"};

  constructor(itemName = "", value = "") {
    super();
    this.setItemName(itemName);
    this.value = value;
    this.styleVariantName = "";

    this.setStyle(null);
  }

  public getColor(context: Context): string{
    if (this.styleVariantName.length>0){
      const paletteColorName = this.styleVariantName as PaletteColor;
      return context.selection.app!.theme.getPaletteColor(paletteColorName).getValue();
    }
    return this.getValue();
  }

  public getMeta(): MetaInfo{
    return StyleVariableColor.meta;
  }

  public override from(item: BaseItem): void{
    super.from(item);
    this.value = (item as StyleVariableColor).getValue();
  }

  public getDefaultInstance(): BaseItem{
    return new StyleVariableColor();
  }
}