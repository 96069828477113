
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { CustomWidget } from '../../CustomWidget';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import { Ref } from 'vue';

// variable
export default {
  name: 'CustomWidgetDefaultViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
  },
  setup(props: any, context: any){
    const { getRefItem, getRefContext } = useAddReactivity();
    const refCustomWidget = getRefItem<CustomWidget>(props) as Ref<CustomWidget>;
    const refContext = getRefContext(props);

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refCustomWidget.value, context);

    return {
      refCustomWidget,
      refContext,
      emitLayoutInitialized, //TODO: when to call emitLayoutInitialized?
    };
  }
}
