import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import Style from "./view/default/Style"
import { Collection } from "@/components/elemental/collection/Collection";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";

import ViewFactory from './view/ViewFactory';

export
class Page extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Page", friendlyName: "Page"};

  static readonly nameTitle: string = "Title";
  static readonly nameContent: string = "Content";

  static readonly nameMetaDescription: string = "Meta Description";

  title = new PropertyString(Page.nameTitle);
  content = new Collection(Page.nameContent);
  metaDescription = new PropertyString(Page.nameMetaDescription, true);

  public getStoreObjectItem(): Object{
    return {
      title: this.title.getStoreObject(),
      content: this.content.getStoreObject(),
      metaDescription: this.metaDescription.getStoreObject(),
    };
  }

  public fromStoreObjectItem(item: any){
    this.title.fromStoreObject(item.title);
    this.content.fromStoreObject(item.content);
    this.metaDescription.fromStoreObject(item.metaDescription);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    this.setStyle(new Style);

    /*
    this.title.setValueOf(Language.default, "Page Title");
    this.metaDescription.setValueOf(Language.default, 'Snappy.page lets you build your own website with the help with AI!');
    this.metaDescription.setValueOf(Language.zh, '页面介绍');
    {
      const apple1 = new BlockText();

      const layout = new Layout("Layout");
      const left = new FluidContainer;
      left.elements = [];

      const iphoneText = new Text();
      iphoneText.setValueOf(Language.default, '<h1 style="text-align: center;"><span>Branding</span></h1><h2 style="text-align: center;"><span>Beyond.</span></h2>');
      left.push(new Spatial(1/12, 48, 10/12, 144, 144, 144), iphoneText);

      const iphoneButton = new Button();
      iphoneButton.setStyleVariantName("Primary");
      // iphoneButton.setStyle(new ButtonStyle()); // using context.selection.app!.theme.styles
      iphoneButton.title.setValueOf(Language.default, 'Learn More');
      left.push(new Spatial(4/12, 192, 4/12, 48, 0), iphoneButton);

      const iphoneImage = new Image();
      iphoneImage.url.setValueOf(Language.default, '/img/pictures/lighthouse.jpg');
      left.push(new Spatial(1/12, 288, 10/12, 336, 0), iphoneImage);

      const right = new FluidContainer;
      right.elements = [];

      const watchText = new Text();
      watchText.setValueOf(Language.default, '<h1 style="text-align: center;"><span>Life </span><span style="color: #e03e2d; font-size: 8pt;">Series 3</span></h1><h2 style="text-align: center;"><span>A leap ahead.</span></h2>');
      right.push(new Spatial(1/12, 48, 10/12, 144, 144, 144), watchText);

      const watchButton = new Button();
      watchButton.setStyleVariantName("Primary");
      // watchButton.setStyle(new ButtonStyle()); // using context.selection.app!.theme.styles
      watchButton.title.setValueOf(Language.default, 'Learn More');
      right.push(new Spatial(4/12, 192, 4/12, 48, 0), watchButton);

      const watchImage = new Image();
      watchImage.url.setValueOf(Language.default, '/img/pictures/sand.jpg');
      right.push(new Spatial(1/12, 288, 10/12, 336, 0), watchImage);

      layout.content = left;
      layout.morphToRowAndAddChild(right);

      apple1.container.content = layout;
      const containerStyle = new ContainerStyle();
      containerStyle.contained.backgroundMode.setEnum('Color');
      containerStyle.contained.image.setValue('');
      containerStyle.contained.color.setStyleVariantName(PaletteColor.B1);
      apple1.container.setStyle(containerStyle);

      this.content.addNewElement(apple1);

      this.content.addNewElement(new BlockText());
      this.setStyle(new Style);
    }
    */

    // console.log(JSON.stringify(this.getStoreObject(), null, " "))
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const page = item as Page;
    this.title.from(page.title);
    this.content.from(page.content);
  }

  getMeta(): MetaInfo{
    return Page.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Page();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}