import { BaseVars } from "@/components/shared/model/BaseVars";
import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";

export class PropertyString extends BaseVars{
  static readonly meta: MetaInfo = {typeName: "PropertyString", friendlyName: "Property"};

  multiLine = false;

  public getStoreObjectItem(): Object{
    return {
      multiLine: this.multiLine
    };
  }

  public fromStoreObjectItem(item: any){
    this.multiLine = item.multiLine;
  }

  constructor(itemName = "", multiLine = false) {
    super();
    this.setItemName(itemName);
    this.multiLine = multiLine;
  }

  public getMeta(): MetaInfo{
    return PropertyString.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new PropertyString();
  }
}