
import { PropType} from 'vue';
import Context from '@/components/shared/view/Context';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import { useServerDelegate } from '@/shared/UseServerDelegate';
import { PageNode } from '@/components/page/pageNode/PageNode';
import { Page } from '@/components/page/page/Page';
import { getPathForPage } from '@/Router';

export default {
  name: 'PageManager',
  props: {
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
  },
  components:{ComponentWrapper},
  setup(props: any){
    const refContext = props.context as Context;

    let pages = [] as string[];
    const {listPages, createPage} = useServerDelegate();
    listPages((pageList: string[])=>{pages = pageList;}, ()=>{});

    const contextView = new Context();
    contextView.selection = refContext.selection;
    contextView.mode.editContent=false;
    contextView.mode.editLayout=false;
    contextView.mode.editTextInline=false;

    const create = ()=>{
      const page = new Page();
      const pageString = JSON.stringify(page.getStoreObject());
      const pageNode = new PageNode();
      pageNode.pageRef.refId = page.ref;
      createPage(pageNode.pageRef.refId, pageString,
      ()=>{
        refContext.selection.app?.pageNodes.addNewElement(pageNode);
      },
      ()=>{});
    };

    const remove = (pageNode: PageNode) =>{
      refContext.selection.app?.pageNodes.removeElement(pageNode);
      // trashPage(pageNode.ref,
      // (value: Object)=>{
      //   value;
      //   updateApp(
      //     refContext.selection.app!.ref, JSON.stringify(refContext.selection.app!.getStoreObject()),
      //     (value: Object)=>{
      //       value;
      //     },
      //     ()=>{}
      //   );
      // },
      // ()=>{});
    };

    return{
      refContext,
      pages,
      contextView,

      create,
      remove,

      PageNode,

      getPathForPage
    }
  },
}
