import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ad0973f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "refDomContainer",
  class: "ImageGalleryContainer InheritSize"
}
const _hoisted_2 = { class: "ImageGalleryContainer InheritSize" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Contained = _resolveComponent("Contained")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!
  const _directive_resize = _resolveDirective("resize")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ComponentContainer, {
      item: $setup.refImageGallery,
      context: $setup.refContext
    }, {
      body: _withCtx(() => [
        _createVNode(_component_Contained, {
          styleContained: $setup.refStyleContained,
          context: $setup.refContext,
          onContentResized: $setup.onContentResized
        }, {
          body: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", {
                ref: "refDomContent",
                class: "ElementsArea HideScrollBar",
                style: _normalizeStyle({gridTemplateColumns: `repeat(${$setup.refNumColumns}, 1fr)`, gap: $setup.refStyle.gap.getValue()})
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.refImageGallery.images, (imagePreview) => {
                  return (_openBlock(), _createElementBlock("img", {
                    key: imagePreview.ref,
                    draggable: "false",
                    src: imagePreview.url.getValueOf($setup.refContext.languageOption),
                    alt: imagePreview.altText.getValueOf($setup.refContext.languageOption),
                    class: "Unselectable",
                    style: _normalizeStyle({display: 'block', width: '100%', height: 'auto', objectFit: 'cover', aspectRatio: $setup.refStyle.aspectRatio.getValue(), borderRadius: $setup.refStyle.borderRadius.getValue()})
                  }, null, 12, _hoisted_3))
                }), 128))
              ], 4)
            ])), [
              [_directive_resize, $setup.onContainerResized]
            ])
          ]),
          _: 1
        }, 8, ["styleContained", "context", "onContentResized"])
      ]),
      _: 1
    }, 8, ["item", "context"])
  ], 512))
}