import { BaseVar } from "@/components/shared/model/BaseVar";
import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";

export class PropertyUrlUnique extends BaseVar{
  static readonly meta: MetaInfo = {typeName: "PropertyUrlUnique", friendlyName: "Url"};

  constructor(itemName = "") {
    super();
    this.setItemName(itemName);
  }

  public getMeta(): MetaInfo{
    return PropertyUrlUnique.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new PropertyUrlUnique();
  }
}