
import { computed, onMounted, PropType, reactive, ref, watch } from 'vue';
import BlockContainer from '@/components/shared/util/BlockContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import Draggable from './Draggable.vue' // TODO: Unify with src/components/composed/imageWithText/view/shared/Draggable.vue
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, { useSerialLayoutInitialization } from '@/components/shared/view/SerialLayoutInitialization';
import { BaseItem } from '@/components/shared/model/BaseItem';
import { FluidContainer } from '../../FluidContainer';
import Context from '@/components/shared/view/Context';
import useUiFix from '@/components/shared/view/UiFix';
import { Text } from '@/components/elemental/text/Text';
import { FluidElement, ChangeType } from './FluidElement';

// variable
export default {
  name: 'FluidElement',
  mixins: [MixinProps, SerialLayoutInitialization],
  emits: ['activeSpatialChangeStarted', 'activeSpatialChangeFinished'],
  props: {
    element: {
      type: Object as PropType<FluidElement>,
      required: true,
    },
    container: {
      type: Object as PropType<FluidContainer>,
      required: true,
    },
    containerDomRef: {
      type: Object,
      required: false,
    },
    intervalHorizontal: {
      type: Number,
      required: true,
    },
    intervalVertical: {
      type: Number,
      required: true,
    },
    onFluidElementChanged: {
      type: Function,
      required: false,
    }
  },
  components: {
    BlockContainer,
    ComponentWrapper,
    Draggable
  },
  setup(props: any, context: any) {
    const refElement = reactive(props.element) as FluidElement;
    const refContext = props.context as Context;

    const refDomMenu = ref<HTMLElement|null>(null);
    const { emitLayoutInitialized, refDomContainer} = useSerialLayoutInitialization(props.item, context);

    onMounted(() => {
      // Initialize spatial
      // console.log(refElement.spatial.displayed.y, refElement.spatial.yDesired);
      refElement.spatial.displayed.from(refElement.spatial.desired);
      setTimeout(()=>{
        refElement.spatial.updateElementHeight(refDomContainer.value!.offsetWidth);
      }, 0); // next tick
      ///////////////////////////////
      // Disable swipe to navigate. Otherwise it will break the menu button.
      const { preventEdgeSwipe } = useUiFix();
      if (refDomMenu.value){
        preventEdgeSwipe(refDomMenu.value);
      }
      ///////////////////////////////
      const observer = new IntersectionObserver(entries => {
        entries;
        if(refDomContainer.value){
          refDomContainer.value.classList.toggle( 'Animation', entries[0].isIntersecting );
          // TODO: unobserve?
          // observer.unobserve(refDomContainer.value);
        }
      })
      observer.observe(refDomContainer.value!);
    });

    let timeLastUpdate = 0;
    watch(refElement.item, ()=>{
      timeLastUpdate = new Date().getTime();
    });

    // deriveFromAnchor: if the spatial, e.g. height, is changed passively, not by dragging.
    const fluidElementChanged = (deriveFromAnchor: boolean, ifChangeDesired: boolean = false, changeType: ChangeType) => {
      console.log(`fluidElementChanged: ${ChangeType[changeType]}`);
      props.onFluidElementChanged(refElement, deriveFromAnchor, ifChangeDesired);
    };


    watch(
      ()=>[refElement.spatial.ratio],
      ()=>{
        refElement.spatial.updateElementHeight(refDomContainer.value!.offsetWidth);
        fluidElementChanged(false, false, ChangeType.Ratio);
      },
    )

    watch(
      ()=>[refElement.spatial.ifStickToContentHeight],
      ()=>{
        refElement.spatial.updateElementHeight(refDomContainer.value!.offsetWidth);
        fluidElementChanged(false, false, ChangeType.ToggleStickToContentHeight);
      },
    )

    watch(
      ()=>[refElement.spatial.sourceAnchorVerticalPosition, refElement.spatial.targetAnchorVerticalPosition],
      ()=>{
        fluidElementChanged(true, false, ChangeType.Anchor);
      },
    )

    const onChildLayoutInitialized = () => {
      emitLayoutInitialized();
    }

    const ifDragging = ():boolean =>{
      return dragDistance>0;
    };

    const onLayoutMinHeightChanged = (item: BaseItem, newMinHeight: number) => {
      const oldSpatial = refElement.spatial.clone();
      refElement.spatial.contentHeight = newMinHeight;

      if (ifDragging()){
        return;
      }

      refElement.spatial.updateElementHeight(refDomContainer.value!.offsetWidth);

      if (refElement.spatial.equals(oldSpatial)){
        return;
      }

      if (refContext.selection.item?.ref === refElement.item.ref && refContext.selection.item?.getMeta() == Text.meta){
        // If the item is text and the change comes from text editing -> update position
        const timeHeightChanged = new Date().getTime();
        // console.log(timeHeightChanged, timeLastUpdate);
        if (timeHeightChanged - timeLastUpdate < 20){ // text change -> height change = about 10ms -> set to 20ms.
          fluidElementChanged(true, false, ChangeType.Text);
          return;
        }''
      }

      fluidElementChanged(true, false, ChangeType.MinContentHeight);
    };

    const onLayoutMinWidthChanged = (item: BaseItem, newWidth: number) => {
      refContentWidthPercent; item; newWidth;
    };

    // Initialize item content height to spatial height.
    // Text can change content height based on given width.
    const refContentWidthPercent = ref(refElement.spatial.contentWidth);

    const displayOnStartedDragging = refElement.spatial.displayed.clone();
    const onStartedDragging = () => {
      // console.log("activeSpatialChangeStarted");
      context.emit('activeSpatialChangeStarted', refElement);
      displayOnStartedDragging.from(refElement.spatial.displayed);
    };

    const onDraggedPosition = (id: number, offsetX: number, offsetY: number) => {
      dragDistance += Math.sqrt(offsetX*offsetX + offsetY*offsetY);

      const containerWidth = (props.containerDomRef as HTMLElement).getBoundingClientRect().width;
      /* eslint-disable vue/no-mutating-props */
      refElement.spatial.displayed.x = refElement.spatial.displayed.x + offsetX / containerWidth;
      refElement.spatial.displayed.x = refElement.spatial.displayed.x < 0 ? 0 : refElement.spatial.displayed.x;
      refElement.spatial.displayed.x = refElement.spatial.displayed.x + refElement.spatial.displayed.width > 1 ? 1 - refElement.spatial.displayed.width : refElement.spatial.displayed.x;
      refElement.spatial.displayed.y = refElement.spatial.displayed.y + offsetY / refContext.displayOption.scale;
      refElement.spatial.displayed.y = refElement.spatial.displayed.y < 0 ? 0 : refElement.spatial.displayed.y;

      if (refElement.spatial.ifHeightRatio){
        const containerHeight = (props.containerDomRef as HTMLElement).getBoundingClientRect().height;
        const maxY = containerHeight - refElement.spatial.displayed.height;
        refElement.spatial.displayed.y = Math.min(refElement.spatial.displayed.y, maxY);
      }
      /* eslint-enable vue/no-mutating-props */

      fluidElementChanged(true, false, ChangeType.DraggingPositionOrSize);
    };

    // Distance of dragging, reset after the dragging is complete.
    let dragDistance = 0;

    const onDraggedSize = (id: number, offsetX: number, offsetY: number) => {
      dragDistance += Math.sqrt(offsetX*offsetX + offsetY*offsetY);

      const containerWidth = (props.containerDomRef as HTMLElement).getBoundingClientRect().width;
      /* eslint-disable vue/no-mutating-props */
      refElement.spatial.displayed.width = refElement.spatial.displayed.width + offsetX / containerWidth;
      refElement.spatial.displayed.width = refElement.spatial.displayed.width < props.intervalHorizontal ? props.intervalHorizontal : refElement.spatial.displayed.width;
      refElement.spatial.displayed.width = refElement.spatial.displayed.x + refElement.spatial.displayed.width > 1 ? 1 - refElement.spatial.displayed.x : refElement.spatial.displayed.width;
      refElement.spatial.displayed.width = Math.max(refElement.spatial.displayed.width, refElement.spatial.contentWidth);
      /* eslint-enable vue/no-mutating-props */

      let newHeight = refElement.spatial.displayed.height + offsetY / refContext.displayOption.scale;
      newHeight = Math.max(newHeight, props.intervalVertical);
      if (refElement.spatial.ifHeightRatio){
        const containerHeight = (props.containerDomRef as HTMLElement).getBoundingClientRect().height;
        const maxHeight = containerHeight - refElement.spatial.displayed.y;
        newHeight = Math.min(newHeight, maxHeight);
      }
      refElement.spatial.setDisplayHeightSafely(newHeight, refDomContainer.value!.offsetWidth);

      fluidElementChanged(true, false, ChangeType.DraggingPositionOrSize);
    };

    const onDraggedPositionAndSize = (id: number, offsetX: number, offsetY: number) => {
      dragDistance += Math.sqrt(offsetX*offsetX + offsetY*offsetY);

      const containerWidth = (props.containerDomRef as HTMLElement).getBoundingClientRect().width;

      const exceedTopEdge = refElement.spatial.displayed.y + offsetY < 0;
      const exceedMinHeight = refElement.spatial.displayed.height - offsetY < props.intervalVertical;
      const exceedContentHeight = refElement.spatial.displayed.height - offsetY < refElement.spatial.contentHeight;
      if ( exceedTopEdge || exceedMinHeight || exceedContentHeight || refElement.spatial.ifShouldStickToContentHeight() ){
        offsetY = 0;
      }

      const exceedLeftEdge = refElement.spatial.displayed.x + offsetX / containerWidth < 0;
      const exceedMinWidth = (refElement.spatial.displayed.width - offsetX / containerWidth) < props.intervalHorizontal;
      const exceedContentWidth = (refElement.spatial.displayed.width - offsetX / containerWidth) < refElement.spatial.contentWidth;
      if ( exceedLeftEdge || exceedMinWidth || exceedContentWidth){
        offsetX = 0;
      }

      const offsetXPercent = offsetX / containerWidth;
      refElement.spatial.displayed.x = refElement.spatial.displayed.x + offsetXPercent;
      refElement.spatial.displayed.width = refElement.spatial.displayed.width - offsetXPercent;

      refElement.spatial.displayed.y = refElement.spatial.displayed.y + offsetY / refContext.displayOption.scale;

      const newHeight = refElement.spatial.displayed.height - offsetY / refContext.displayOption.scale;
      refElement.spatial.setDisplayHeightSafely(newHeight, refDomContainer.value!.offsetWidth);

      fluidElementChanged(true, false, ChangeType.DraggingPositionOrSize);
    };

    const onFinishedDragging = () => {
      // On iOS touch event could be triggered, if the drag distance is short.
      document.addEventListener('touchend', filterTouch);

      if(dragDistance==0){
        return;
      }

      setTimeout(()=>{
        // console.log("activeSpatialChangeFinished");
        context.emit('activeSpatialChangeFinished', refElement);
      }, 100);

      refElement.spatial.displayed.x = Math.round(refElement.spatial.displayed.x / props.intervalHorizontal) * props.intervalHorizontal;
      refElement.spatial.displayed.width = Math.round(refElement.spatial.displayed.width / props.intervalHorizontal) * props.intervalHorizontal;
      refElement.spatial.displayed.y = Math.round(refElement.spatial.displayed.y / props.intervalVertical) * props.intervalVertical;

      const newHeight = Math.round(refElement.spatial.displayed.height / props.intervalVertical) * props.intervalVertical;
      // const newHeight = refElement.spatial.displayed.height;
      const containerWidth = (props.containerDomRef as HTMLElement).getBoundingClientRect().width;
      const roundedWidth = refElement.spatial.displayed.width * containerWidth;
      refElement.spatial.setDisplayHeightSafely(newHeight, roundedWidth);

      setTimeout(
        ()=>{
          if (!refElement.spatial.displayed.equals(displayOnStartedDragging)){
            fluidElementChanged(true, false, ChangeType.DraggingFinished);
            refElement.spatial.desired.height = refElement.spatial.displayed.height;
            refElement.spatial.desired.width = refElement.spatial.displayed.width;
            fluidElementChanged(false, true, ChangeType.DraggingFinished);
          }
        },
        100 // set bigger if ifHeightRatio elements not adjusted after the rounding. (Because the root container needs time to change height first)
      );
      setTimeout(
        ()=>{
          // Reset
          dragDistance = 0;
          document.removeEventListener('touchend', filterTouch);
        },
        200 // set bigger if onContainerResized gets triggered
      );
    };

    const filterTouch = (event: any) => {
      if (ifDragging())
        event.preventDefault();
      else
        return true;
    };

    const callbacks = {
      onChildLayoutInitialized,
      onLayoutMinHeightChanged,
      onLayoutMinWidthChanged,
      onStartedDragging,
      onDraggedPosition,
      onDraggedSize,
      onDraggedPositionAndSize,
      onFinishedDragging,
    };

    const refShowMoreOptions = ref(false);

    watch(
      () => refContext.uiState.getLastRequestEditFluid(),
      () => {
        refShowMoreOptions.value = refContext.uiState.getLastRequestEditFluid().element!==undefined
                                && refContext.uiState.getLastRequestEditFluid().element!.ref === props.element.ref;
      },
    );

    watch(
      () => refContext.selection.item,
      () => {
        if (refContext.selection.item == null || refContext.selection.item.ref !== props.element.ref){
          refShowMoreOptions.value = false;
        }
      },
    );

    const toggleMoreOptions = ()=>{
      // If the button is dragged with mouse, the click event will be triggered.
      if (ifDragging())
        return;

      refShowMoreOptions.value = !refShowMoreOptions.value;
      if (refShowMoreOptions.value){
        refContext.uiState.requestEditFluid(props.container, props.element);
      }
    };

    const actions = {
      toggleMoreOptions,
    };

    const computedAllowDragging = computed((): boolean => {
      if (!refContext.mode.editLayout || !refContext.selection.item){
        return false;
      } else if (refElement.item == refContext.selection.item)
        return true;
      else {
        // return refElement.item.contains(refContext.selection.item).length>0;
        return false;
      }
    });

    const computedAllowStickingToContentHeight = computed((): boolean => {
      return refElement.spatial.contentHeight>0;
    });

    const onContainerResized = ()=>{
      if (ifDragging()){
        return;
      }
      refElement.spatial.updateElementHeight(refDomContainer.value!.offsetWidth);
    };

    return {
      props,
      refElement,
      refContext,
      refDomContainer,
      refDomMenu,
      refShowMoreOptions,
      ...callbacks,
      ...actions,
      computedAllowDragging,
      computedAllowStickingToContentHeight,
      onContainerResized,
    };
  }
}
