import { BaseItem, MetaInfo } from "../model/BaseItem";
import { BaseStyle } from "../model/BaseStyle";
import PaletteSet from "./PaletteSet";
import ItemStyleSet from "./ItemStyleSet";
import { ButtonPreset } from "./preset/ButtonPreset";
import { BaseItemPreset } from "./preset/BaseItemPreset";
import { Button } from "@/components/elemental/button/Button";
import { fonts } from "./Fonts";
import { getPalettes } from "./Palettes";

export
class ThemeParam{
  name = "";

  styleSet = "";
  paletteSet = "";

  fontHeader = "";
  fontParagraph = "";
  fontButton = "";
  fontHeaderWeight = "Bold";
  fontParagraphWeight = "Normal";
  fontButtonWeight = "Normal";

  constructor(name: string, styleSet: string, paletteSet: string, fontHeader: string, fontHeaderWeight: string, fontParagraph: string, fontParagraphWeight: string, fontButton: string, fontButtonWeight: string){
    this.name = name;
    this.styleSet = styleSet;
    this.paletteSet = paletteSet;
    this.fontHeader = fontHeader;
    this.fontHeaderWeight = fontHeaderWeight;
    this.fontParagraph = fontParagraph;
    this.fontParagraphWeight = fontParagraphWeight;
    this.fontButton = fontButton;
    this.fontButtonWeight = fontButtonWeight;
  }
}

export
class Predefined{
  // Item presets
  static buttonPreset = new ButtonPreset;
  static itemPresets = new Map<MetaInfo, BaseItemPreset>([[Button.meta, Predefined.buttonPreset]]);
  //////////////////////////////////////////////////////////////////////////////
  static themeParams: ThemeParam[];
  static paletteSets: PaletteSet[];
  static itemStyleSets: ItemStyleSet[];
  //////////////////////////////////////////////////////////////////////////////
  static readonly fonts = fonts;
  //////////////////////////////////////////////////////////////////////////////
  constructor(){
    Predefined.themeParams = [
      new ThemeParam("New York", "Apple", "Black And White", "Lato", "Normal", "Lato", "Normal", "Lato", "Normal"),
      new ThemeParam("Berlin", "Milkshake", "Yellow", "Poppins", "Bold", "Poppins", "Normal", "Poppins", "Normal"),
      new ThemeParam("Marrakesh", "Google", "Red", "Fair Display", "Normal", "Fair Display", "Normal", "Fair Display", "Normal"),
      new ThemeParam("Chefchaouen", "Milkshake", "Blue", "Fair Display", "Bold", "Fair Display", "Normal", "Fair Display", "Normal"),
    ]
    //////////////////////////////////////////////////////////////////////////////

    Predefined.paletteSets = getPalettes();
    //////////////////////////////////////////////////////////////////////////////
    const apple = new ItemStyleSet("Apple");
    //////////////////////////////////////////////////////////////////////////////
    const google = new ItemStyleSet("Google");
    google.buttonSet.primary = Predefined.buttonPreset.get("1") as BaseStyle;
    google.buttonSet.secondary = Predefined.buttonPreset.get("1") as BaseStyle;
    (google.buttonSet.secondary as any).font.size.setValue("12px"); //TODO
    //////////////////////////////////////////////////////////////////////////////
    const milkshake = new ItemStyleSet("Milkshake");
    milkshake.buttonSet.primary = Predefined.buttonPreset.get("2") as BaseStyle;
    milkshake.buttonSet.secondary = Predefined.buttonPreset.get("2") as BaseStyle;
    (milkshake.buttonSet.secondary as any).font.size.setValue("12px"); //TODO

    //////////////////////////////////////////////////////////////////////////////
    Predefined.itemStyleSets = [apple, google, milkshake];
    //////////////////////////////////////////////////////////////////////////////
  }

  static getItemPreset(meta: MetaInfo) : BaseItemPreset | undefined{
    return Predefined.itemPresets.get(meta);
  }
  static cloneItemWithPreset(item: BaseItem, presetName: string) : BaseItem{
    const style = Predefined.itemPresets.get(item.getMeta())?.get(presetName);
    if (style==undefined){
      throw new Error("Predefined.cloneItemWithPreset: style not found");
    }
    const clone = item.clone();
    clone.setStyle(style);
    return clone;
  }

  static getItemStyleSet(setName: string): ItemStyleSet{
    let setFound = undefined;
    Predefined.itemStyleSets.every(itemStyleSet => {
      if (itemStyleSet.name===setName){
        setFound = itemStyleSet;
        return false;
      } else {
        return true;
      }
    });
    if (setFound==undefined)
      throw new Error("Theme.getItemStyleSet(): set not found: "+setName);
    return setFound;
  }

  static getItemStyleNames(setName: string, meta: MetaInfo): string[]{
    return this.getItemStyleSet(setName).getItemStyleNames(meta);
  }
}

new Predefined; // Initialize