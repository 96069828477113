
import MixinProps from '@/components/shared/view/MixinProps';
import { ProductRef } from '../../ProductRef';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import Model from '@/shared/Model'

export default {
  mixins: [MixinProps],
  name: 'ProductRefEditor',
  components: {  },
  setup(props: any){
    const { getRefItem, getRefContext } = useAddReactivity();
    const refProductRef = getRefItem<ProductRef>(props);
    const refContext = getRefContext(props);
    return {
      refProductRef,
      refContext,
      Model
    }
  }
}
