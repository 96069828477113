import type { Theme } from "../theme/Theme";
import type { BaseStyle } from "./BaseStyle";
import { v4 as uuid } from 'uuid';

export interface MetaInfo{
  typeName: string
  friendlyName: string // use for ui, localization etc.
}

export abstract class BaseItem{
  private itemName = "";
  // ref used for identification item from others e.g. in Repeat
  public ref = uuid();

  public urlSlug = this.ref;

  // Item with customizable styles must initialize style in constructor.
  // Item whose style == null is considered as non-customizable.
  private style = null as null | BaseStyle;

  public styleVariantName = "";

  public getStoreObject(): Object{
    const object : any = {
      type: this.getMeta().typeName,
      item: this.getStoreObjectItem(),
    };

    if (this.style!=null){
      object.style = this.style.getStoreObject();
    }

    if (this.styleVariantName.length!=0){
      object.styleVariantName = this.styleVariantName;
    }

    return object;
  }

  public getStoreObjectItem(): Object{
    throw new Error(`❗️${this.getMeta().friendlyName} has no getStoreObjectItem()`);
  }

  public fromStoreObject(object: any){
    if (object===undefined) return;
    if (object.type !== this.getMeta().typeName){
      console.log(`❗️fromStoreObject(): type mismatch: ${object.type} & ${this.getMeta().typeName}`);
    }
    this.fromStoreObjectItem(object.item);
    if(object.style){
      this.fromStyleObject(object.style);
    }
    if(object.styleVariantName){
      this.styleVariantName = object.styleVariantName;
    } else {
      this.styleVariantName = "";
    }
  }

  public fromStoreObjectItem(item: any){
    console.log(this.getMeta().friendlyName + " no store object❗️");
  }

  public async fromStyleObject(object: any){
    await this.setStyleByName(object.styleName);
    this.style?.fromStoreObject(object);
  }

  public isStyleConfigurable(theme: Theme): boolean{
    return this.getStyle(theme)!=null && this.getStyle(theme)!.getStyleName()!=='none';
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{
    console.log(this.getMeta().typeName + " no createStyle()❗️");
    return null;
  }

  public async setStyleByName(name: string){
    const style = await this.createStyle(name);
    if (style){
      this.setStyle(style);
    }
  }

  public setStyle(style: null | BaseStyle){
    this.style = style;
  }

  public getStyle(theme: Theme): BaseStyle | null{
    if (this.styleVariantName.length>0){
      const style = theme.getStyle(this.styleVariantName, this.getMeta());
      if (style != null){
        return style;
      }
    }
    return this.style;
  }

  public setStyleVariantName(name: string){
    this.styleVariantName = name;
  }

  public setItemName(itemName: string){
    this.itemName = itemName;
  }

  public getItemName(): string{
    return this.itemName;
  }

  public abstract getMeta(): MetaInfo;

  public from(item: BaseItem): void{
    this.itemName = item.itemName;
    // uuid should not be assigned
    this.style = null;
    if (item.style!==null){
      this.style = item.style!.clone() as BaseStyle;
    }
    this.styleVariantName = item.styleVariantName;
  }

  public abstract getDefaultInstance(): BaseItem;

  public clone(): BaseItem{
    const newItem = this.getDefaultInstance();
    newItem.from(this);
    return newItem;
  }

  public contains(item: BaseItem): BaseItem[]{
    // if (this.ref === item.ref)
    //   return [this];
    return [];
  }
}