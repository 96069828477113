
import { PropType } from 'vue';
import Context from '@/components/shared/view/Context';
import { allLanguages, Language } from '@/components/shared/view/LanguageOption';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import { VueDraggable, SortableEvent } from 'vue-draggable-plus';

export default {
  name: 'LanguageManager',
  props: {
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
  },
  components: {VueDraggable},
  setup(props: any){
    const { getRefContext } = useAddReactivity();
    const refContext = getRefContext(props);
    const refLanguageOption = refContext.value.languageOption;

    const onChoose = (event: SortableEvent)=>{
      event.item.classList.add("Dragged");
    };

    const onUnchoose = (event: SortableEvent)=>{
      event.item.classList.remove("Dragged");
    };

    const addLanguage = (language: Language)=>{
      refContext.value.selection.app!.languages.push(language as Language);
    };

    const removeLanguage = (language: Language)=>{
      const index = refContext.value.selection.app!.languages.indexOf(language, 0);
      if (index > -1) {
        refContext.value.selection.app!.languages.splice(index, 1);
      }
    };

    const setLanguage = (language: Language)=>{
      refLanguageOption.current = language as Language;
    };

    return{
      refContext,
      refLanguageOption,
      Language,
      allLanguages,
      onChoose,
      onUnchoose,
      addLanguage,
      removeLanguage,
      setLanguage
    }
  },
}
