import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import StyleContained from "@/components/shared/style/StyleContained";
import { EnumPair, StyleVariableString } from "@/components/shared/style/StyleVariableString";
import Context from "@/components/shared/view/Context";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Location Style"};
  static readonly nameColorMode: string = "ColorMode";

  public colorMode = new StyleVariableString("Color Mode");
  public contained = new StyleContained;

  public getStoreObjectItem(): Object{
    return {
      colorMode: this.colorMode.getStoreObject(),
      contained: this.contained.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.colorMode.fromStoreObject(item.colorMode)
    this.contained.fromStoreObject(item.contained);
  }

  getClassNames(): any{
    // TODO: complete css class names here and in the Viewer
    return {
    }
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");

    this.colorMode.enums = [
      new EnumPair("Normal", "Normal"),
      new EnumPair("Gray", "Gray"),
      new EnumPair("Dark", "Dark"),
    ];
    this.colorMode.enumOnly=true;
    this.colorMode.setEnum("Normal");

    this.contained.maxWidth.enums = [new EnumPair("S", "200px"), new EnumPair("M", "400px"), new EnumPair("L", "600px")];
  }

  from(item: BaseItem): void{
    super.from(item);
    this.setStyleName((item as BaseStyle).getStyleName());
    this.colorMode.from((item as Style).colorMode);
    this.contained.from((item as Style).contained);
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }

  getPredefinedStyleObject(context: Context) {
    throw new Error("Location default style getPredefinedStyleObject() not implemented");
  }
}