import { BaseItem, MetaInfo } from "../model/BaseItem";
import { BaseVar } from "../model/BaseVar";

export
class EnumPair{
  name: string;
  value: string;

  constructor(name: string, value: string){
    this.name = name;
    this.value = value;
  }
}

export class StyleVariableString extends BaseVar{
  static readonly meta: MetaInfo = {typeName: "StyleVariableString", friendlyName: "Style Variable String"};

  enums : EnumPair[]; // VERSION_VARIANT
  enumOnly: boolean = false; // VERSION_VARIANT

  constructor(itemName = "", value = "Empty String Variable", enums: EnumPair[] = []) {
    super();
    this.setItemName(itemName);
    this.value = value;
    this.enums = enums;

    this.setStyle(null);
  }

  public getMeta(): MetaInfo{
    return StyleVariableString.meta;
  }

  public override from(item: BaseItem): void{
    super.from(item);
    this.value = (item as StyleVariableString).getValue();
    this.enums = (item as StyleVariableString).enums;
  }

  public getDefaultInstance(): BaseItem{
    return new StyleVariableString();
  }

  public setEnum(enumName: string): void{
    this.enums.forEach( (pair) => {
      if (pair.name === enumName){
        this.setValue(pair.value);
      }
    });
  }
}