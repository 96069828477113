
import MixinProps from '@/components/shared/view/MixinProps';
import { setSelectedItem } from '@/components/shared/view/Context';
import { BaseItem } from '@/components/shared/model/BaseItem';
import { Ref, toRef } from 'vue';

export default {
  name: 'SelectCollectionButton',
  mixins: [MixinProps],
  setup(props: any){
    const refItem = toRef(props, 'item') as Ref<BaseItem>;
    return{
      props,
      refItem,
      setSelectedItem
    }
  },
}
