import { Ref, reactive, ref, toRef, watch } from "vue"
import { BaseItem } from "../model/BaseItem";
import { BaseStyle } from "../model/BaseStyle";
import Context from "./Context";

export function useAddReactivity() {
  const getRefItem = <T extends BaseItem>(props: any): Ref<T> => {
    return toRef(props, "item") as Ref<T>;
  }

  const getRefItemDeep = <T extends BaseItem>(props: any): T => {
    const refItemDeep = reactive(props.item);
    watch(
      () => props.item,
      () => {
        Object.assign(refItemDeep, props.item);
    });
    return refItemDeep;
  }

  const getRefContext = (props: any): Ref<Context>=>{
    return toRef(props, "context") as Ref<Context>;
  };

  const getRefStyle = <T extends BaseStyle>(props: any): Ref<T> => {
    const refStyle = ref(props.item.getStyle(props.context.selection.app!.theme)!) as Ref<T>;
    watch(
      () => [props.item.styleVariantName, props.context.selection.app!.theme],
      () => {
        refStyle.value = props.item.getStyle(props.context.selection.app!.theme)! as T;
      },
      {deep: true}
    )
    return refStyle as Ref<T>;
  };

  return {
    getRefItem,
    getRefItemDeep,
    getRefContext,
    getRefStyle,
  };
}