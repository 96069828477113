
import { onMounted, ref, watch } from 'vue';

export default {
  name: 'ContentEditable',
  props: {
    value: {
      type: String,
      default: 'defaultValue',
    },
    style: {
      type: Object,
      default: ()=>({})
    },
    multiline: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['valueChanged'],
  setup(props: any, context: any){
    const refDomText = ref(null);

    watch(props, () =>
      {
        if (document.activeElement === refDomText.value)
          return;

        (refDomText.value as any).innerText = props.value;
      },
    )

    onMounted(() => {
      if (refDomText.value != null){
        (refDomText.value as any).innerText = props.value;

        // listen to change
        // #AI
        const targetElement = refDomText.value as Node;
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type==='characterData')
            context.emit('valueChanged', (mutation.target as Text).nodeValue);
          });
        });
        const config = { characterData: true, subtree: true };
        observer.observe(targetElement, config);
        // #END

        // disable key Enter if not multiline
        if (!props.multiline){
        (refDomText.value as any).addEventListener('keypress', (event: KeyboardEvent) => {
            if (event.code === "Enter") {
              event.preventDefault();
            }
          });
        }
      }
    });

    return {
      props,
      refDomText,
    }
  }
}
