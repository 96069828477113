export default function useUiFix() {
  const edgeSize = 50;

  const preventEdgeSwipe = (element: HTMLElement) => {
    // Disable swipe to navigate
    element.addEventListener('touchmove', (e) => {
      // is not near edge of view, exit
      if (e.touches[0].pageX > edgeSize && e.touches[0].pageX < window.innerWidth - edgeSize) return;
      // prevent swipe to navigate back gesture
      e.preventDefault();
      return e;
      ///////////////////////////////
    });
  };

  return {
    preventEdgeSwipe
  };
}