
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
// import BlockContainer from '@/components/shared/util/BlockContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import Draggable from './Draggable.vue' // TODO: Unifywith src/components/composed/imageWithText/view/shared/Draggable.vue
import AddItemButton from "@/components/shared/util/AddItemButton.vue"
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { Ref, computed, onMounted, ref } from 'vue';
import { Layout } from '../../Layout';
import { BaseItem } from '@/components/shared/model/BaseItem';

// variable
export default {
  name: 'ImageDefaultViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
    // BlockContainer,
    ComponentWrapper,
    Draggable,
    AddItemButton,
  },
  setup(props: any, context: any){
    const refLayout = ref(props.item) as Ref<Layout>; //BROKEN

    const { emitLayoutInitialized, emitLayoutMinHeightChanged } = useSerialLayoutInitialization(refLayout.value, context);

    const computedStyleLayout = computed( () => {
      if (refLayout.value.layoutType=='Single'){
        return {
          position: 'relative',
          width: 'calc(100%-40px)',
          height: 'calc(100%-40px)',
        };
      } else if (refLayout.value.layoutType=='Row'){
        return {
          position: 'relative',
          width: 'calc(100%-40px)',
          height: 'calc(100%-40px)',
          display: 'flex',
          flexDirection:'row',
        };
      } else if (refLayout.value.layoutType=='Column'){
        return {
          position: 'relative',
          width: 'calc(100%-40px)',
          height: 'calc(100%-40px)',
        };
      }
      return {};
    });

    const domRefArea = ref(null);

    const updateOffset = (idx: number, offset: number)=>{
      if(idx==-1)
        throw new Error("Layout:Viewer:callbackOnDragged: wrong idx -1.");
      if(idx==refLayout.value.children.length-1)
        throw new Error("Layout:Viewer:callbackOnDragged: wrong idx, last layout cannot be selected.");

      const first = refLayout.value.children[idx];
      const second = refLayout.value.children[idx+1];

      const newFirstProportion = first.proportion + offset;
      const newSecondProportion = second.proportion - offset;

      // Limit min proportion
      const minProportion = 1/12;
      if (newFirstProportion<minProportion || newSecondProportion<minProportion)
        return;

      first.proportion = newFirstProportion;
      second.proportion = newSecondProportion;
    };

    const callbackOnDragged = (idx: number, offset: number)=>{
      updateOffset(idx, offset);
    };

    const callBackOnFinished = (idx: number)=>{
      const first = refLayout.value.children[idx];

      const interval = 1/12;
      const offset = Math.round(first.proportion / interval) * interval - first.proportion;
      updateOffset(idx, offset);
    };

    const singleChangeContentItem = (item: BaseItem)=>{
      // console.log("singleChangeContentItem");
      refLayout.value.content = item;
    };

    const refCurrentRange = ref(1);

    const insertNewChild = (content: BaseItem, idx: number, offset: number)=>{
      refLayout.value.insertNewChild(content, idx, offset);
      ++refCurrentRange.value;
    }

    const removeChild = (idx: number)=>{
      refLayout.value.removeChild(idx);
      --refCurrentRange.value;
    }
    const morphToRowAndAddChild = (content: BaseItem)=>{
      refLayout.value.morphToRowAndAddChild(content);
    }
    const morphToColumnAndAddChild = (content: BaseItem)=>{
      refLayout.value.morphToColumnAndAddChild(content);
    }

    const onChildLayoutInitialized = (child: BaseItem)=>{
      // console.log("Layout: onChildLayoutInitialized: ", child.getMeta().typeName);
      if (refLayout.value.layoutType=='Single'){
        emitLayoutInitialized();
      } else if (refLayout.value.layoutType=='Row' || refLayout.value.layoutType=='Column'){
        const idx = refLayout.value.children.indexOf(child as Layout);
        if (idx<0){
          throw new Error("LayoutViewer:default:onChildLayoutInitialized: child not found");
        }
        if (refCurrentRange.value==refLayout.value.children.length){
          // All children have finished
          // console.log("Layout: all children finished initialization");
          emitLayoutInitialized();
        } else {
          refCurrentRange.value = idx+2;
        }
      }
    };

    const refDomBody = ref(null);

    const currentWidth = ref(0);

    const updateLayout = () => {
      if (refDomBody.value == null){
        console.log(`Layout: updateLayout(): refDomBody is null! Layout contains: ${refLayout.value.content?.getMeta().typeName}`);
      } else {
        currentWidth.value = (refDomBody.value as HTMLElement).clientWidth as number;
      }
    }

    onMounted(() => {
      updateLayout();
    });

    const ifChildSelected = (idx: number) => {
      if (props.context.mode.editLayout == false || props.context.selection.item == undefined || refLayout.value.children[idx].content == undefined){
        return false;
      } else {
        return props.context.selection.item == refLayout.value.children[idx].content;
      }
    };

    const advancedEditMode = false;

    return {
      props,
      refLayout,
      computedStyleLayout,
      domRefArea,
      callbackOnDragged,
      callBackOnFinished,
      singleChangeContentItem,

      refCurrentRange,
      insertNewChild,
      removeChild,
      morphToRowAndAddChild,
      morphToColumnAndAddChild,

      onChildLayoutInitialized,
      emitLayoutMinHeightChanged,

      refDomBody,
      currentWidth,
      updateLayout,

      ifChildSelected,

      advancedEditMode,
    };
  }
}
