import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "domRefSlider",
    style: _normalizeStyle({position: 'absolute', top: 0, left: $setup.props.currentProportion*100+'%', width: '0px', height: '100%',
    zIndex: $setup.zIndexEditorDraggableSlider, touchAction: 'none', transform: 'translate(-50%, 0%)'})
  }, [
    _renderSlot(_ctx.$slots, "slider")
  ], 4))
}