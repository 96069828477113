import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9af90e1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "GlobalInputRow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.props.context.mode.editContent || $setup.props.context.mode.editTextInline)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("textarea", {
          class: "GlobalTextInput CodeTextArea BoxSizingBorder",
          style: {"width":"100%","height":"fit-content"},
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.refCurrentValue) = $event)),
          placeholder: "edit me",
          rows: "10"
        }, null, 512), [
          [_vModelText, $setup.refCurrentValue]
        ])
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createTextVNode(_toDisplayString($setup.refCurrentValue), 1)
      ], 64))
}