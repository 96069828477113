import { Ref, ref, toRef, watch } from "vue"
import { BaseVars } from "../model/BaseVars";
import { LanguageOption } from '@/components/shared/view/LanguageOption';
import { useAddReactivity } from "./AddReactivity";

export function useRefVarsAndCurrentValue() {
  const getRefVarsAndCurrentValue = <T extends BaseVars>(props: any): [Ref<T>, Ref<string>] => {
    const { getRefItem,getRefContext } = useAddReactivity();
    const refVars = getRefItem<T>(props);
    const refContext = getRefContext(props);
    const refLanguageOption = toRef(props.context, "languageOption") as Ref<LanguageOption>;
    const refCurrentValue = ref(props.item.getValueOf(refContext.value.languageOption)) as Ref<string>;

    watch([refVars, refLanguageOption], () =>
      {
        refCurrentValue.value = refVars.value.getValueOf(refLanguageOption.value);
      },
      {deep: true}
    );
    watch(refCurrentValue, () =>
      {
        refVars.value.setValueOf(refLanguageOption.value.current, refCurrentValue.value);
      }
    )

    return [refVars, refCurrentValue];
  }

  return {
    getRefVarsAndCurrentValue
  };
}