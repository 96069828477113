import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "ContainedBackgroundContainer InheritSize",
  style: {"position":"absolute","top":"0","left":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_resize = _resolveDirective("resize")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "refDomContainer",
    class: "ContainedContainer InheritSize Unselectable",
    style: _normalizeStyle([{"overflow":"hidden"}, $setup.getContainerPredefinedStyleObject()])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "ContainedBackground InheritSize",
        style: _normalizeStyle($setup.getBackgroundPredefinedStyleObject())
      }, null, 4)
    ]),
    ($setup.props.onContentResized)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "refDomContent",
          class: _normalizeClass(["ContainedContent", $setup.props.ifContainedContentInheritSize? 'InheritSize' : '']),
          style: _normalizeStyle($setup.getContentPredefinedStyleObject())
        }, [
          _renderSlot(_ctx.$slots, "body")
        ], 6)), [
          [_directive_resize, $setup.props.onContentResized]
        ])
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref: "refDomContent",
          class: _normalizeClass(["ContainedContent", $setup.props.ifContainedContentInheritSize? 'InheritSize' : '']),
          style: _normalizeStyle([$setup.getContentPredefinedStyleObject(), {"position":"relative"}])
        }, [
          _renderSlot(_ctx.$slots, "body")
        ], 6))
  ], 4))
}