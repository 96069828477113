import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";

export default
class StyleDefault extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "StyleDefault", friendlyName: "Style Default"};

  public getStoreObjectItem(): Object{ return {}; }
  public fromStoreObjectItem(item: any){}

  constructor (){
    super();
    this.setItemName("StyleDefault");
    this.setStyleName("default");
  }

  public override from(item: BaseItem): void{
    super.from(item);
  }

  getMeta(): MetaInfo{
    return StyleDefault.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new StyleDefault();
  }
}