import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "Unselectable",
    style: {"position":"absolute","top":"0","left":"0","margin":"0 auto","background-color":"transparent","border":"0"},
    onClick: _cache[0] || (_cache[0] = ()=>{$setup.refContext.uiState.requestAddItem($setup.refType, $setup.itemAdded);})
  }, [
    _renderSlot(_ctx.$slots, "buttonBody")
  ]))
}