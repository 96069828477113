import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import { EnumPair, StyleVariableString } from "./StyleVariableString";
import { StyleVariableColor } from "./StyleVariableColor";
import Context from "../view/Context";

export default
class StyleBorder extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "StyleBorder", friendlyName: "Border"};

  static readonly nameWidth: string = "Width";
  static readonly nameRadius: string = "Radius";
  static readonly nameColor: string = "Color";

  public width = new StyleVariableString(StyleBorder.nameWidth, "0px");
  public radius = new StyleVariableString(StyleBorder.nameRadius, "0px");
  public color = new StyleVariableColor(StyleBorder.nameColor, "#000000");

  public getStoreObjectItem(): Object{
    return {
      width: this.width.getStoreObject(),
      radius: this.radius.getStoreObject(),
      color: this.color.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.width.fromStoreObject(item.width);
    this.radius.fromStoreObject(item.radius);
    this.color.fromStoreObject(item.color);
  }

  constructor (itemName = "StyleBorder"){
    super();
    this.setItemName(itemName);

    this.width.enums = [new EnumPair("0", "0px"), new EnumPair("S", "2px"), new EnumPair("M", "4px"), new EnumPair("L", "6px"), ];
    this.radius.enums = [new EnumPair("0", "0px"), new EnumPair("S", "8px"), new EnumPair("M", "16px"), new EnumPair("L", "100vw"), ];
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const styleBorder = item as StyleBorder;
    this.width.from(styleBorder.width);
    this.radius.from(styleBorder.radius);
    this.color.from(styleBorder.color);
  }

  getMeta(): MetaInfo{
    return StyleBorder.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new StyleBorder();
  }

  getPredefinedStyleObject(context: Context) : {borderWidth: string, borderRadius: string, borderColor: string, borderStyle: string, boxSizing: string}{
    return {
      borderWidth: this.width.getValue(),
      borderRadius: this.radius.getValue(),
      borderColor: this.color.getColor(context),
      borderStyle: 'solid',
      boxSizing: "border-box",
    };
  }
}