
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
// import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import Contained from '@/components/shared/util/Contained.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import { ImageGallery } from '../../ImageGallery';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import Style from './Style';
import StyleContained from '@/components/shared/style/StyleContained';
import { Ref, onMounted, ref, toRef, watch } from 'vue';
import { useSerialLayoutInitialization } from '@/components/shared/view/SerialLayoutInitialization';

// variable
export default {
  name: 'HeaderDefaultViewer',
  mixins: [MixinProps],
  components:{
    ComponentContainer,
    // ComponentWrapper,

    Contained,
  },
  setup(props: any, context: any){
    const { getRefItem, getRefContext, getRefStyle } = useAddReactivity();
    const refImageGallery = getRefItem<ImageGallery>(props);
    const refContext = getRefContext(props);
    const refStyle = getRefStyle<Style>(props);
    const refStyleContained = toRef(refStyle.value, 'contained') as Ref<StyleContained>;

    const { emitLayoutInitialized, emitLayoutMinHeightChanged, refDomContainer, refDomContent } = useSerialLayoutInitialization(refImageGallery.value, context);

    const refContainerWidthPx = ref(0) as Ref<number>;
    const refContainerHeightPx = ref(0) as Ref<number>;
    const refContentHeightPx = ref(0) as Ref<number>;

    const refNumColumns = ref(2) as Ref<number>;

    onMounted(() => {
      // timeout is required to fix missed height change during initialization.
      // For example, page inited with the second language, which affects the text size.
      // 0ms does the trick as well.
      setTimeout( () => {
        refContainerHeightPx.value = (refDomContent.value as HTMLElement).clientHeight as number;
        refContainerWidthPx.value = (refDomContent.value as HTMLElement).clientWidth as number;
        // console.log("ImageGallery onMounted: height = ", height);
        emitLayoutInitialized();
        emitLayoutMinHeightChanged(refImageGallery.value, refContainerHeightPx.value);
      }, 0 );
    });

    const onContentResized = (size: {width: number, height: number})=>{
      // console.log("Content = ", size.height);
      emitLayoutMinHeightChanged(refImageGallery.value, size.height);
    }

    const onContainerResized = (size: {width: number, height: number})=>{
      refContainerWidthPx.value = size.width;
      refContainerHeightPx.value = size.height;
      // console.log("Container size = ", size);
    }

    watch(
      ()=>[refContainerWidthPx, refContainerHeightPx, refContentHeightPx, refStyle.value.width],
      ()=>{
        const desiredImageWidthPx = Number(refStyle.value.width.getValue());
        const imageWidthPx = isNaN(desiredImageWidthPx) ? 100 : desiredImageWidthPx;
        refNumColumns.value = Math.floor(refContainerWidthPx.value / imageWidthPx);
        refNumColumns.value = Math.max(1, refNumColumns.value);
        refNumColumns.value = Math.min(refImageGallery.value.images.length, refNumColumns.value);
        // console.log("Col = ", refNumColumns.value);
      },
      {immediate: true, deep: true}
    );

    return {
      refImageGallery,
      refContext,
      refStyle,
      refStyleContained,

      refDomContainer,
      refDomContent,

      onContentResized,
      onContainerResized,

      refNumColumns
    };
  }
}
