
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import Contained from '@/components/shared/util/Contained.vue';
import { Ref, computed, onMounted, toRef } from 'vue';
import { ProductPreview } from '../../ProductPreview';
import { Product } from '@/components/product/product/Product';
import Model from '@/shared/Model';
import { useSerialLayoutInitialization } from '@/components/shared/view/SerialLayoutInitialization';
import Style from './Style';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import StyleContained from '@/components/shared/style/StyleContained';
import { getPathForPage } from '@/Router';

export default {
  name: 'ProductPreview',
  mixins: [MixinProps],
  components:{
    ComponentContainer,
    Contained,
  },
  setup(props: any, context: any){
    const { getRefItem, getRefContext, getRefStyle } = useAddReactivity();
    const refProductPreview = getRefItem<ProductPreview>(props);
    const refContext = getRefContext(props);
    const refStyle = getRefStyle<Style>(props);
    const refStyleContained = toRef(refStyle.value, 'contained') as Ref<StyleContained>;

    const { emitLayoutInitialized, emitLayoutMinHeightChanged, emitLayoutMinWidthChanged } = useSerialLayoutInitialization(refProductPreview.value, context);

    onMounted(()=>{
      emitLayoutInitialized();
      emitLayoutMinHeightChanged(refProductPreview.value, 200);
      emitLayoutMinWidthChanged(refProductPreview.value, 3/12);
    });

    const computedProduct = computed((): Product => {
      return Model.getProduct(refProductPreview.value.productRef.getRefId());
    });

    const getButtonStyle = ()=>{
      const button = refStyle.value.font.getPredefinedStyleObject(refContext.value);
      button.fontFamily = button.fontFamily != "" ? button.fontFamily : refContext.value.selection.app!.theme.fontButton;
      button.fontWeight = button.fontWeight != "" ? button.fontWeight : refContext.value.selection.app!.theme.fontButtonWeight;
      return {
        ...button,
      };
    }

    return {
      refProductPreview,
      refContext,
      refStyle,
      refStyleContained,
      computedProduct,
      getButtonStyle,

      getPathForPage
    }
  }
}
