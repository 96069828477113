import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7542e15e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Overview" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.images, (imageUrl) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "BlockView",
        key: imageUrl,
        onClick: ($event: any) => ($setup.imageSelected(imageUrl))
      }, [
        _createElementVNode("img", {
          draggable: "false",
          style: {"height":"auto","width":"100%","margin":"auto","object-fit":"contain","object-position":"center"},
          src: imageUrl
        }, null, 8, _hoisted_3)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}