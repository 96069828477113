
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import { Ref, computed, toRef } from 'vue';
import { ProductRef } from '../../ProductRef';
import { Product } from '@/components/product/product/Product';
import Model from '@/shared/Model';

export default {
  name: 'ProductRef',
  mixins: [MixinProps],
  components:{
    ComponentWrapper,
  },
  setup(props: any){
    const refProductRef = toRef(props, 'item') as Ref<ProductRef>;

    const computedProduct = computed((): Product => {
      return Model.getProduct(refProductRef.value.getRefId());
    });

    return {
      props,
      computedProduct
    }
  }
}
