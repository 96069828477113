
import MixinProps from '@/components/shared/view/MixinProps';
import StyleBorder from '../StyleBorder';
import { Ref, toRef } from 'vue';
import Context from '../../view/Context';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue'

export default {
  mixins: [MixinProps],
  name: 'EditorStyleBorder',
  components: {ComponentWrapper},
  setup(props: any){
    const refStyleBorder = toRef(props, 'item') as Ref<StyleBorder>;
    const refContext = toRef(props, 'context') as Ref<Context>;
    return {
      refStyleBorder,
      refContext
    }
  }
}
