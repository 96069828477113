import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9d1c180"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "VariableFontFamilyContainer GlobalInputRow" }
const _hoisted_2 = { class: "AlignLeft" }
const _hoisted_3 = {
  key: 1,
  class: "GlobalTextLabel"
}
const _hoisted_4 = { style: {"height":"40px"} }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.refHasEnum && !$setup.refStyleVariableString.enumOnly)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "GlobalTextLabelButton",
            onClick: _cache[0] || (_cache[0] = ()=>{$setup.refShowingEnum = !$setup.refShowingEnum; $setup.refShowValueAfterInitialization = false;})
          }, _toDisplayString($setup.refStyleVariableString.getItemName()), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.refStyleVariableString.getItemName()), 1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!$setup.refShowValueAfterInitialization && $setup.refHasEnum && $setup.refShowingEnum)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.refStyleVariableString.enums, (pair) => {
            return (_openBlock(), _createElementBlock("div", {
              key: pair.name,
              style: _normalizeStyle([{"display":"inline-block"}, pair.value != 'Default' ? {fontFamily: pair.value} : {}])
            }, [
              ($setup.refStyleVariableString.getValue() === pair.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "GlobalTextButton GlobalTextButtonSelected",
                    onClick: ()=>{$setup.refStyleVariableString.setEnum(pair.name);}
                  }, _toDisplayString(pair.name), 9, _hoisted_5))
                : (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "GlobalTextButton GlobalTextButtonNotSelected",
                    onClick: ()=>{$setup.refStyleVariableString.setEnum(pair.name);}
                  }, _toDisplayString(pair.name), 9, _hoisted_6))
            ], 4))
          }), 128))
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            ref: "refDomInput",
            type: "text",
            class: "GlobalTextInput",
            style: {"width":"100%","text-align":"center"},
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.refStyleVariableString.value) = $event)),
            placeholder: "edit me"
          }, null, 512)), [
            [_vModelText, $setup.refStyleVariableString.value]
          ])
    ])
  ]))
}