
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import { Ref, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import BlockContainer from '@/components/shared/util/BlockContainer.vue';
import { PageNode } from '@/components/page/pageNode/PageNode';

import { App } from '../../App';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import { BaseItem } from '@/components/shared/model/BaseItem';
import AppBarStyle from '@/components/app/appBar/view/default/Style';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { PaletteColor } from '@/components/shared/theme/PaletteSet';
import { useServerDelegate } from '@/shared/UseServerDelegate';
import { getPathForPage } from '@/Router';
import { Page } from '@/components/page/page/Page';
library.add(faXmark);

// variable
export default {
  name: 'AppDefaultViewer',
  mixins: [MixinProps],
  components:{
    ComponentContainer,
    BlockContainer,
    ComponentWrapper,
    FontAwesomeIcon
},

  setup(props: any){
    const { getRefItemDeep, getRefContext } = useAddReactivity();
    const refApp = getRefItemDeep<App>(props);
    const refContext = getRefContext(props);

    //////////////////////////
    const refPageId = ref("");

    const refPageNode = ref(null) as Ref<BaseItem|null>;

    const route = useRoute();
    watch(
      () => route.params.pageId,
      () => {
        console.log("App route params: ", route.params);
        let pageUrlOrId = route.params.pageId as string;
        // Home is the first one
        let newPageNode : PageNode | undefined = undefined;
        if (pageUrlOrId == undefined){
          console.log("No page -> Home");
          newPageNode = refApp.getHomePageNode();
        } else {
          newPageNode = refApp.getPageNode(pageUrlOrId, refContext.value.languageOption);
        }
        // ID/URL not found
        if (newPageNode == undefined){
          console.log("Wrong page -> Error page = Home");
          newPageNode = refApp.getHomePageNode();
        }

        refPageId.value = pageUrlOrId;
        refPageNode.value = newPageNode;

        /* eslint-disable vue/no-mutating-props */
        const pageRef = (refPageNode.value as PageNode).pageRef;
        refContext.value.selection.pageRef = pageRef;
        /* eslint-enable vue/no-mutating-props */

        // Load page
        const {getPage} = useServerDelegate();
        getPage(
          route.params.userId as string,
          refContext.value.selection.pageRef.refId,
          (value)=>{
            console.log("Page refId = ", pageRef.refId);

            const startTime = performance.now();
            try {
              const page = new Page;
              page.fromStoreObject(value);
              // Wait for the next tick, so that refContext.value.selection.page can be finalized
              setTimeout(() => {
                refContext.value.remotePageStoreObject = value;
                refContext.value.selection.page = page;
              }, 0);
            } catch (e: unknown){
              console.log(pageRef.refId, e);
            }
            const endTime = performance.now();
            const duration = endTime - startTime;
            console.log(`page.fromStoreObject took ${duration} milliseconds.`);
            // refContext.value.selection.page = null;
          },
          (error)=>{
            console.error('Error:', error);
            refContext.value.selection.page = null;
          }
        );
      },
      { immediate: true }
    );

    refContext.value.watchQuery();

    watch(
      ()=>[
            refContext.value.selection.app!.theme.fontHeader,
            refContext.value.selection.app!.theme.fontParagraph,
            refContext.value.selection.app!.theme.fontButton,

            refContext.value.selection.app!.theme.fontHeaderWeight,
            refContext.value.selection.app!.theme.fontParagraphWeight,
            refContext.value.selection.app!.theme.fontButtonWeight,
          ],
      ()=>{
        const root = document.querySelector(':root') as HTMLElement;
        root.style.setProperty('--font-h', refContext.value.selection.app!.theme.fontHeader);
        root.style.setProperty('--font-p', refContext.value.selection.app!.theme.fontParagraph);
        // root.style.setProperty('--font-button', refContext.value.selection.app!.theme.fontButton);

        root.style.setProperty('--font-weight-h', refContext.value.selection.app!.theme.fontHeaderWeight);
        root.style.setProperty('--font-weight-p', refContext.value.selection.app!.theme.fontParagraphWeight);
        // root.style.setProperty('--font-weight-button', refContext.value.selection.app!.theme.fontButtonWeight);
      },
      {immediate: true}
    )

    const refPageTop = ref('0px');
    const refAppBarPosition = ref('absolute');
    let refStyleAppBar = ref(refApp.appBar.getStyle(refContext.value.selection.app!.theme)) as Ref<AppBarStyle>;
    watch(
      ()=>[refApp],
      ()=>{
        refStyleAppBar.value = refApp.appBar.getStyle(refContext.value.selection.app!.theme) as AppBarStyle;
      },
      {immediate: true, deep: true}
    );
    watch(
      ()=>[
        props.item, refStyleAppBar, refContext.value.mode
      ],
      ()=>{
        const displayMode = refStyleAppBar.value.displayMode.getValue();
        let pageTopPx = 0;
        switch( displayMode ){
          case "Hovering":
            if (!refContext.value.mode.inAnyEditMode()){
              pageTopPx = 0;
              refContext.value.displayOption.pageSafeAreaInsetTop = 48;
            } else {
              pageTopPx = 48;
              refContext.value.displayOption.pageSafeAreaInsetTop = 0;
            }
            break;
          case "On Top":
          default:
            pageTopPx = 48;
            refContext.value.displayOption.pageSafeAreaInsetTop = 0;
            break;
        }
        refPageTop.value = pageTopPx + 'px';

        const stickyMode = refStyleAppBar.value.stickyMode.getValue();
        switch( stickyMode ){
          case "Sticky":
            refAppBarPosition.value = "fixed";
            break;
          case "Non sticky":
            refAppBarPosition.value = "absolute";
            break;
          default:
            refAppBarPosition.value = "absolute";
        }
      },
      {immediate: true, deep: true}
    );

    onMounted(() => {
      refContext.value.selection.app!.theme.paletteNameActive = window.matchMedia('(prefers-color-scheme: dark)').matches  ? "Dark" : "Light";
      // Listen to system dark/light mode
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        refContext.value.selection.app!.theme.paletteNameActive = e.matches ? "Dark" : "Light";
      });
    });

    //////////////////////////
    const refShowMenuPopup = ref(false);

    watch(
      () => refContext.value.uiState.getLastRequestMenuPopup(),
      () => {
        refShowMenuPopup.value = refContext.value.uiState.getLastRequestMenuPopup().show;
      },
    );

    //////////////////////////
    const getButtonStyle = ()=>{
      return {
        ...refStyleAppBar.value.font.getPredefinedStyleObject(refContext.value),
        fontFamily: refContext.value.selection.app!.theme.fontButton,
        fontWeight: refContext.value.selection.app!.theme.fontButtonWeight
      };
    }

    const getCancelButtonStyle = ()=>{
      const font = refStyleAppBar.value.font.getPredefinedStyleObject(refContext.value);
      return {
        fontSize: font.fontSize,
        color: refStyleAppBar.value.font.color.getColor(refContext.value)
      };
    }

    return {
      refApp,
      refContext,
      refPageId,
      refPageNode,
      refStyleAppBar,
      refPageTop,
      refAppBarPosition,
      refShowMenuPopup,
      getButtonStyle,
      getCancelButtonStyle,

      PaletteColor,

      getPathForPage
    };
  }
}
