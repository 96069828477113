import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.refItem.getMeta() === $setup.Text.meta ? '' : 'ContentEditorContainer GlobalContainer')
  }, [
    _createVNode(_component_ComponentWrapper, {
      item: $setup.refItem,
      context: $setup.refContext
    }, null, 8, ["item", "context"])
  ], 2))
}