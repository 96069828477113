
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import BlockContainer from '@/components/shared/util/BlockContainer.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import { Product } from '../../Product';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import { PaletteColor } from "@/components/shared/theme/PaletteSet";

// variable
export default {
  name: 'HeaderDefaultViewer',
  mixins: [MixinProps],
  components:{
    ComponentContainer,
    ComponentWrapper,
    BlockContainer,
},
  setup(props: any){
    const { getRefItemDeep, getRefContext } = useAddReactivity();
    const refProduct = getRefItemDeep<Product>(props);
    const refContext = getRefContext(props);

    return {
      refProduct,
      refContext,
      PaletteColor
    };
  }
}
