import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import Style from "./view/default/Style";
import { PropertyUrl } from "@/components/shared/property/propertyUrl/PropertyUrl";
import { Language } from '@/components/shared/view/LanguageOption';
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class Button extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Button", friendlyName: "Button"};

  static readonly nameTitle: string = "Title";
  static readonly nameUrl: string = "URL";

  title = new PropertyString(Button.nameTitle);
  url = new PropertyUrl(Button.nameUrl);

  public getStoreObjectItem(): Object{
    return {
      title: this.title.getStoreObject(),
      url: this.url.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.title.fromStoreObject(item.title);
    this.url.fromStoreObject(item.url);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    this.title.setValueOf(Language.default, "Title");
    this.url.setValueOf(Language.default, "https://google.com");

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const button = item as Button;
    this.title.from(button.title);
    this.url.from(button.url);
  }

  getMeta(): MetaInfo{
    return Button.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Button();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}