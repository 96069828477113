
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import BlockContainer from '@/components/shared/util/BlockContainer.vue';
import Contained from '@/components/shared/util/Contained.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import { ProductGallery } from '../../ProductGallery';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import Style from './Style';
import StyleContained from '@/components/shared/style/StyleContained';
import { Ref, onMounted, toRef } from 'vue';
import { useSerialLayoutInitialization } from '@/components/shared/view/SerialLayoutInitialization';

// variable
export default {
  name: 'HeaderDefaultViewer',
  mixins: [MixinProps],
  components:{
    ComponentContainer,
    ComponentWrapper,
    BlockContainer,
    Contained,
  },
  setup(props: any, context: any){
    const { getRefItem, getRefContext, getRefStyle } = useAddReactivity();
    const refProductGallery = getRefItem<ProductGallery>(props);
    const refContext = getRefContext(props);
    const refStyle = getRefStyle<Style>(props);
    const refStyleContained = toRef(refStyle.value, 'contained') as Ref<StyleContained>;

    const { emitLayoutInitialized, emitLayoutMinHeightChanged, refDomContainer, refDomContent } = useSerialLayoutInitialization(refProductGallery.value, context);

    onMounted(() => {
      // timeout is required to fix missed height change during initialization.
      // For example, page inited with the second language, which affects the text size.
      // 0ms does the trick as well.
      setTimeout( () => {
        const height = (refDomContent.value as HTMLElement).clientHeight as number;
        // console.log("ProductGallery onMounted: height = ", height);
        emitLayoutInitialized();
        emitLayoutMinHeightChanged(refProductGallery.value, height);
      }, 0 );
    });

    const onContentResized = (size: {width: number, height: number})=>{
      emitLayoutMinHeightChanged(refProductGallery.value, size.height);
    }

    return {
      refProductGallery,
      refContext,
      refStyle,
      refStyleContained,

      refDomContainer,
      refDomContent,

      onContentResized
    };
  }
}
