
import { onMounted, PropType, reactive, ref, toRef, watch } from 'vue';
import Context from '@/components/shared/view/Context';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import { templatesText } from '@/components/templates/blocks/TemplatesText';
import { BaseItem } from '../model/BaseItem';

export default {
  name: 'BlockSelector',
  props:{
    onSelected: {
      type: Function,
      required: false,
      default: undefined,
    },
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
  },
  components: {
    ComponentWrapper
  },
  setup(props: any) {
    const refContext = toRef(props, 'context');
    const refSelectorContext = reactive(new Context);
    const categories = ["Intro", "Contact"];
    const refCategory = ref(categories[0]);
    watch(
      ()=>refContext.value.selection.app!.theme,
      ()=>{
        refSelectorContext.from(refContext.value);
        refSelectorContext.mode.selectItem = false;
        refSelectorContext.mode.editContent = false;
        refSelectorContext.mode.editLayout = false;
        refSelectorContext.mode.editTextInline = false;
      },
      {immediate: true, deep: true}
    );

    const selectItem = (item: BaseItem) => {
      props.onSelected(item);
    };

    // Update UI
    const refDomContainer = ref<HTMLElement|null>(null);
    const refContainerWidthPx = ref(0);
    const refNumColumns = ref(0);

    const onContainerResized = (size: {width: number, height: number})=>{
      refContainerWidthPx.value = size.width;
    };

    watch(
      ()=>[refContainerWidthPx],
      ()=>{
        const desiredWidthPx = 400;
        refNumColumns.value = Math.floor(refContainerWidthPx.value / desiredWidthPx);
        refNumColumns.value = Math.max(1, refNumColumns.value);
        refNumColumns.value = Math.min(3, refNumColumns.value);
      },
      {immediate: true, deep: true}
    );

    onMounted(() => {
      setTimeout( () => {
        refContainerWidthPx.value = (refDomContainer.value as HTMLElement).clientWidth as number;
      }, 0 );
    });

    return {
      refSelectorContext,
      categories,
      refCategory,
      selectItem,

      refDomContainer,
      onContainerResized,
      refNumColumns,
      templatesText
    }
  }
}
