import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "GlobalRowLeft" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.Model.products, (product) => {
      return (_openBlock(), _createElementBlock("button", {
        key: product.ref,
        class: _normalizeClass(["GlobalTextButton", $setup.refProductRef.refId===product.urlSlug ? 'GlobalTextButtonSelected' : 'GlobalTextButtonNotSelected']),
        onClick: ()=>{$setup.refProductRef.refId = product.urlSlug;}
      }, _toDisplayString(product.title.getValueOf($setup.refContext.languageOption)), 11, _hoisted_2))
    }), 128))
  ]))
}