export
class TreeNode<T> {
  content: T|null;
  children: TreeNode<T>[];
  parent: TreeNode<T> | null;

  constructor(content: T | null) {
    this.content = content;
    this.children = [];
    this.parent = null;
  }

  getContent(): T{
    if (this.content===null){
      throw new Error("TreeNode<T> getContent(): content is null");
    }
    return this.content;
  }

  clone(): TreeNode<T> {
    const newNode = new TreeNode(this.content);
    newNode.children = this.children.map(child => {
      const childClone = child.clone();
      childClone.parent = newNode;
      return childClone;
    });
    return newNode;
  }
}

export
class Tree<T> {
  root: TreeNode<T> | null = null;

  from(tree: Tree<T>): void {
    if (tree.root) {
      this.root = tree.root.clone();
    } else {
      this.root = null;
    }
  }

  add(content: T, parentValue?: T): void {
    const newNode = new TreeNode(content);
    if (this.root === null) {
      this.root = newNode;
    } else if (parentValue !== undefined) {
      const parentNode = this.findValue(this.root, parentValue);
      if (parentNode) {
        newNode.parent = parentNode;
        parentNode.children.push(newNode);
      } else {
        throw new Error(`Parent node with content ${parentValue} not found.`);
      }
    } else {
      throw new Error("Cannot add a node without specifying the parent node when the tree is not empty.");
    }
  }

  remove(content: T): void {
    if (this.root === null) {
      throw new Error("Tree is empty.");
    }

    if (this.root.content === content) {
      this.root = null;
    } else {
      this.removeNode(this.root, content);
    }
  }

  private removeNode(node: TreeNode<T>, content: T): boolean {
    const index = node.children.findIndex(child => child.content === content);
    if (index !== -1) {
      node.children.splice(index, 1);
      return true;
    }

    for (const child of node.children) {
      const removed = this.removeNode(child, content);
      if (removed) {
        return true;
      }
    }

    return false;
  }

  findNode(sourceNode: TreeNode<T>, targetNode: TreeNode<T>): TreeNode<T> | null {
    if (targetNode === sourceNode) {
      return targetNode;
    }

    for (const child of sourceNode.children) {
      const result = this.findNode(child, targetNode);
      if (result) {
        return result;
      }
    }

    return null;
  }

  findValue(node: TreeNode<T>, content: T): TreeNode<T> | null {
    if (node.content === content) {
      return node;
    }

    for (const child of node.children) {
      const result = this.findValue(child, content);
      if (result) {
        return result;
      }
    }

    return null;
  }

  nodeExists(content: T): boolean {
    if (this.root === null) {
      return false;
    }
    return this.findValue(this.root, content) !== null;
  }

  // Traverse the tree (depth-first search)
  // Callback: return if stop traversing
  traverse(node: TreeNode<T> | null, callback: (childNode: TreeNode<T>, parentNode: TreeNode<T>) => boolean): void {
    if (node === null) {
      return;
    }

    for (const child of node.children) {
      const ifStop = callback(child, node);
      if (ifStop) continue;
      this.traverse(child, callback);
    }
  }

  moveNode(nodeToMove: TreeNode<T>, newParentNode: TreeNode<T>): void {
    if (this.root === null) {
      throw new Error("Tree is empty.");
    }

    const currentParent: TreeNode<T> | null = nodeToMove.parent;

    // Remove the node from its current parent's children
    if (currentParent !== null) {
      const index = currentParent.children.indexOf(nodeToMove);
      if (index !== -1) {
        currentParent.children.splice(index, 1);
      }
    }

    // Add the node to the new parent's children
    nodeToMove.parent = newParentNode;
    newParentNode.children.push(nodeToMove);
  }
}