import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import StyleFont from "@/components/shared/style/StyleFont"
import StyleContainer from "@/components/shared/style/StyleContained"
import { EnumPair } from "@/components/shared/style/StyleVariableString";
import Context from "@/components/shared/view/Context";
import { PaletteColor } from "@/components/shared/theme/PaletteSet";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Product Preview Style"};

  static readonly nameMaxWidth: string = "Max Width";

  public font = new StyleFont;
  public contained = new StyleContainer;

  public getStoreObjectItem(): Object{
    return {
      font: this.font.getStoreObject(),
      contained: this.contained.getStoreObject()
    };
  }

  getClassNames(): any{
    return {
      container: "ProductPreviewContainer",
      button: "ProductPreview",
    }
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");

    this.font.color.styleVariantName = PaletteColor.F1;
    this.font.size.enums = [new EnumPair("S", "12px"), new EnumPair("M", "16px"), new EnumPair("L", "20px"), ];

    this.contained.color.setValue("#6A6C6E28");
    this.contained.border.radius.setValue('10px');
    this.contained.border.color.setValue('#FFFFFF00');
    this.contained.maxWidth.enums = [new EnumPair("S", "200px"), new EnumPair("M", "300px"), new EnumPair("L", "400px"), ];

    this.customStyle = '{"background-color": "#000000"}';
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }

  getPredefinedStyleObject(context: Context) {
    const font = this.font.getPredefinedStyleObject(context);
    const border = this.contained.border.getPredefinedStyleObject(context);
    return {
      ...font,
      ...border,
      backgroundColor: this.contained.color.getColor(context),
      ...{
        textDecoration: 'none',
        padding: '0px 0px 0px 0px',
        textAlign: 'center',
        maxWidth: this.contained.contentMaxWidth.value,
        margin: 'auto',
      }
    };
  }
}