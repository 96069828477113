

export default {
  name: 'ImageGallery',
  props:{
    onImageSelected: {
      type: Function,
      required: false,
      default: undefined,
    },
  },
  setup(props: any) {
    props;
    const images = [
      "https://images.unsplash.com/photo-1704310957636-be5d273c8f0a?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.unsplash.com/photo-1599499332864-356ea4ffe0fc?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.unsplash.com/photo-1705564993082-61bce8f20f51?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.unsplash.com/photo-1705228775052-250033f5c9aa?w=1200&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwzNzMwMTU0fHxlbnwwfHx8fHw%3D",
      "https://images.unsplash.com/photo-1700581182740-dd9d3ad180cc?w=1200&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDMwfENEd3V3WEpBYkV3fHxlbnwwfHx8fHw%3D"
    ];

    for (let i = 0; i < 20; ++i){
      const url = `https://source.unsplash.com/random?sig=${i}`;
      images.push(url);
    }

    const imageSelected = (url: String) => {
      props.onImageSelected(url);
    };

    return {
      images,
      imageSelected
    }
  }
}
