
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import Contained from '@/components/shared/util/Contained.vue';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { Ref, computed, onMounted, toRef } from 'vue';
import {Location} from "../../Location";
import StyleLocation from './Style';
import StyleContained from "@/components/shared/style/StyleContained";
import { useAddReactivity } from '@/components/shared/view/AddReactivity';

export default {
  name: 'LocationDefaultViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components: {  ComponentContainer, Contained },
  setup(props: any, context: any){
    const { getRefItem, getRefContext, getRefStyle } = useAddReactivity();

    const refLocation = getRefItem<Location>(props);
    const refContext = getRefContext(props);
    const refStyleLocation = getRefStyle<StyleLocation>(props);
    const refStyleContained = toRef(refStyleLocation.value, 'contained') as Ref<StyleContained>;

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refLocation.value, context);
    onMounted(()=>{
      emitLayoutInitialized();
    });

    const computedStyleMap = computed(()=>{
      switch(refStyleLocation.value.colorMode.value){
        case "Normal":
          return {};
        case "Gray":
          return {filter: 'grayscale(100%)'};
        case "Dark":
          return {filter: 'invert(100%)'};
      }
      return {}
    });

    return{
      refLocation,
      refContext,
      refStyleLocation,
      refStyleContained,
      computedStyleMap
    }
  }
}
