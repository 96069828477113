import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.props.context.mode.selectItem)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        id: "Select",
        class: "Unselectable",
        onClick: _cache[0] || (_cache[0] = ($event: any) => {$setup.setSelectedItem($setup.props.context, $setup.refItem);})
      }, " Select this collection "))
    : _createCommentVNode("", true)
}