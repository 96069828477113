import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import StyleFont from "@/components/shared/style/StyleFont";
import StyleContainer from "@/components/shared/style/StyleContained";
import { EnumPair, StyleVariableString } from "@/components/shared/style/StyleVariableString";
import { PaletteColor } from "@/components/shared/theme/PaletteSet";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "App Bar Style"};

  public displayMode = new StyleVariableString("Display");
  public stickyMode = new StyleVariableString("Sticky");
  public font = new StyleFont;
  public contained = new StyleContainer;

  public getStoreObjectItem(): Object{
    return {
      displayMode: this.displayMode.getStoreObject(),
      stickyMode: this.stickyMode.getStoreObject(),
      font: this.font.getStoreObject(),
      contained: this.contained.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.displayMode.fromStoreObject(item.displayMode);
    this.stickyMode.fromStoreObject(item.stickyMode);
    this.font.fromStoreObject(item.font);
    this.contained.fromStoreObject(item.contained);
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");

    this.displayMode.enums = [new EnumPair("On Top", "On Top"), new EnumPair("Hovering", "Hovering")];
    this.displayMode.setEnum("Hovering");
    this.displayMode.enumOnly = true;

    this.stickyMode.enums = [new EnumPair("Sticky", "Sticky"), new EnumPair("Non sticky", "Non sticky")];
    this.stickyMode.setEnum("Sticky");
    this.stickyMode.enumOnly = true;

    this.font.size.enums = [new EnumPair("S", "14px"), new EnumPair("M", "16px"), new EnumPair("L", "18px"), ];
    this.font.size.setValue('14px');
    this.font.color.setStyleVariantName(PaletteColor.F3);
    this.font.color.setValue('#FFF');

    this.contained.color.setStyleVariantName(PaletteColor.B3);
    this.contained.opacity.setValue("0.5");
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }
}