import { BaseItem, MetaInfo } from "../model/BaseItem";
import { fonts } from "../theme/Fonts";
import { StyleVariableString } from "./StyleVariableString";

export
class EnumPair{
  name: string;
  value: string;

  constructor(name: string, value: string){
    this.name = name;
    this.value = value;

  }
}

export class StyleVariableFontFamily extends StyleVariableString{
  static readonly meta: MetaInfo = {typeName: "StyleVariableFontFamily", friendlyName: "Style Variable String"};

  constructor(itemName = "", value = "") {
    super();
    this.setItemName(itemName);
    this.value = value;
    this.enums = [
      new EnumPair("Theme", ""),
    ];
    fonts.forEach(font => {
      this.enums.push(
        new EnumPair(font, font)
      );
    });
    this.setEnum("Theme");

    this.setStyle(null);
  }

  public getMeta(): MetaInfo{
    return StyleVariableFontFamily.meta;
  }

  public override from(item: BaseItem): void{
    super.from(item);
  }

  public getDefaultInstance(): BaseItem{
    return new StyleVariableFontFamily();
  }
}