import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import StyleContained from "@/components/shared/style/StyleContained";
import { EnumPair, StyleVariableString } from "@/components/shared/style/StyleVariableString";
import Context from "@/components/shared/view/Context";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Page Style"};

  public contained = new StyleContained;

  public getStoreObjectItem(): Object{
    return {
      contained: this.contained.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.contained.fromStoreObject(item.contained);
  }

  getClassNames(): any{
    // TODO: complete css class names here and in the Viewer
    return {
    }
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");

    this.contained.maxWidth.enums = [new EnumPair("S", "800px"), new EnumPair("M", "1200px"), new EnumPair("L", "1600px")];
  }

  from(item: BaseItem): void{
    super.from(item);
    this.setStyleName((item as BaseStyle).getStyleName());
    this.contained.from((item as Style).contained);
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }

  getPredefinedStyleObject(context: Context) {
    throw new Error("Page default style getPredefinedStyleObject() not implemented");
  }
}