import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import Context from "@/components/shared/view/Context"
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import BaseViewFactory from "@/components/shared/view/BaseViewFactory";
import { defineAsyncComponent } from "vue";
import { Collection } from "../Collection";

export default
class ViewFactory extends BaseViewFactory{
  getItemMetaOfView(): MetaInfo {
    return Collection.meta;
  }

  getNameOfRegisteredStyles(): string[] {
    return [
      "default",
      "grid",
      "horizontal",
    ];
  }

  // overrided for using Editor in all the modes below.
  getFileName(context: Context): string{
    if (context.mode.editLayout || context.mode.selectItem)
      return "LayoutEditor.vue";
    else if (context.mode.editContent)
      return "ContentEditor.vue";
    else
      return "Viewer.vue";
  }

  async getStyleByName(styleName: string): Promise<BaseStyle>{
    const Style = await import("./"+styleName+"/Style");
    return new Style.default;
  }

  getView(styleName: string, context: Context): any{
    const viewFile = this.getViewFileNameWithPath(styleName, context);
    return defineAsyncComponent({
      // Prefix ./ must be defined explicitely
      loader: () => import('/'+viewFile),
    })
  }
}