
import { onMounted, ref, watch } from 'vue';
import { GlobalZIndex } from '@/shared/GlobalVariables';

export default {
  name: 'Draggable',
  props: {
    domRefParent: {
      required: true,
    },
    currentProportion: {
      type: Number,
      required: true,
    },
    onDragged: {
      type: Function,
      required: false,
      default: undefined,
    },
    onFinished: {
      type: Function,
      required: false,
      default: undefined,
    },
    id: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  setup(props: any){
    const domRefSlider = ref<HTMLElement|null>(null);

    const updatePositionChange = (offset: number)=>{
      const parentSize = (props.domRefParent as HTMLElement).getBoundingClientRect().width;
      props.onDragged(props.id, offset/parentSize);
    }

    let lastPosition = 0;
    const mouseMoveListener = function(e: MouseEvent) {
      const currentPosition = e.clientX;
      const offset = currentPosition-lastPosition;
      updatePositionChange(offset);
      lastPosition = currentPosition;
    };

    onMounted(() => {
      // Handle touch event for mobile devices
      (domRefSlider.value as HTMLElement).addEventListener('touchstart', function(e: TouchEvent) {
        lastPosition = e.touches[0].clientX;
      });
      (domRefSlider.value as HTMLElement).addEventListener('touchmove', function(e: TouchEvent) {
        const currentPosition = e.touches[0].clientX;
        const offset = currentPosition-lastPosition;
        updatePositionChange(offset);
        lastPosition = currentPosition;
      });
      (domRefSlider.value as HTMLElement).addEventListener('touchend', function(e: TouchEvent) {
        e;
        props.onFinished(props.id);
      });

      // Handle mouse event for desktop devices
      (domRefSlider.value as HTMLElement).addEventListener('mousedown', function(e: MouseEvent) {
        // console.log("mousedown");
        lastPosition = e.clientX;
        document.getElementById('app')?.addEventListener('mousemove', mouseMoveListener); // TODO: Id could change, maybe root is better?
      });
      document.addEventListener('mouseup', function(e: MouseEvent) {
        // console.log("mouseup");
        e;
        document.getElementById('app')?.removeEventListener('mousemove', mouseMoveListener); // TODO: Id could change, maybe root is better?
        props.onFinished(props.id);
      });
    });

    watch(
      () => props.domRefParent,
      () => {
        if (props.domRefParent!=null){
          // Handle mouseup outside slider
          document.getElementById('app')?.addEventListener('mouseup', function(e: MouseEvent) {
            // console.log("mouseup");
            e;
            document.getElementById('app')?.removeEventListener('mousemove', mouseMoveListener);
          });
        }
      },
      { immediate: true }
    );

    const zIndexEditorDraggableSlider = GlobalZIndex.EditorDraggableSlider;

    return {
      props,
      domRefSlider,
      zIndexEditorDraggableSlider,
    };
  }
}
