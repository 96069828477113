
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { Ref, computed, onMounted, toRef } from 'vue';
import { Spacer } from '../../Spacer';
import Context from '@/components/shared/view/Context';

// variable
export default {
  name: 'SpacerDefaultViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
  },
  setup(props: any, context: any){
    const refSpacer = toRef(props, 'item') as Ref<Spacer>;
    const refContext = toRef(props, 'context') as Ref<Context>;

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refSpacer.value, context);

    onMounted( ()=>{
      emitLayoutInitialized();
    });

    const computedIfEditing = computed( () : boolean => {
      return refContext.value.mode.editContent || refContext.value.mode.editTextInline;
    });

    return {
      props,
      refSpacer,
      computedIfEditing,
      emitLayoutInitialized,
    };
  }
}
