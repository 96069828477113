import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { BaseDefinition } from "@/components/shared/model/BaseDefinition";
import { ProductRef } from "@/components/product/productRef/ProductRef";
import Style from "./view/default/Style";
import Model from "@/shared/Model";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class ProductPreview extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "ProductPreview", friendlyName: "Product Preview"};

  static readonly nameProductRef: string = "Product Preview";

  productRef = new ProductRef(ProductPreview.nameProductRef);

  public getStoreObjectItem(): Object{
    return {
      productRef: this.productRef.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.productRef.fromStoreObject(item.productRef);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    this.productRef.setRefId(Model.products[0].ref);

    this.setStyle(new Style);
    this.setStyleVariantName("Primary");
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const productPreview = item as ProductPreview;
    this.productRef.from(productPreview.productRef);
  }

  getMeta(): MetaInfo{
    return ProductPreview.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new ProductPreview();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}