
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { onMounted } from 'vue';
import { Video } from '../../Video';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';

// variable
export default {
  name: 'VideoDefaultViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
  },
  setup(props: any, context: any){
    const { getRefItem,getRefContext } = useAddReactivity();
    const refVideo = getRefItem<Video>(props);
    const refContext = getRefContext(props);

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refVideo.value, context);

    onMounted(()=>{
      emitLayoutInitialized();
    })

    return {
      refVideo,
      refContext,
      emitLayoutInitialized,
    };
  }
}
