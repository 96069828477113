import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import Style from "./view/default/Style";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class Spacer extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Spacer", friendlyName: "Spacer"};

  static readonly nameHeight: string = "Height";

  public getStoreObjectItem(): Object{ return {}; }

  public fromStoreObjectItem(item: any){item;}

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const spacer = item as Spacer;
  }

  getMeta(): MetaInfo{
    return Spacer.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Spacer();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}