import { BaseStyle } from "@/components/shared/model/BaseStyle";
import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { Text } from "../text/Text";
import Style from "./view/default/Style";
import ViewFactory from "./view/ViewFactory";
import { typeToItem } from "@/components/shared/registry/All";

export
class Container extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Container", friendlyName: "Background"};

  static readonly nameContent: string = "Content";

  content : BaseItem = new Text(Container.nameContent);

  public getStoreObjectItem(): Object{
    return {
      content: this.content.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.content = typeToItem(item.content.type);
    this.content.fromStoreObject(item.content);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const container = item as Container;
    this.content.from(container.content);
  }

  getMeta(): MetaInfo{
    return Container.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Container();
  }

  public contains(item: BaseItem): BaseItem[]{
    if (this.content.ref === item.ref)
      return [this.content, this];

    const tmp = this.content.contains(item);
    if (tmp.length>0){
      tmp.push(this);
      return tmp;
    }
    return [];
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}