
import { onMounted, PropType } from "vue";
import {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import StyleContained from '../style/StyleContained';
import Context from "../view/Context";

export default {
  name: "Contained",
  props: {
    styleContained: {
      type: Object as PropType<StyleContained>,
      required: true,
    },
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
    onContentResized: {
      type: Function,
      required: false,
      default: undefined,
    },
    ifContainedContentInheritSize: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  setup(props: any, context: any) {
    const { refDomContainer, refDomContent} = useSerialLayoutInitialization(props.item, context);

    onMounted(() => {
      // timeout is required to fix missed height change during initialization.
      // For example, page inited with the second language, which affects the text size.
      // 0ms does the trick as well.
      if (props.onContentResized){
        setTimeout( () => {
          props.onContentResized({width: (refDomContent.value as HTMLElement).clientWidth, height: (refDomContent.value as HTMLElement).clientHeight});
        }, 10 );
      }
    });

    const getContainerPredefinedStyleObject = ()=>{
      return props.styleContained.getContainerPredefinedStyleObject(props.context);
    }

    const getContentPredefinedStyleObject = ()=>{
      return props.styleContained.getContentPredefinedStyleObject(props.context);
    }

    const getBackgroundPredefinedStyleObject = ()=>{
      return props.styleContained.getBackgroundPredefinedStyleObject(props.context);
    }
    return {
      props,
      refDomContainer,
      refDomContent,
      getContainerPredefinedStyleObject,
      getContentPredefinedStyleObject,
      getBackgroundPredefinedStyleObject
    };
  },
};
