
import MixinProps from '@/components/shared/view/MixinProps';
import { Text } from '../../Text';
import Editor from '@/components/shared/util/TinyMceEditorWidget.vue'
import { useRefVarsAndCurrentValue } from '@/components/shared/view/RefVarsAndCurrentValue';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';

export default {
  mixins: [MixinProps],
  name: 'TextEditor',
  components: { Editor },
  setup(props: any){
    const { getRefVarsAndCurrentValue } = useRefVarsAndCurrentValue();
    const [refText, refCurrentValue] = getRefVarsAndCurrentValue<Text>(props);
    const {getRefContext} = useAddReactivity();
    const refContext = getRefContext(props);

    const onTextChanged = (newText: string)=>{
      refCurrentValue.value = newText;
    }

    return {
      refText,
      refCurrentValue,
      refContext,
      onTextChanged
    }
  }
}
