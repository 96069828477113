import { BaseStyle } from "@/components/shared/model/BaseStyle";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { Text } from "../text/Text";
import Style from "./view/default/Style";
import { typeToItem } from "@/components/shared/registry/All";
import ViewFactory from "./view/ViewFactory";

export
enum LayoutType{
  Single='Single',
  Row='Row',
  Column='Column',
}

export
class Layout extends BaseItem{
  static readonly meta: MetaInfo = {typeName: "Layout", friendlyName: "Layout"};

  layoutType: LayoutType = LayoutType.Single;
  children: Layout[] = [];
  content: null|BaseItem = new Text("Text"); //TODO: init as undefined
  proportion = 1.0;

  public getStoreObjectItem(): Object{
    const childObjects : any[]= [];
    this.children.forEach((child: Layout)=>{
      childObjects.push(child.getStoreObject());
    });
    return {
      layoutType: this.layoutType,
      children: childObjects,
      content: this.content ? this.content.getStoreObject() : null,
      proportion: this.proportion,
    };
  }

  public fromStoreObjectItem(item: any){
    this.layoutType = item.layoutType;
    this.proportion = item.proportion;

    this.children.splice(0); // clear array without losing reactivity
    const childObjects : any[] = item.children;
    childObjects.forEach((layoutObject: any)=>{
      const layout = new Layout();
      layout.fromStoreObject(layoutObject);
      this.children.push(layout);
    });
    if (item.content){
      this.content = typeToItem(item.content.type);
      this.content.fromStoreObject(item.content);
    } else {
      this.content = null;
    }
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);

    const layout = item as Layout;
    this.layoutType = layout.layoutType;

    this.children = [];
    for (let i = 0; i<layout.children.length; ++i){
      this.children.push(layout.children[i].clone() as Layout);
    }
    this.content = layout.content ? layout.content.clone() : null;
    this.proportion = layout.proportion;
  }

  private morphTo(layoutType: LayoutType){
    if (this.layoutType == layoutType)
      return;
    if(layoutType != LayoutType.Single){
      // CLONE THE CURRENT ONE
      const newChild = new Layout();
      newChild.layoutType = this.layoutType;
      newChild.children = this.children;
      newChild.content = this.content;

      // MORPH
      this.layoutType = layoutType;
      this.children = [newChild];
      this.content = null;
    } else {
      if (this.children.length > 1)
        throw new Error("Layout.morphTo(): can only morph to LayoutType.Single with fewer than one child");
      this.layoutType = layoutType;
      this.content = this.children[0].content;
      this.children = [];
    }
  }

  addNewChild(content: BaseItem): Layout{
    return this.insertNewChild(content, this.children.length-1, 1 );
    // if (this.layoutType == LayoutType.Single)
    //   throw new Error("Layout.addNewChild(): cannot add child with LayoutType.Single");

    // // Make the current last child smaller
    // const lastChild = this.children[this.children.length-1];
    // const lastChildProportion = lastChild!.proportion;
    // lastChild!.proportion = lastChildProportion/2;

    // const newChild = new Layout;
    // newChild.content = content;
    // newChild.proportion = lastChildProportion/2;
    // this.children.push(newChild);

    // return newChild;
  }

  insertNewChild(content: BaseItem, idx: number, offset: number): Layout{
    if (this.layoutType == LayoutType.Single)
      throw new Error("Layout.insertNewChild(): cannot insert child with LayoutType.Single");

    // Make the previous child smaller
    const lastChild = this.children[idx];
    const lastChildProportion = lastChild!.proportion;
    lastChild!.proportion = lastChildProportion/2;

    const newChild = new Layout;
    newChild.content = content;
    newChild.proportion = lastChildProportion/2;
    if (idx + offset == this.children.length)
      this.children.push(newChild);
    else if (idx + offset >= 0)
      this.children.splice(idx + offset, 0, newChild);
    else
      throw new Error("Layout.insertNewChild(): cannot insert child");
    return newChild;
  }

  morphToRowAndAddChild(content: BaseItem){
    this.morphTo(LayoutType.Row);
    this.addNewChild(content);
  }

  morphToColumnAndAddChild(content: BaseItem){
    this.morphTo(LayoutType.Column);
    this.addNewChild(content);
  }

  removeChild(idx: number){
    if (this.children.length>1){
      const childProportion = this.children[idx]!.proportion;
      this.children.splice(idx, 1);
      const idxToGetProportion = idx>0 ? idx-1 : 0;
      this.children[idxToGetProportion]!.proportion += childProportion;
    }
    if (this.children.length==1){
      this.morphTo(LayoutType.Single);
    }
  }

  private moveChild(from: number, to: number){
    const elementToMove = this.children.splice(from, 1)[0];
    this.children.splice(to, 0, elementToMove);
  }

  public moveChildForwards(child: Layout){
    const from = this.children.indexOf(child);
    const to = from - 1;
    if (to>=0){
      this.moveChild(from, to);
    }
  }

  public moveChildBackwards(child: Layout){
    const from = this.children.indexOf(child);
    const to = from + 1;
    if (to<this.children.length){
      this.moveChild(from, to);
    }
  }

  proportionBefore(layout: Layout): number{
    let proportion = 0;
    for (const child of this.children) {
      if (child == layout){
        return proportion;
      }
      proportion += child.proportion;
    }
    throw new Error("Layout.proportionBefore(): layout not found.")
  }

  proportionMiddleOf(layout: Layout): number{
    return this.proportionBefore(layout) + layout.proportion / 2;
  }

  proportionAfter(layout: Layout): number{
    return this.proportionBefore(layout) + layout.proportion;
  }

  getMeta(): MetaInfo{
    return Layout.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Layout();
  }

  public contains(item: BaseItem): BaseItem[]{
    if (this.content?.ref == item.ref)
      return [this.content, this];
    if (this.content){
      const tmp = this.content.contains(item);
      if (tmp.length>0){
        tmp.push(this);
        return tmp;
      }
    }

    for (const child of this.children){
      if (child.ref == item.ref){
        return [this, child];
      }
    }
    for (const child of this.children){
      const tmp = child.contains(item);
      if (tmp.length>0){
        tmp.push(this);
        return tmp;
      }
    }
    return [];
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}