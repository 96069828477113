
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ContentEditable from '@/components/shared/util/ContentEditable.vue';
import { PropertyUrl } from './PropertyUrl';
import { useRefVarsAndCurrentValue } from "@/components/shared/view/RefVarsAndCurrentValue";

export default {
  mixins: [MixinProps],
  name: 'PropertyUrl',
  components:{
    ComponentContainer,
    ContentEditable
  },
  setup(props: any){
    const { getRefVarsAndCurrentValue } = useRefVarsAndCurrentValue();
    const [refPropertyUrl, refCurrentValue] = getRefVarsAndCurrentValue<PropertyUrl>(props);

    return {
      props,
      refPropertyUrl,
      refCurrentValue,
    }
  }
}
