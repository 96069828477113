import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { Collection } from "../collection/Collection";
import Style from "./view/default/Style";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import { Language } from '@/components/shared/view/LanguageOption';
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";
import { Image } from "@/components/elemental/image/Image";

export
class ImageGallery extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "ImageGallery", friendlyName: "Image Gallery"};

  static readonly nameTitle: string = "Title";
  static readonly nameImageRefs: string = "imagePreviews";

  title = new PropertyString(ImageGallery.nameTitle);
  images = [] as Image[];

  public getStoreObjectItem(): Object{
    const imageObjects : any[]= [];
    this.images.forEach((image: Image)=>{
      imageObjects.push(image.getStoreObject());
    });

    return {
      title: this.title.getStoreObject(),
      images: imageObjects,
    };
  }

  public fromStoreObjectItem(item: any){
    this.title.fromStoreObject(item.title);

    this.images.splice(0); // clear array without losing reactivity
    const imageObjects : any[] = item.images;
    imageObjects.forEach((imageObject: any)=>{
      const imagePreview = new Image();
      imagePreview.fromStoreObject(imageObject);
      this.images.push(imagePreview);
    });
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
    this.title.setValueOf(Language.default, "Image Gallery Title");

    this.images.push(new Image(""));
    this.images.push(new Image(""));
    this.images.push(new Image(""));
    this.images.push(new Image(""));
    this.images.push(new Image(""));
    this.images.push(new Image(""));
    this.images.push(new Image(""));
    this.images.push(new Image(""));
    this.images.push(new Image(""));
    this.images.push(new Image(""));
    this.images.push(new Image(""));
    this.images.push(new Image(""));

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const imageGallery = item as ImageGallery;
    this.title.from(imageGallery.title);

    for (let i = 0; i<imageGallery.images.length; ++i){
      const clonedElement = imageGallery.images[i].clone() as Image;
      this.images.push(clonedElement);
    }
  }

  public contains(item: BaseItem): BaseItem[]{
    for (const image of this.images){
      if (image.ref == item.ref){
        return [image, this];
      }
    }
    return [];
  }

  getMeta(): MetaInfo{
    return ImageGallery.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new ImageGallery();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}