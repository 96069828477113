
import { Ref, computed, onMounted, ref, toRef, watch } from 'vue';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import FluidElementViewer from '../shared/FluidElement.vue';
import AddItemButton from "@/components/shared/util/AddItemButton.vue";
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { FluidContainer } from '../../FluidContainer';
import { ItemInfo, SizeHint, findItemInfo } from '@/components/shared/registry/Items';
import Context from '@/components/shared/view/Context';
import { scrollToItem } from '@/components/shared/view/AutoScroll';
import { BaseItem } from '@/components/shared/model/BaseItem';
import { AnchorPosition, FluidElement } from '../shared/FluidElement';
import { AnchorNode } from '../shared/AnchorTree';

// variable
export default {
  name: 'FluidContainerDefaultViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
    FluidElementViewer,
    AddItemButton,
  },
  setup(props: any, context: any){
    const refFluidContainer = ref(props.item) as Ref<FluidContainer>; //BROKEN
    const refContext = toRef(props, 'context') as Ref<Context>;

    const { emitLayoutInitialized, emitLayoutMinHeightChanged } = useSerialLayoutInitialization(refFluidContainer.value, context);

    const domRefFluidContainer = ref<HTMLElement|null>(null);

    const refMinHeight = ref(48);

    const refIfShowMiddleLine = ref(false);
    const refActiveSpatialChange = ref(false);

    const computeIfShowMoreOptions = computed((): boolean => {
      if (refContext.value!.selection.item == null)
        return false;
      const editLayout = refContext.value!.mode.editLayout;
      const selectItem = refContext.value!.mode.selectItem;
      const isRelatedItemSelected = refContext.value!.selection.item.ref==refFluidContainer.value!.ref;
      const containsSelectedItem = refFluidContainer.value!.contains(refContext.value!.selection.item).length>0;
      return editLayout && selectItem && (isRelatedItemSelected || containsSelectedItem);
    })

    const updateHeight = ()=>{
      // Adjust container height if needed
      refMinHeight.value = refFluidContainer.value.anchorTree.getMinHeight();
      refMinHeight.value = refMinHeight.value < 64 ? 64 : refMinHeight.value; // the constant min height
      // console.log("FluidContainerViewer: refMinHeight = ", refMinHeight.value);
      emitLayoutMinHeightChanged(refFluidContainer.value, refMinHeight.value);
    }

    const onContainerResized = ()=>{
      const containerHeight = domRefFluidContainer.value!.offsetHeight;
      refFluidContainer.value.anchorTree.setRootElementHeight(containerHeight);
      // console.log("Container height = ", refFluidContainer.value!.anchorTree.root!.getContent().spatial.displayed.height);
    }

    onMounted(()=>{
      setTimeout(()=>{onContainerResized();}, 0);

      refFluidContainer.value.anchorTree.updateElementArray();
      if (refFluidContainer.value.anchorTree.elementNodeArray.length==0){
        emitLayoutInitialized();
      }
    });

    watch(
      () => refFluidContainer.value!.anchorTree.root,
      () => {
        updateHeight();
        if (refActiveSpatialChange.value === false){
          console.log("FluidElement: updateAllDisplayedY for change in root");
          refFluidContainer.value!.anchorTree.updateAllDisplayedY();
        }
      },
      { immediate: true, deep: true }
    );

    // refSelectedElementNode is the FluidElement with its item currently selected
    const refSelectedElementNode = ref(null) as Ref<AnchorNode | null>;
    const refSelectedAnchorTargetNode = ref(null) as Ref<AnchorNode | null>;
    watch(
      () => refContext.value!.selection.item,
      () => {
        for (const elementNode of refFluidContainer.value!.anchorTree.elementNodeArray){
          if (elementNode.getContent().item == refContext.value!.selection.item){
            refSelectedElementNode.value = elementNode;
            refSelectedAnchorTargetNode.value = refSelectedElementNode.value.parent;
            return;
          }
        }
        refSelectedElementNode.value = null;
      },
      { immediate: true }
    );

    let numChildrenInitialized = 1; // 1 because the root to be excluded
    const onChildLayoutInitialized = (child: BaseItem)=>{
      child;
      ++numChildrenInitialized;
      // TODO: initialize after order.
      updateHeight();
      if (numChildrenInitialized == refFluidContainer.value!.anchorTree.elementNodeArray.length){
        emitLayoutInitialized();
        setTimeout(()=>{
          refFluidContainer.value!.anchorTree.updateAllDisplayedY();
        }, 0);
        // console.log("FluidContainer: all children finished initialization");
      }
    }

    // Modes:
    //   1. Edit mode: change position or size by dragging -> No pushing, changing desired rect.
    //   2. Edit mode: change size by changing content, for example text -> Pushing, changing desired rect.
    //   3. View mode: viewport size has been changed -> Pushing, no changing desired rect.
    const onFluidElementChanged = (elementChanged: FluidElement, deriveFromAnchor: boolean, ifChangeDesired: boolean)=>{
      // refFluidContainer.value!.anchorTree.printDebugInfo();
      if (deriveFromAnchor){
        let skipNode = refFluidContainer.value.anchorTree.findValue(refFluidContainer.value.anchorTree.root!, elementChanged);
        refFluidContainer.value!.anchorTree.updateAllDisplayedY(skipNode === null ? undefined : skipNode);
      } else {
        const x = Math.round(elementChanged.spatial.displayed.x / intervalHorizontal) * intervalHorizontal;
        const width = Math.round(elementChanged.spatial.displayed.width / intervalHorizontal) * intervalHorizontal;
        const xCenter = x + width/2;
        if (xCenter>0.5-intervalHorizontal/4 && xCenter<0.5+intervalHorizontal/4) {
          refIfShowMiddleLine.value = true;
        } else {
          refIfShowMiddleLine.value = false;
        }
      }
      // change desired rect for all elementNodes
      if (ifChangeDesired){
        updateSpatialDesired();
      }

      updateHeight();
    }

    const callbacks = {
      onChildLayoutInitialized,
      onFluidElementChanged,
    }

    const updateSpatialDesired = ()=>{
      for (const elementNode of refFluidContainer.value!.anchorTree.elementNodeArray){
        elementNode.getContent().spatial.desired.from(elementNode.getContent().spatial.displayed);
      }
    }

    const addItem = (item: BaseItem, sizeHint: SizeHint, requestEdit = true, ifStickToContentHeight = true)=>{
      updateSpatialDesired();
      // add to bottom
      const spaceHeight = intervalVertical * 3;
      refFluidContainer.value!.addElement(item, (1-sizeHint.width)/2, refMinHeight.value + spaceHeight, sizeHint.width, sizeHint.height, ifStickToContentHeight);
      updateHeight();
      refContext.value!.setSelectedItem(item);
      if(requestEdit){
        refContext.value!.uiState.requestEditType('Content');
      }

      scrollToItem(item);
    }

    const addElement = (itemInfo: ItemInfo)=>{
      const newItem = itemInfo.getInstance();
      addItem(newItem, itemInfo.sizeHint);
    };

    const pasteItem = ()=>{
      const clipboard = refContext.value!.getClipboard();
      if (!clipboard){
        throw new Error("FluidContainer: pasteItem: no item in clipboard");
      }
      if (clipboard.getMeta()===FluidElement.meta){
        const fluidElement = clipboard as FluidElement;
        const sizeHint = {width: fluidElement.spatial.displayed.width, height: fluidElement.spatial.displayed.height};
        addItem(fluidElement.item.clone(), sizeHint, false, fluidElement.spatial.ifStickToContentHeight);
      } else {
        const item = clipboard.clone();
        const itemInfo = findItemInfo(item.getMeta().typeName);
        addItem(item, itemInfo.sizeHint, false);
      }
    };

    const unselectItem = ()=>{
      refContext.value.selection.item = null;
    };

    const changeAnchor = (sourceNode: AnchorNode, targetNode: AnchorNode)=>{
      const elementSpatial = sourceNode.content!.spatial;
      switch(elementSpatial.targetAnchorVerticalPosition){
        case AnchorPosition.None:
          refFluidContainer.value.anchorTree.link(sourceNode, targetNode, sourceNode.getContent().spatial.sourceAnchorVerticalPosition, sourceNode.getContent().spatial.targetAnchorVerticalPosition);
          if (sourceNode.getContent().spatial.sourceAnchorVerticalPosition === AnchorPosition.None) sourceNode.getContent().spatial.sourceAnchorVerticalPosition = AnchorPosition.Top;
          if (sourceNode.getContent().spatial.targetAnchorVerticalPosition === AnchorPosition.None) sourceNode.getContent().spatial.targetAnchorVerticalPosition = AnchorPosition.Bottom;
          refSelectedAnchorTargetNode.value = targetNode;
          break;
        case AnchorPosition.Top:
          refFluidContainer.value.anchorTree.unlink(sourceNode);
          break;
        case AnchorPosition.Center:
          elementSpatial.targetAnchorVerticalPosition = AnchorPosition.Top;
          break;
        case AnchorPosition.Bottom:
          elementSpatial.targetAnchorVerticalPosition = AnchorPosition.Center;
          break;
      }
      onFluidElementChanged(sourceNode.getContent(), false, true);
    }

    const actions = {addElement, pasteItem, unselectItem, changeAnchor};

    const intervalHorizontal = 1/24;
    const intervalVertical = 16;

    const constants = {intervalHorizontal, intervalVertical};

    return {
      refFluidContainer,
      refContext,
      domRefFluidContainer,
      onContainerResized,
      refSelectedElementNode,
      refSelectedAnchorTargetNode,
      refMinHeight,
      refIfShowMiddleLine,
      refActiveSpatialChange,
      computeIfShowMoreOptions,
      ...callbacks,
      ...actions,
      ...constants,
      AnchorPosition
    };
  }
}
