import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import { EnumPair, StyleVariableString } from "./StyleVariableString";
import { StyleVariableColor } from "./StyleVariableColor";
import Context from "../view/Context";
import { StyleVariableFontFamily } from "./StyleVariableFontFamily";

export default
class StyleFont extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "StyleFont", friendlyName: "Font"};

  static readonly nameSize: string = "Size";
  static readonly nameFont: string = "Font";
  static readonly nameWeight: string = "Weight";
  static readonly nameMode: string = "Mode";
  static readonly nameColor: string = "Color";
  static readonly nameGradient: string = "Gradient";

  public size = new StyleVariableString(StyleFont.nameSize, "16px");
  public font = new StyleVariableFontFamily(StyleFont.nameFont, "");
  public weight = new StyleVariableString(StyleFont.nameWeight, "");
  public mode = new StyleVariableString(StyleFont.nameMode, "");
  public color = new StyleVariableColor(StyleFont.nameColor, "#000000");
  public gradient = new StyleVariableString(StyleFont.nameGradient, "");

  public getStoreObjectItem(): Object{
    return {
      size: this.size.getStoreObject(),
      font: this.font.getStoreObject(),
      weight: this.weight.getStoreObject(),
      mode: this.mode.getStoreObject(),
      color: this.color.getStoreObject(),
      gradient: this.gradient.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.size.fromStoreObject(item.size);
    this.font.fromStoreObject(item.font);
    this.weight.fromStoreObject(item.weight);
    this.mode.fromStoreObject(item.mode);
    this.color.fromStoreObject(item.color);
    this.gradient.fromStoreObject(item.gradient);
  }

  constructor (){
    super();
    this.setItemName("StyleFont");

    this.weight.setEnum("Default");

    this.weight.enums = [
      new EnumPair("Default", ""),
      new EnumPair("Thin", "200"),
      new EnumPair("Normal", "Normal"),
      new EnumPair("Bold", "Bold"),
    ];
    this.weight.setEnum("Default");

    this.gradient.enums = [
      new EnumPair("Blue", "linear-gradient(45deg, #7B6AB9 10%, #8BC4DB 90%)"),
      new EnumPair("Sunset", "linear-gradient(45deg, #9587E2 10%, #DA889B 90%)"),
      new EnumPair("Galaxy", "linear-gradient(45deg, #D2735E 10%, #7454CC 90%)"),
      new EnumPair("Lake", "linear-gradient(45deg, #5D79B6 20%, #5BA07A 80%)"),
      new EnumPair("Snow", "linear-gradient(145deg, #FFFFFF, #E6E6E6)"),
    ];
    this.gradient.setEnum("Galaxy");

    this.mode.enums = [
      new EnumPair("Color", "Color"),
      new EnumPair("Gradient", "Gradient"),
    ];
    this.mode.enumOnly=true;
    this.mode.setEnum("Color");
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const styleFont = item as StyleFont;
    this.font.from(styleFont.font);
    this.weight.from(styleFont.weight);
    this.size.from(styleFont.size);
    this.mode.from(styleFont.mode);
    this.color.from(styleFont.color);
    this.gradient.from(styleFont.gradient);
  }

  getMeta(): MetaInfo{
    return StyleFont.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new StyleFont();
  }

  getPredefinedStyleObject(context: Context) : {fontFamily: string, fontWeight: string, fontSize: string, color: string, background: string, webkitBackgroundClip: string}{
    return {
      fontFamily: this.font.getValue(),
      fontWeight: this.weight.getValue(),
      fontSize: this.size.getValue(),
      color: this.mode.getValue() === "Color" ? this.color.getColor(context) : "transparent",
      background: this.mode.getValue() === "Color" ? "" : this.gradient.getValue(),
      webkitBackgroundClip: this.mode.getValue() === "Color" ? "" : "text"
    };
  }
}