import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import StyleContained from "@/components/shared/style/StyleContained";
import Context from "@/components/shared/view/Context";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Background Style"};

  getClassNames(): any{
    // TODO: complete css class names here and in the Viewer
    return {
    }
  }

  public contained = new StyleContained;

  public getStoreObjectItem(): Object{
    return {
      contained: this.contained.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.contained.fromStoreObject(item.contained);
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }

  getContainerPredefinedStyleObject(context: Context){
    return this.contained.getContainerPredefinedStyleObject(context);
  }

  getContentPredefinedStyleObject(context: Context){
    return this.contained.getContentPredefinedStyleObject(context);
  }
}