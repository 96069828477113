import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import { EnumPair, StyleVariableString } from "@/components/shared/style/StyleVariableString";
import Context from "@/components/shared/view/Context";
import StyleContained from "@/components/shared/style/StyleContained";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Image Gallery Style"};

  static readonly nameWidth: string = "Width";
  static readonly nameAspectRatio: string = "Aspect Ratio";
  static readonly nameBorderRadius: string = "Border Radius";
  static readonly nameGap: string = "Gap";

  public width = new StyleVariableString(Style.nameWidth, "100");
  public aspectRatio = new StyleVariableString(Style.nameAspectRatio, "1");
  public borderRadius = new StyleVariableString(Style.nameBorderRadius, "0px");
  public gap = new StyleVariableString(Style.nameGap, "0px");

  public contained = new StyleContained;

  public getStoreObjectItem(): Object{
    return {
      width: this.width.getStoreObject(),
      aspectRatio: this.aspectRatio.getStoreObject(),
      borderRadius: this.borderRadius.getStoreObject(),
      gap: this.gap.getStoreObject(),
      contained: this.contained.getStoreObject(),
    };
  }

  public fromStoreObjectItem(item: any){
    this.width.fromStoreObject(item.width);
    this.aspectRatio.fromStoreObject(item.aspectRatio);
    this.borderRadius.fromStoreObject(item.borderRadius);
    this.gap.fromStoreObject(item.gap);
    this.contained.fromStoreObject(item.contained);
  }


  getClassNames(): any{
    // TODO: complete css class names here and in the Viewer
    return {
    }
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");

    this.contained.alignment.setEnum("Start");

    this.width.enums = [
      new EnumPair("S", "100"),
      new EnumPair("M", "150"),
      new EnumPair("L", "400"),
    ];
    this.width.setEnum("M");

    this.aspectRatio.enums = [
      new EnumPair("2.4:1", "2.4/1"),
      new EnumPair("3:2", "3/2"),
      new EnumPair("1:1", "1"),
      new EnumPair("2:3", "2/3"),
      new EnumPair("1:2.4", "1/2.4"),
    ];
    this.aspectRatio.setEnum("1:1");

    this.borderRadius.enums = [
      new EnumPair("0", "0px"),
      new EnumPair("S", "4px"),
      new EnumPair("M", "8px"),
      new EnumPair("L", "16px"),
    ];
    this.borderRadius.setEnum("M");

    this.gap.enums = [
      new EnumPair("0", "0px"),
      new EnumPair("S", "4px"),
      new EnumPair("M", "8px"),
      new EnumPair("L", "16px"),
    ];
    this.gap.setEnum("M");
  }

  from(item: BaseItem): void{
    super.from(item);
    this.setStyleName((item as BaseStyle).getStyleName());
    this.width.from((item as Style).width);
    this.aspectRatio.from((item as Style).aspectRatio);
    this.borderRadius.from((item as Style).borderRadius);
    this.gap.from((item as Style).gap);
    this.contained.from((item as Style).contained);
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }

  getPredefinedStyleObject(context: Context) {
    throw new Error("ImageGallery default style getPredefinedStyleObject() not implemented");
  }
}