
import { getItemInfos, findItemInfo } from '@/components/shared/registry/Items';
import { PropType, Ref, reactive, toRef, watch } from 'vue';
import Context from '@/components/shared/view/Context';

export default {
  name: 'ItemSelector',
  props:{
    onSelected: {
      type: Function,
      required: false,
      default: undefined,
    },
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
  },
  setup(props: any) {
    const refContext = toRef(props, 'context') as Ref<Context>;
    const refSelectorContext = reactive(new Context);
    watch(
      ()=>refContext.value.selection.app!.theme,
      ()=>{
        refSelectorContext.from(refContext.value);
        refSelectorContext.mode.selectItem = false;
        refSelectorContext.mode.editContent = false;
        refSelectorContext.mode.editLayout = false;
        refSelectorContext.mode.editTextInline = false;
      },
      {immediate: true, deep: true}
    );

    const selectItem = (typeName: string) => {
      props.onSelected(findItemInfo(typeName));
    };

    return {
      refSelectorContext,
      getItemInfos,
      selectItem
    }
  }
}
