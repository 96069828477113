import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f9c1dd1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal__header" }
const _hoisted_2 = { class: "modal__body" }
const _hoisted_3 = { class: "modal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      ($setup.refShow)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "modal",
            style: _normalizeStyle({height: $setup.refHeight, bottom: $setup.refBottom})
          }, [
            ($setup.refBackdrop)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "modal__backdrop",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.dismiss && $setup.dismiss(...args)))
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "modal__dialog",
              style: _normalizeStyle({height: $setup.refHeight, bottom: $setup.refBottom})
            }, [
              _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "header", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "body", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
              ])
            ], 4)
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}