import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyCode } from "@/components/shared/property/propertyCode/PropertyCode";
import Style from "./view/default/Style";
import { Language } from '@/components/shared/view/LanguageOption';
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class CustomWidget extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "CustomWidget", friendlyName: "Custom Widget"};

  static readonly nameCode: string = "Code";

  code = new PropertyCode(CustomWidget.nameCode);

  public getStoreObjectItem(): Object{
    return {
      code: this.code.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.code.fromStoreObject(item.code);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
    const defaultCode =
`<a class="C1" href="https://www.freecodecamp.org/" target="_blank">freeCodeCamp</a>

<style>.C1{color: red;}</style>

<script></script>`;

    this.code.setValueOf(Language.default, defaultCode);

    this.setStyle(new Style);
    this.setStyleVariantName("Primary");
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const customWidget = item as CustomWidget;
    this.code.from(customWidget.code);
  }

  getMeta(): MetaInfo{
    return CustomWidget.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new CustomWidget();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}