
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import BlockContainer from '@/components/shared/util/BlockContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue'
// import SelectCollectionButton from '@/components/elemental/collection/view/shared/SelectCollectionButton.vue';
import AddItemButton from "@/components/shared/util/AddItemButton.vue"
import ItemToolBar from '@/components/elemental/collection/view/shared/ItemToolBar.vue';
import useCollection from '../shared/useCollection';
import { Collection } from '../../Collection';
import { Ref, toRef } from 'vue';
// import { ItemInfo } from '@/components/shared/registry/Items';

export default {
  name: 'CollectionLayoutEditor',
  mixins: [MixinProps],
  components: {
    ComponentContainer,
    BlockContainer,
    ComponentWrapper,
    // SelectCollectionButton,
    AddItemButton,
    ItemToolBar,
  },
  setup(props: any){
    const refCollection = toRef(props, 'item') as Ref<Collection>;

    const { tryCreateAndInsertNewItemAt, addNewItemAt } = useCollection(refCollection.value);

    return{
      props,
      refCollection,
      addNewItemAt,
      tryCreateAndInsertNewItemAt,
    }
  },
}
