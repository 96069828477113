
import MixinProps from '@/components/shared/view/MixinProps';
import StyleContained from '../StyleContained';
import { Ref, toRef } from 'vue';
import Context from '../../view/Context';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import ImagePicker from '@/components/shared/util/ImagePicker.vue';

export default {
  mixins: [MixinProps],
  name: 'EditorStyleContained',
  components: {ComponentWrapper, ImagePicker},
  setup(props: any){
    const refStyleContained = toRef(props, 'item') as Ref<StyleContained>;
    const refContext = toRef(props, 'context') as Ref<Context>;
    return {
      refStyleContained,
      refContext
    }
  }
}
