
import { onMounted, ref, watch } from 'vue';
import { GlobalZIndex } from '@/shared/GlobalVariables';
import useUiFix from '@/components/shared/view/UiFix';
export default {
  name: 'Draggable',
  props: {
    domRefParent: {
      required: true,
    },
    onStarted: {
      type: Function,
      required: false,
      default: undefined,
    },
    onDragged: {
      type: Function,
      required: false,
      default: undefined,
    },
    onFinished: {
      type: Function,
      required: false,
      default: undefined,
    },
    styleObject: {
      type: Object,
      required: false,
    }
  },
  setup(props: any){
    const domRefSlider = ref<HTMLElement|null>(null);

    const updatePositionChange = (offsetX: number, offsetY: number)=>{
      props.onDragged(props.id, offsetX, offsetY);
    }

    let lastPositionX = 0;
    let lastPositionY = 0;

    const mouseMoveListener = function(e: MouseEvent) {
      const currentPositionX = e.clientX;
      const currentPositionY = e.clientY;
      const offsetX = currentPositionX-lastPositionX;
      const offsetY = currentPositionY-lastPositionY;
      updatePositionChange(offsetX, offsetY);
      lastPositionX = currentPositionX;
      lastPositionY = currentPositionY;
    };

    const mouseUpListener = function(e: MouseEvent) {
      e;
      props.onFinished();
      document.removeEventListener('mousemove', mouseMoveListener);
      document.removeEventListener('mouseup', mouseUpListener);
    };

    onMounted(() => {
      // Handle touch event for mobile devices
      (domRefSlider.value as HTMLElement).addEventListener('touchstart', function(e: TouchEvent) {
        props.onStarted();
        lastPositionX = e.touches[0].clientX;
        lastPositionY = e.touches[0].clientY;
      });
      (domRefSlider.value as HTMLElement).addEventListener('touchmove', function(e: TouchEvent) {
        const currentPositionX = e.touches[0].clientX;
        const currentPositionY = e.touches[0].clientY;
        const offsetX = currentPositionX-lastPositionX;
        const offsetY = currentPositionY-lastPositionY;
        updatePositionChange(offsetX, offsetY);
        lastPositionX = currentPositionX;
        lastPositionY = currentPositionY;
      });
      (domRefSlider.value as HTMLElement).addEventListener('touchend', function(e: TouchEvent) {
        e;
        props.onFinished();
      });

      // Handle mouse event for desktop devices
      (domRefSlider.value as HTMLElement).addEventListener('mousedown', function(e: MouseEvent) {
        // console.log("mousedown");
        props.onStarted();
        lastPositionX = e.clientX;
        lastPositionY = e.clientY;
        document.addEventListener('mousemove', mouseMoveListener);
        document.addEventListener('mouseup', mouseUpListener);
      });

      ///////////////////////////////
      // Disable swipe to navigate
      if (domRefSlider.value){
        const { preventEdgeSwipe } = useUiFix();
        // Following disables touch
        preventEdgeSwipe(domRefSlider.value);
      }
      ///////////////////////////////
    });

    watch(
      () => props.domRefParent,
      () => {
        if (props.domRefParent!=null){
          // Handle mouseup outside slider
          document.getElementById('app')?.addEventListener('mouseup', function(e: MouseEvent) {
            // console.log("mouseup");
            e;
            document.getElementById('app')?.removeEventListener('mousemove', mouseMoveListener);
          });
        }
      },
      { immediate: true }
    );

    const zIndexEditorDraggableSlider = GlobalZIndex.EditorDraggableSlider;

    const mergedStyle = {...props.styleObject, position: 'absolute', zIndex: zIndexEditorDraggableSlider, touchAction: 'none'};

    return {
      props,
      domRefSlider,
      zIndexEditorDraggableSlider,
      mergedStyle
    };
  }
}
