
import { Theme } from '@/components/shared/theme/Theme';
import { PropType, Ref, ref, watch } from 'vue';
import Context from '@/components/shared/view/Context';
import { StyleVariableColor } from '@/components/shared/style/StyleVariableColor';
import { ColorPicker } from 'vue-accessible-color-picker';

export default {
  name: 'PaletteSelector',
  components: {
    ColorPicker
  },
  props: {
    theme: {
      type: Object as PropType<Theme>,
      required: true,
    },
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
  },
  setup(props: any){
    const refTheme = props.theme as Theme;
    const refContext = props.context as Context;
    const refColorEditing = ref(null) as Ref<null|StyleVariableColor>;

    // If dark/light mode changes, the displayed palette will change.
    watch(
      ()=>[refContext.selection.app!.theme.paletteNameActive],
      ()=>{
        refColorEditing.value = null;
      }
    );

    return{
      refTheme,
      refContext,
      refColorEditing,
    }
  },
}
