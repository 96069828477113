import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor", true)!

  return (_openBlock(), _createBlock(_component_Editor, {
    text: $setup.refCurrentValue,
    onTextChanged: $setup.onTextChanged,
    ifEditing: true,
    inline: false,
    context: $setup.refContext
  }, null, 8, ["text", "onTextChanged", "context"]))
}