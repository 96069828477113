import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import Context from "@/components/shared/view/Context"

export default
abstract class BaseViewFactory{
  abstract getItemMetaOfView(): MetaInfo;

  abstract getNameOfRegisteredStyles(): string[];

  abstract getView(styleName: string, context: Context): any;

  private getPath(styleName: string): string{
    return styleName+"/";
  }

  getFileName(context: Context): string{
    if (context.mode.editContent)
      return "Editor.vue";
    else
      return "Viewer.vue";
  }

  getViewFileNameWithPath(styleName: string, context: Context): string{
    return this.getPath(styleName)+this.getFileName(context);
  }

  // TODO: use meta info instead of string
  // TODO: use BaseStyle as return type, same for the derived classes
  abstract getStyleByName(styleName: string): any;

  getComponent(item: BaseItem, context: Context): any{
    if (item.getStyle(context.selection.app!.theme) != null)
      return this.getView(item.getStyle(context.selection.app!.theme)!.getStyleName(), context);
    else
      return this.getView("default", context);
    // throw new Error("BaseViewFactory.getComponent: invalid item style.")
  }
}