import { BaseItem, MetaInfo } from '@/components/shared/model/BaseItem';
import { Image } from '@/components/elemental/image/Image';
import { ImageGallery } from '@/components/elemental/imageGallery/ImageGallery';
import { Button } from '@/components/elemental/button/Button';
import { Text } from '@/components/elemental/text/Text';
import { Location } from '@/components/elemental/location/Location';
// import { ProductPreview } from '@/components/product/productPreview/ProductPreview';
import { Spacer } from '@/components/elemental/spacer/Spacer';
import { Video } from '@/components/elemental/video/Video';
import { CustomWidget } from '@/components/custom/customWidget/CustomWidget';
import { ProductGallery } from '@/components/product/productGallery/ProductGallery';
import { Container } from '@/components/elemental/container/Container';
import { Layout } from '@/components/elemental/layout/Layout';
import { Collection } from '@/components/elemental/collection/Collection';
import { FluidContainer } from '@/components/elemental/fluidContainer/FluidContainer';
import { AppBar } from '@/components/app/appBar/AppBar';
import { PageRef } from '@/components/page/pageRef/PageRef';
import { PropertyString } from '../property/propertyString/PropertyString';
import { ProductRef } from '@/components/product/productRef/ProductRef';
import { Product } from '@/components/product/product/Product';
import { App } from '@/components/app/app/App';
import { Page } from '@/components/page/page/Page';
import { PageNode } from '@/components/page/pageNode/PageNode';

export
const typeToItem = (typeName: string): BaseItem => {
  const typeToItemMap = new Map<string, BaseItem>([
    [App.meta.typeName, new App()],
    [Page.meta.typeName, new Page()],
    [Container.meta.typeName, new Container()],
    [Layout.meta.typeName, new Layout()],
    [Collection.meta.typeName, new Collection()],
    [FluidContainer.meta.typeName, new FluidContainer()],
    [AppBar.meta.typeName, new AppBar()],
    [PageRef.meta.typeName, new PageRef()],
    [PageNode.meta.typeName, new PageNode()],
    [PropertyString.meta.typeName, new PropertyString()],
    [Product.meta.typeName, new Product()],
    [ProductRef.meta.typeName, new ProductRef()],
    // [ProductPreview.meta.typeName, new ProductPreview()],
    // [ProductGallery.meta.typeName, new ProductGallery()],
    [Image.meta.typeName, new Image()],
    [ImageGallery.meta.typeName, new ImageGallery()],
    [Text.meta.typeName, new Text()],
    [Spacer.meta.typeName, new Spacer()],
    [Button.meta.typeName, new Button()],
    [Location.meta.typeName, new Location()],
    [Video.meta.typeName, new Video()],
    [CustomWidget.meta.typeName, new CustomWidget()]
  ]);

  const item = typeToItemMap.get(typeName);
  if(item==undefined){
    throw new Error("typeToItemMap: typename not found: " + typeName);
  }
  return item;
};