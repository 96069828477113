import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";

export
abstract class BaseRef extends BaseItem{
  refId = "";

  public getStoreObjectItem(): Object{
    return {
      refId: this.refId
    };
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
  }

  public override from(item: BaseItem): void{
    const baseRef = item as BaseRef;
    this.refId = baseRef.refId;
  }

  setRefId(id: string){
    this.refId = id;
  }

  getRefId(){
    return this.refId;
  }

  public contains(item: BaseItem): BaseItem[]{
    return [];
    // throw new Error("BaseRef: contains() is called. No dereferencing implemented.")
  }
}