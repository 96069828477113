
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import { Ref, toRef } from 'vue';
import { PageNode } from '../../PageNode';

export default {
  name: 'PageNode',
  mixins: [MixinProps],
  components:{
    ComponentWrapper,
  },
  setup(props: any){
    const refPageNode = toRef(props, 'item') as Ref<PageNode>;

    return {
      props,
      refPageNode,
    }
  }
}
