
import MixinProps from '@/components/shared/view/MixinProps';
import { PropertyCode } from './PropertyCode';
import { useRefVarsAndCurrentValue } from "@/components/shared/view/RefVarsAndCurrentValue";

export default {
  mixins: [MixinProps],
  name: 'PropertyCode',
  components:{
  },
  setup(props: any){
    const { getRefVarsAndCurrentValue } = useRefVarsAndCurrentValue();
    const [refPropertyCode, refCurrentValue] = getRefVarsAndCurrentValue<PropertyCode>(props);

    return {
      props,
      refPropertyCode,
      refCurrentValue,
    }
  }
}
