
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ContentEditable from '@/components/shared/util/ContentEditable.vue';
import { PropertyString } from './PropertyString';
import { useRefVarsAndCurrentValue } from "@/components/shared/view/RefVarsAndCurrentValue";

export default {
  mixins: [MixinProps],
  name: 'PropertyString',
  components:{
    ComponentContainer,
    ContentEditable
  },
  setup(props: any){
    const { getRefVarsAndCurrentValue } = useRefVarsAndCurrentValue();
    const [refPropertyString, refCurrentValue] = getRefVarsAndCurrentValue<PropertyString>(props);

    return {
      props,
      refPropertyString,
      refCurrentValue,
    }
  }
}
