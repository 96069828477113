import type Context from "../view/Context";
import { BaseItem } from "./BaseItem";

export
abstract class BaseStyle extends BaseItem{
  private styleName = "";

  public useCustomStyle = false;
  public customStyle = "";

  public getSubItems(): BaseItem[]{
    const subItems = [];
    for (const value of Object.values(this)) {
      if (value instanceof BaseItem){
        subItems.push(value);
      }
    }
    return subItems;
  }

  public getStoreObject(): Object{
    const object : any = {
      type: this.getMeta().typeName,
      item: this.getStoreObjectItem(),
    };

    if (this.styleName != undefined && this.styleName.length!=0){
      object.styleName = this.styleName;
    }

    if (this.useCustomStyle==true){
      object.useCustomStyle = this.useCustomStyle;
    }

    if (this.customStyle != undefined && this.customStyle.length!=0){
      object.customStyle = this.customStyle;
    }

    return object;
  }

  public fromStoreObject(object: any){
    this.styleName = object.styleName;

    if (object.useCustomStyle){
      this.useCustomStyle = object.useCustomStyle;
    } else {
      this.useCustomStyle = false;
    }

    if (object.customStyle){
      this.customStyle = object.customStyle;
    } else {
      this.customStyle = "";
    }

    this.fromStoreObjectItem(object.item);
  }

  // abstract getClassNames(): any;
  getClassNames(): any{
    throw new Error("getClassNames not implemented");
  }

  getStyleName(): string{
    return this.styleName;
  }

  setStyleName(styleName: string){
    this.styleName = styleName;
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const baseStyle = item as BaseStyle;
    this.styleName = baseStyle.styleName;
    this.useCustomStyle = baseStyle.useCustomStyle;
    this.customStyle = baseStyle.customStyle;
  }

  getPredefinedStyleObject(context: Context){} // TODO: make pure virtual

  getStyleObject(context: Context){
    const useGlobalStyle = false;
    if (useGlobalStyle){
      return {};
    }

    if (this.useCustomStyle){
      return JSON.parse(this.customStyle);
    } else {
      return this.getPredefinedStyleObject(context);
    }
  }
}