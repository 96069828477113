import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!

  return ($setup.refPage)
    ? (_openBlock(), _createBlock(_component_ComponentWrapper, {
        key: 0,
        item: $setup.refPage,
        context: $setup.refContext
      }, null, 8, ["item", "context"]))
    : _createCommentVNode("", true)
}