
import { Theme } from '@/components/shared/theme/Theme';
import { PropType } from 'vue';
import { fonts, weights } from '../theme/Fonts';



export default {
  name: 'FontSelector',
  props: {
    theme: {
      type: Object as PropType<Theme>,
      required: true,
    },
  },
  components:{
  },
  setup(props: any){
    const refTheme = props.theme as Theme;

    const selectFontHeader = (font: string) => {
      // eslint-disable-next-line no-alert
      refTheme.fontHeader = font;
    }

    const selectFontParagraph = (font: string) => {
      // eslint-disable-next-line no-alert
      refTheme.fontParagraph = font;
    }

    const selectFontButton = (font: string) => {
      // eslint-disable-next-line no-alert
      refTheme.fontButton = font;
    }

    return{
      refTheme,
      selectFontHeader,
      selectFontParagraph,
      selectFontButton,
      fonts,
      weights
    }
  },
}
