import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "refDomContainer",
  class: "Container InheritSize"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!
  const _component_Contained = _resolveComponent("Contained")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createBlock(_component_ComponentContainer, {
    item: $setup.refContainer,
    context: $setup.refContext
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Contained, {
          styleContained: $setup.refStyleContained,
          context: $setup.refContext
        }, {
          body: _withCtx(() => [
            _createVNode(_component_ComponentWrapper, {
              item: $setup.refContainer.content,
              context: $setup.refContext,
              onLayoutInitialized: $setup.onChildLayoutInitialized,
              onLayoutMinHeightChanged: $setup.onLayoutMinHeightChanged
            }, null, 8, ["item", "context", "onLayoutInitialized", "onLayoutMinHeightChanged"])
          ]),
          _: 1
        }, 8, ["styleContained", "context"])
      ], 512)
    ]),
    _: 1
  }, 8, ["item", "context"]))
}