import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createBlock(_component_ComponentContainer, {
    item: $setup.refCustomWidget,
    context: $setup.refContext
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: $setup.refCustomWidget.code.getValueOf($setup.refContext.languageOption)
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["item", "context"]))
}