
import AppEditorDrawer from './AppEditorDrawer.vue';
import AppViewer from '../../components/app/app/view/default/Viewer.vue';
import Context, { DisplayOption, Mode, Selection } from '../../components/shared/view/Context'
import { computed, onMounted, PropType, reactive, Ref, ref, toRef, watch } from 'vue';
import { GlobalZIndex } from '@/shared/GlobalVariables';
import { App } from '@/components/app/app/App';
import {GlobalDomId} from "@/shared/GlobalVariables";
import { LanguageOption } from '@/components/shared/view/LanguageOption';

export default {
  props: {
    app: {
      type: Object as PropType<App>,
      required: true,
    },
  },

  components: {
    AppEditorDrawer,
    AppViewer,
  },

  setup(props: any) {
    const refViewportOffsetTop = ref(0) as Ref<number>; // To put top bar in the correct position
    const refViewportHeight = ref(0) as Ref<number>; // To put top bar in the correct position

    const onViewportChanged = () => {
      refViewportOffsetTop.value = window.visualViewport.offsetTop;
      refViewportHeight.value = window.visualViewport.height;
    };

    onMounted(() => {
      // TODO: create context variable.
      window.visualViewport.addEventListener('scroll', onViewportChanged);
      window.visualViewport.addEventListener('resize', onViewportChanged);
      onViewportChanged(); // init
      ///////////////////////////////
      // Disable double touch to zoom
      // P.S. Pinch to zoom is disabled in index.css
      // Solution 1
      // https://medium.com/building-blocks/code-snippet-4accfa29b75d
      var doubleTouchStartTimestamp = 0;
      document.addEventListener("touchstart", function(event){
        var now = +(new Date());
        if (doubleTouchStartTimestamp + 500 > now){
          event.preventDefault();
        }
        doubleTouchStartTimestamp = now;
      });
      // Solution 2
      // https://stackoverflow.com/questions/59732074/disable-double-tap-zoom-on-safari-ios-13/73334551#73334551
      document.ondblclick = function(e) {
        e.preventDefault();
      }
      ///////////////////////////////
      // Detect landscape mode on mobile device and change scale
      const updateScale = ()=>{
        switch (screen.orientation.type) {
          case "landscape-primary":
          case "landscape-secondary":
            if (window.innerWidth <1000 && navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)){
              // selectorContext.displayOption.scale = 0.5;
              // TODO: fix bug and enable the scale.
              selectorContext.displayOption.scale = 1;
            }
            break;
          case "portrait-secondary":
          case "portrait-primary":
            selectorContext.displayOption.scale = 1;
            break;
          default:
            selectorContext.displayOption.scale = 1;
            console.log("The orientation API isn't supported in this browser :(");
        }
      };
      screen.orientation.onchange = function (){
        updateScale();
      };
      // Init
      updateScale();
      ///////////////////////////////
    });
    //////////////////////////////////////////////////////////////////
    const refDomAppEditorDrawer = ref(null) as Ref<typeof AppEditorDrawer | null>;

    const editorToolbarTextId = GlobalDomId.EditorToolbarText;

    const computedStyleTextBar = computed( () => {
      return {
        position: "fixed",
        width: "100%",
        // TODO: the check refViewportOffsetTop.value == 0 does not always work.
        top: `calc( ${refViewportOffsetTop.value + refViewportHeight.value - 40 + 'px'} - ${refViewportOffsetTop.value == 0 ? 'env(safe-area-inset-bottom)' : 0 + 'px'} )`,
        left: 0,
        zIndex: GlobalZIndex.EditorToolbar
      }
    });

    const onEditorToolbarTextResized = (size: {width: number, height: number})=>{
      if (size.height>0){
        selectorContext.uiState.requestEditType("None");
      }
    };
    //////////////////////////////////////////////////////////////////
    const computedStyleBottomBar = computed( () => {
      return {
        position: "fixed",
        width: "100%",
        height: "40px",
        // top: `calc( ${refViewportOffsetTop.value + refViewportHeight.value - 40 + 'px'} - env(safe-area-inset-bottom) )`,
        bottom: "env(safe-area-inset-bottom)",
        left: 0,
        zIndex: GlobalZIndex.EditorToolbar
      }
    });

    const refApp = toRef(props, 'app') as Ref<App>;

    const enableEditorInit = false;

    const selectorContext = reactive(new Context(new Mode(false, enableEditorInit, enableEditorInit, enableEditorInit), new LanguageOption(), new DisplayOption(1), new Selection(props.app, null, null)));

    watch(()=>selectorContext.selection.pageRef, ()=>{
      selectorContext.selection.item = null;
    });

    const computedStyleViewer = computed( () => {
      const bottomSpacePx = 0; //40;

      // TODO: scale breaks Product view
      const scale = selectorContext.displayOption.scale;
      return {
        position: "absolute",
        top: 0,
        height: `calc( ${100/scale}% - ${bottomSpacePx/scale}px - env(safe-area-inset-bottom) )`,
        left: 0,
        width: 100/scale+'%',
        overflow: "none",

        // Transform creates a separate viewport
        transformOrigin: 'left top',
        transform: `scale(${scale})`,
      };
    });

    return {
      computedStyleTextBar,
      onEditorToolbarTextResized,
      computedStyleBottomBar,
      refDomAppEditorDrawer,
      editorToolbarTextId,
      refApp,
      selectorContext,
      computedStyleViewer,
    }
  }
}
