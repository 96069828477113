import { BaseItem } from "./BaseItem";

export abstract class BaseVar extends BaseItem{
  public value: string = ""; // must be public for v-model as LHS

  public getStoreObject(): Object{
    const object : any = {
      value: this.value
    };
    if (this.styleVariantName.length!=0){
      object.styleVariantName = this.styleVariantName;
    }
    return object;
  }

  public fromStoreObject(object: any){
    if (object!==undefined){
      this.value = object.value;
      if(object.styleVariantName!==undefined){
        this.styleVariantName = object.styleVariantName;
      } else {
        this.styleVariantName = "";
      }
    } else {
      this.value = "";
      this.styleVariantName = "";
    }
  }

  public setValue(value: string): void{
    this.value = value;
  }

  public getValue(): string{
    return this.value;
  }
}