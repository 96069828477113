
// https://stackoverflow.com/questions/67425439/tinymce-vue-integration-selfhosted
import Editor from '@tinymce/tinymce-vue';
import "tinymce/tinymce";
import 'tinymce/models/dom/model';
import "tinymce/themes/silver";
import "tinymce/icons/default";

import "tinymce/skins/ui/oxide/skin.css";
import 'tinymce/skins/ui/mobu/skin.min.css';
// import 'tinymce/skins/ui/mobu/content.inline.min.css';
// import 'tinymce/skins/ui/mobu/content.min.css';
// import 'tinymce/skins/ui/mobu/skin.shadowdom.min.css';

// Import plugins if needed
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
// import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/media';
// import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';

import { Ref, computed, ref, toRef, watch } from 'vue';
import {GlobalDomId} from "@/shared/GlobalVariables"
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { BaseItem } from '../model/BaseItem';
import Context from '../view/Context';

export default {
  mixins: [SerialLayoutInitialization],
  emits: ['textChanged'],
  components: { Editor },
  name: 'TinyMceEditorWidget',
  props: {
    text: {
      type: String,
      required: true,
      default: "",
    },
    ifPlainTextOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    ifEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    context: {
      type: Context,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const refItem = toRef(props, 'item') as Ref<BaseItem>;
    const refContext = toRef(props, 'context') as Ref<Context>;
    const refText = toRef(props, "text") as Ref<string>;

    watch(refText, () =>
      {
        refCurrentValue.value = refText.value;
      }
    )

    const refCurrentValue = ref(refText.value) as Ref<string>;
    watch(refCurrentValue, () =>
      {
        context.emit('textChanged', refCurrentValue.value);
      },
      {immediate: true},
    )

    const refIfPlainTextOnly = toRef(props, "ifPlainTextOnly") as Ref<boolean>;
    const refIfEditing = toRef(props, "ifEditing") as Ref<boolean>;
    const refInline = toRef(props, "inline") as Ref<boolean>;

    const refState = ref('HTML') as Ref<string>; // HTML, EditorLoad, EditorReady

    // Store height (incl. padding) when refState === HTML or EditorReady. Use this height during EditorLoad.
    // This is for preventing flash when the TinyMceEditor is being loaded.
    const refHeight = ref(0) as Ref<number>;

    const { emitLayoutInitialized, emitLayoutMinHeightChanged, refDomContainer } = useSerialLayoutInitialization(refItem.value, context);

    const onResized = (size: {width: number, height: number})=>{
      // console.log('TinyMce: onResized', size.height);
      if (refState.value==='EditorReady' || refState.value==='HTML'){
        refHeight.value = size.height;
        emitLayoutMinHeightChanged(refItem.value, refHeight.value);
      }
    }

    let ifEditingBefore = false; // ifEditing in the previous tick.
    watch(
      () => refIfEditing,
      () => {
        const ifEditingNow = refIfEditing.value;
        if (ifEditingNow == ifEditingBefore)
          return;
        else
          ifEditingBefore = ifEditingNow;

        if (!ifEditingNow){
          refState.value = 'HTML';
        } else {
          refState.value = 'EditorLoad';
        }
      },
      { immediate: true, deep: true }
    );

    watch([refState, refDomContainer], () =>
      {
        // console.log(refState.value)
        if ( refDomContainer.value && (refState.value==='EditorReady' || refState.value==='HTML')){
          refHeight.value = (refDomContainer.value as HTMLElement).clientHeight;
          emitLayoutMinHeightChanged(refItem.value, refHeight.value);
        }
      },
    );

    const computedEditorContainerStyle = computed( () => {
      let style = {};
      if (refState.value==='EditorLoad'){
        style = {height: (refHeight.value - 0 - 0) + 'px', width: 'calc(100% - 0px - 0px)'}; // padding in the style .HtmlContainer
      }
      // console.log('computedEditorContainerStyle: ', style);
      return style;
    });

    const onFocused = () => {
      // Currently disabled so that selection will not change while editing e.g. ContainedText
      // if (props.context.mode.selectItem)
      //   setSelectedItem(props.context, refItem);
    };

    const onBlurred = () => {
      // console.log('Editor got blurred!');
      // setSelectedItem(props.context, null) will cause problems, because blur is also
      // triggered when the user presses buttons to edit the content or style.
    };

    const computedShowEditor = computed( () => {
      if (refIfEditing.value)
        return true;
      else{
        emitLayoutInitialized();
        return false;
      }
    });

    const editorConfig = computed( () => {
      let pluginList = "";
      let toolbarList = "";
      if (refIfPlainTextOnly.value){
        toolbarList = "undo redo";
      } else {
        pluginList =
          "advlist lists autolink link";
        toolbarList = "undo redo textOptions listOptions | selectAll copy paste";
      }
      toolbarList = "undo redo | forecolor backcolor bold italic underline | fontfamily fontsize | h1 h2 h3 h4 h5 h6 | bullist numlist outdent indent | alignleft aligncenter alignright alignjustify | removeformat selectAll copy paste";

      if (refInline.value){
        toolbarList = toolbarList + " finishEditingButton"
      }

      let mobileOptions : any = {};
      // mobileOptions.skin = 'mobu';
      if (refInline.value){
        mobileOptions.skin = 'mobu'; // In classic mode, the skin causes the toolbar to disappear.
      }

      return {
        license_key: 'gpl',
        height: 310, //AppEditorDrawer height - AppEditorDrawer bar height
        inline: refInline.value,
        menubar: false, // for mode classic
        statusbar: false, // for mode classic
        toolbar: toolbarList,

        // mobile: mobileOptions,

        toolbar_groups: {
          textOptions: {
            icon: 'format',
            tooltip: 'Text',
            items: 'fontfamily fontsize | forecolor backcolor bold italic underline | h1 h2 h3 h4 h5 h6'
          },
          listOptions: {
            icon: 'align-justify',
            tooltip: 'List',
            items: 'bullist numlist outdent indent | alignleft aligncenter alignright alignjustify'
          },
        },

        // toolbar_sticky: true,
        toolbar_location: 'bottom', // for mode classic
        fixed_toolbar_container: "#"+GlobalDomId.EditorToolbarText, // for mode inline
        // inline_boundaries: false,
        plugins: pluginList,
        // https://www.tiny.cloud/blog/tinymce-custom-font-family/
        font_family_formats:
        ` Arial=arial,helvetica,sans-serif;
          Helvetica=helvetica;
          Terminal=terminal,monaco;
          Courier New=courier new, courier;
          Open Sans=open sans ms,sans-serif;
          Montserrat=Montserrat, sans-serif;
          Lato=Lato, sans-senrif;
          Fair Display=Playfair Display;
          Poppins=Poppins;`,
        content_style:
        `
          @import url('https://fonts.googleapis.com/css?family=Open+Sans');
          @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        `,
        init_instance_callback: function (editor: any) {
          editor.on('focus', function (e: any) {
            e;
            onFocused();
          });
          editor.on('blur', function (e: any) {
            e;
            onBlurred();
          });
        },
        setup: function (editor: any) {
          editor.on('init', function(args:any) {
            args;
            // console.log('TinyMCE: loaded');
            emitLayoutInitialized();
            refState.value = 'EditorReady';

            // editor.ui.registry.getAll().buttons['selectall'].onAction();
          });
          editor.ui.registry.addIcon('customAiButtonIcon',
            `<svg width="24px" height="24px"  viewBox="0 0 24 24">
              <g id="AI" clip-path="url(#clip_1)">
                <path d="M24 0L24 0L24 24L0 24L0 0L24 0Z" id="AI" fill="#FFFFFF" fill-opacity="0" stroke="none" />
                <g id="AI" transform="translate(3 1)">
                  <g id="AI" fill="#A12DD3">
                    <path d="M3.39418 17.4375L0.428267 17.4375L4.94744 4.34659L8.5142 4.34659L13.027 17.4375L10.0611 17.4375L9.08949 14.4524L4.36577 14.4524L3.39418 17.4375ZM5.06889 12.2919L8.39276 12.2919L6.78196 7.33807L6.67969 7.33807L5.06889 12.2919ZM17.3672 4.34659L17.3672 17.4375L14.5994 17.4375L14.5994 4.34659L17.3672 4.34659Z" />
                  </g>
                </g>
              </g>
            </svg>`
          );
          editor.ui.registry.addButton('customAiButton', {
            icon: 'customAiButtonIcon',
            onAction: function (_:any) {
              _;
              editor.insertContent('<br/><strong>Subscribe to let the AI write for you!</strong>');
            }
          });
          editor.ui.registry.addButton('finishEditingButton', {
            icon: 'checkmark',
            onAction: function (_:any) {
              _;
              refContext.value.selection.item = null;
            }
          });
        }
      };
    });

    return {
      props,
      refCurrentValue,
      refIfPlainTextOnly,
      refDomContainer,
      onResized,
      onFocused,
      onBlurred,
      computedShowEditor,
      computedEditorContainerStyle,
      editorConfig,
    };
  },
}
