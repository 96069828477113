import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import StylePadding from "@/components/shared/style/StylePadding"
import { StyleVariableString } from "@/components/shared/style/StyleVariableString";
import Context from "@/components/shared/view/Context";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Video Style"};

  // static readonly nameObjectPosition: string = "ObjectPosition";

  public padding = new StylePadding;
  // public objectPosition = new StyleVariableString(Style.nameObjectPosition, "50% 50%");

  public getStoreObjectItem(): Object{
    return {
      padding: this.padding.getStoreObject()
    };
  }

  getClassNames(): any{
    // TODO: complete css class names here and in the Viewer
    return {
    }
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }

  getPredefinedStyleObject(context: Context) {
    return {
      ...this.padding.getPredefinedStyleObject(context),
      // objectPosition: this.objectPosition.getValue(),
    };
  }
}