import PaletteSet from "./PaletteSet";

export
const getPalettes = () : PaletteSet[] => {
  const blackAndWhite = new PaletteSet("Black And White");

  const red = new PaletteSet("Red");
  red.light.setColors("#F08F80","rgba(212, 92, 92, 1)","rgba(180, 90, 90, 1)","#000000","#FFFFFF","#FFFFFF");
  red.dark.setColors("#A74D4B","rgba(187, 90, 90, 1)","rgba(180, 90, 90, 1)","#FFFFFF","#FFFFFF","#FFFFFF");

  const blue = new PaletteSet("Blue");
  blue.light.setColors("#797ACE","#000","#A259FF","#000000","#FFFFFF","#FFFFFF");
  blue.dark.setColors("rgba(39, 49, 91, 1)","#FFF","#A259FF","#FFFFFF","#000","#FFFFFF");

  const yellow = new PaletteSet("Yellow");
  yellow.light.setColors("#FFC700", "#000", "#000", "#000", "#FFF", "#FFF");
  yellow.dark.setColors("#DDAC13", "#000", "#000", "#000", "#FFF", "#FFF");

  const oliver = new PaletteSet("Oliver");
  oliver.light.setColors("#757264", "#E1CCBF", "#EEEDEB", "#E1CCBF", "#757264", "#000000");
  oliver.dark.setColors("#757264", "#E1CCBF", "#000000", "#E1CCBF", "#757264", "#EEEDEB");

  const salvia = new PaletteSet("Salvia");
  salvia.light.setColors("#818A7E", "#EEEDEB", "#EEEDEB", "#EEEDEB", "#818A7E", "#000000");
  salvia.dark.setColors("#818A7E", "#EEEDEB", "#000000", "#EEEDEB", "#818A7E", "#EEEDEB");

  return [blackAndWhite, red, blue, yellow, oliver, salvia];
}