
import { BaseDefinition } from '@/components/shared/model/BaseDefinition';
import MixinProps from '@/components/shared/view/MixinProps';
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import { PropertyUrl } from "@/components/shared/property/propertyUrl/PropertyUrl";
import { PropertyCode } from "@/components/shared/property/propertyCode/PropertyCode";
import { Text } from '@/components/elemental/text/Text';
import { Collection } from '@/components/elemental/collection/Collection';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import { BaseStyle } from '@/components/shared/model/BaseStyle';
import { ProductRef } from '@/components/product/productRef/ProductRef';

// only allows editing of variables
export default {
  name: 'PropertyEditor',
  mixins: [MixinProps],
  components: {ComponentWrapper},
  setup(props: any) {
    const isAtomicEdit = (): Boolean => {
      return(
        props.item.getMeta()==PropertyString.meta ||
        props.item.getMeta()==PropertyUrl.meta ||
        props.item.getMeta()==PropertyCode.meta ||
        props.item.getMeta()==Text.meta ||
        props.item.getMeta()==Collection.meta ||
        props.item.getMeta()==ProductRef.meta
      );
    }
    const isDefinition = (): Boolean => {
      return props.item instanceof BaseDefinition;
    };
    const isNotStyle = (): Boolean => {
      return !(props.item instanceof BaseStyle);
    };
    const asDefinition = (): BaseDefinition => {
      return props.item as BaseDefinition;
    };
    return {
      props,
      isAtomicEdit,
      isDefinition,
      isNotStyle,
      asDefinition,
    }
  }
}
