import { BaseItem } from "@/components/shared/model/BaseItem";
import Context from "../view/Context";

export default function useStyle(item: BaseItem, context: Context) {
  const getClassNames = (): any => {
    return item.getStyle(context.selection.app!.theme)!.getClassNames();
  };

  return {
    getClassNames,
  };
}