import type { MetaInfo } from "../model/BaseItem";
import type { BaseStyle } from "../model/BaseStyle";
import type { StyleVariableColor } from "@/components/shared/style/StyleVariableColor";
import PaletteSet, { Palette, PaletteColor } from "./PaletteSet";
import type  ItemStyleSet from "./ItemStyleSet";
import { Predefined, ThemeParam } from "./Predefined";

// Theme
// |- Theme active
// |- Palette
// |- Font header
// |- Font paragraph

export class Theme{
  paletteNameActive = "Light";

  styleSetActive = "Apple";
  // Theme - Variant - Value
  private paletteSet = Predefined.paletteSets[0].clone();

  fontHeader = "Helvetica";
  fontParagraph = "Helvetica";
  fontButton = "Helvetica";

  fontHeaderWeight = "Bold";
  fontParagraphWeight = "Normal";
  fontButtonWeight = "Normal";

  public getStoreObject(): Object{
    return {
      styleSetActive: this.styleSetActive,
      paletteSet: this.paletteSet.getStoreObject(),

      fontHeader: this.fontHeader,
      fontParagraph: this.fontParagraph,
      fontButton: this.fontButton,

      fontHeaderWeight: this.fontHeaderWeight,
      fontParagraphWeight: this.fontParagraphWeight,
      fontButtonWeight: this.fontButtonWeight
    }
  }

  public fromStoreObject(object: any){
    this.styleSetActive = object.styleSetActive;
    this.paletteSet.fromStoreObject(object.paletteSet);

    this.fontHeader = object.fontHeader;
    this.fontParagraph = object.fontParagraph;
    this.fontButton = object.fontButton;

    this.fontHeaderWeight = object.fontHeaderWeight;
    this.fontParagraphWeight = object.fontParagraphWeight;
    this.fontButtonWeight = object.fontButtonWeight;
  }

  constructor(){
  }

  initWith(themeParam: ThemeParam){
    this.styleSetActive = themeParam.styleSet;

    this.setPaletteSetWithName(themeParam.paletteSet);

    this.fontHeader = themeParam.fontHeader;
    this.fontParagraph = themeParam.fontParagraph;
    this.fontButton = themeParam.fontButton;

    this.fontHeaderWeight = themeParam.fontHeaderWeight;
    this.fontParagraphWeight = themeParam.fontParagraphWeight;
    this.fontButtonWeight = themeParam.fontButtonWeight;
  }

  getThemeMetas(): ThemeParam[]{
    return Predefined.themeParams;
  }

  getItemStyleSets(): ItemStyleSet[]{
    return Predefined.itemStyleSets;
  }

  getItemStyleNames(meta: MetaInfo): string[]{
    return Predefined.getItemStyleNames(this.styleSetActive, meta);
  }

  getStyle(styleVariantName: string, meta: MetaInfo): BaseStyle | null{
    const itemStyleSet = Predefined.getItemStyleSet(this.styleSetActive);
    if (itemStyleSet == undefined)
      return null;
    const style = itemStyleSet.getItemStyle(meta, styleVariantName);
    if (style == undefined)
      return null;
    return style;
  }

  getPaletteColor(colorName: PaletteColor): StyleVariableColor{
    const palette = this.getPalette();

    const color = palette.get(colorName);
    if (color == undefined){
      throw new Error("getPaletteColor: color not found.");
    }
    return color;
  }

  getPalette(): Palette{
    const palette = this.paletteSet.get(this.paletteNameActive);
    if (palette == undefined){
      throw new Error("getVariants: palette not found.");
    }
    return palette;
  }

  getPaletteSets(): PaletteSet[]{
    return Predefined.paletteSets;
  }

  getPaletteSet(name: string): PaletteSet{
    const paletteSets = this.getPaletteSets();
    for (let i=0; i<paletteSets.length; ++i) {
      if (paletteSets[i].name === name){
        return paletteSets[i];
      }
    }
    throw new Error('getPaletteSet: name not found: ' + name);
  }

  setPaletteSet(palette: PaletteSet){
    this.paletteSet = palette.clone();
  }

  setPaletteSetWithName(paletteName: String){
    for (let i=0; i<Predefined.paletteSets.length; ++i){
      if (Predefined.paletteSets[i].name===paletteName){
        this.paletteSet = Predefined.paletteSets[i].clone();
        return;
      }
    }
    throw new Error("setPaletteSetWithName: palette name not found.");
  }

  public getFonts(): string[]{
    return Predefined.fonts;
  }

  public from(theme: Theme): void{
    this.styleSetActive = theme.styleSetActive;
    this.paletteSet = theme.paletteSet; //TODO: CORRECT?
    this.paletteNameActive = theme.paletteNameActive;
    this.fontHeader = theme.fontHeader;
    this.fontParagraph = theme.fontParagraph;
    this.fontButton = theme.fontButton;
  }
}