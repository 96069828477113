
import { Collection } from '../../Collection';
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue'
import { ref, toRef, Ref } from 'vue';
import { BaseItem } from '@/components/shared/model/BaseItem';
import { useSerialLayoutInitialization } from '@/components/shared/view/SerialLayoutInitialization';

export default {
  name: 'CollectionViewer',
  mixins: [MixinProps],
  components: {ComponentWrapper},
  setup(props: any, context: any){
    const refCollection = toRef(props, 'item') as Ref<Collection>;

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refCollection.value, context);

    const refCurrentRange = ref(1);

    const onChildLayoutInitialized = (child: BaseItem)=>{
      // console.log("Collection: onChildLayoutInitialized: ", child.getMeta().typeName);
      const idx = refCollection.value.elements.indexOf(child);
      if (idx<0)
        throw new Error("CollectionViewer:default:onChildLayoutInitialized: child not found");
      if (refCurrentRange.value==refCollection.value.elements.length){
        // All elements have finished
        emitLayoutInitialized();
      } else {
        refCurrentRange.value = idx+2;
      }
    };

    return{
      props,
      refCurrentRange,
      onChildLayoutInitialized,
    }
  },
}
