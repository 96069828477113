import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-334be04e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "GlobalInputRow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentEditable = _resolveComponent("ContentEditable")!
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return ($setup.props.context.mode.editContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ContentEditable, {
          value: $setup.refCurrentValue,
          style: {"font-size":"var(--GlobaInputFontSize)"},
          multiline: $setup.refPropertyString.multiLine,
          onValueChanged: _cache[0] || (_cache[0] = (value)=>{$setup.refCurrentValue = value})
        }, null, 8, ["value", "multiline"])
      ]))
    : ($setup.props.context.mode.editTextInline)
      ? (_openBlock(), _createBlock(_component_ComponentContainer, {
          key: 1,
          item: $setup.props.item,
          context: $setup.props.context
        }, {
          body: _withCtx(() => [
            _createVNode(_component_ContentEditable, {
              value: $setup.refCurrentValue,
              multiline: $setup.refPropertyString.multiLine,
              style: {"display":"inline-block","min-width":"50px"},
              onValueChanged: _cache[1] || (_cache[1] = (value)=>{$setup.refCurrentValue = value})
            }, null, 8, ["value", "multiline"])
          ]),
          _: 1
        }, 8, ["item", "context"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createTextVNode(_toDisplayString($setup.refCurrentValue), 1)
        ], 64))
}