import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.computedIfShowButton)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        style: _normalizeStyle($setup.computedButtonStyle),
        onClick: _cache[0] || (_cache[0] = (event)=>{$setup.toggleSelection(event)})
      }, null, 4))
    : _createCommentVNode("", true)
}