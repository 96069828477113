
import Modal from "@/components/shared/util/Modal.vue";
import { Ref, ref } from 'vue';
import ImageGallery from "./ImageGallery.vue";

export default {
  name: 'ImagePicker',
  props:{
    onImagePicked: {
      type: Function,
      required: false,
      default: undefined,
    },
  },
  components: {
    Modal,
    ImageGallery
  },
  setup(props: any){
    const refShowModal = ref(false) as Ref<boolean>;

    const imagePicked = (url: String) => {
      props.onImagePicked(url);
      refShowModal.value = false;
    };
    return{
      refShowModal,
      imagePicked,
    }
  },
}
