import { BaseVars } from "@/components/shared/model/BaseVars";
import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";

export class PropertyCode extends BaseVars{
  static readonly meta: MetaInfo = {typeName: "PropertyCode", friendlyName: "Code"};

  public getStoreObjectItem(): Object{ return {}; }

  public fromStoreObjectItem(item: any){item;}

  constructor(itemName = "") {
    super();
    this.setItemName(itemName);
  }

  public getMeta(): MetaInfo{
    return PropertyCode.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new PropertyCode();
  }
}