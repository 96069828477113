import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Grid Style"};

  public getStoreObjectItem(): Object{ return {}; }

  constructor (){
    super();
    this.setItemName("Style");
    this.setStyleName("grid");
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }
}