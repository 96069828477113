
import { PropType, Ref, ref, toRef } from 'vue';
import { ItemInfo } from "@/components/shared/registry/Items";
import Context from "../view/Context";

export default {
  name: 'AddItemButton',
  props:{
    type: {
      type: String,
      required: true,
      default: undefined,
    },
    onSelected: {
      type: Function,
      required: false,
      default: undefined,
    },
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
  },
  setup(props: any){
    const refType = toRef(props, "type") as Ref<String>;
    const refShowModal = ref(false) as Ref<boolean>;
    const refContext = props.context as Context;

    const itemAdded = (itemInfo: ItemInfo) => {
      props.onSelected(itemInfo);
      refShowModal.value = false;
    };
    return{
      refType,
      refShowModal,
      refContext,
      itemAdded,
    }
  },
}
