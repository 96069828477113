import { StyleVariableColor } from "@/components/shared/style/StyleVariableColor";

// TODO: Use enum
// enum PaletteSetType{
//   Light = "Light",
//   Dark = "Dark"
// }

export enum PaletteColor{
  B1 = "B1",
  B2 = "B2",
  B3 = "B3",
  F1 = "F1",
  F2 = "F2",
  F3 = "F3"
}

export class Palette{
  b1 = new StyleVariableColor(PaletteColor.B1, "#FFFFFF");
  b2 = new StyleVariableColor(PaletteColor.B2, "#FFFFFF");
  b3 = new StyleVariableColor(PaletteColor.B3, "#FFFFFF");
  f1 = new StyleVariableColor(PaletteColor.F1, "#000000");
  f2 = new StyleVariableColor(PaletteColor.F2, "#000000");
  f3 = new StyleVariableColor(PaletteColor.F3, "#000000");

  public getStoreObject(): Object{
    return {
      b1: this.b1.getStoreObject(),
      b2: this.b2.getStoreObject(),
      b3: this.b3.getStoreObject(),
      f1: this.f1.getStoreObject(),
      f2: this.f2.getStoreObject(),
      f3: this.f3.getStoreObject(),
    }
  }

  public fromStoreObject(object: any){
    this.b1.fromStoreObject(object.b1);
    this.b2.fromStoreObject(object.b2);
    this.b3.fromStoreObject(object.b3);
    this.f1.fromStoreObject(object.f1);
    this.f2.fromStoreObject(object.f2);
    this.f3.fromStoreObject(object.f3);
  }

  public getColors(): StyleVariableColor[]{
    return [this.b1, this.b2, this.b3, this.f1, this.f2, this.f3];
  }

  public get(colorName: PaletteColor): StyleVariableColor|undefined{
    switch(colorName){
      case PaletteColor.B1:
        return this.b1;
      case PaletteColor.B2:
        return this.b2;
      case PaletteColor.B3:
        return this.b3;
      case PaletteColor.F1:
        return this.f1;
      case PaletteColor.F2:
        return this.f2;
      case PaletteColor.F3:
        return this.f3;
    }
  }

  public setColors(b1: string, b2: string, b3: string, f1: string, f2: string, f3: string){
    this.b1.setValue(b1);
    this.b2.setValue(b2);
    this.b3.setValue(b3);
    this.f1.setValue(f1);
    this.f2.setValue(f2);
    this.f3.setValue(f3);
  }

  constructor(b1: string, b2: string, b3: string, f1: string, f2: string, f3: string){
    this.setColors(b1, b2, b3, f1, f2, f3);
  }

  public clone(): Palette{
    const newPalette = new Palette("","","","","","");
    newPalette.b1.from(this.b1);
    newPalette.b2.from(this.b2);
    newPalette.b3.from(this.b3);
    newPalette.f1.from(this.f1);
    newPalette.f2.from(this.f2);
    newPalette.f3.from(this.f3);
    return newPalette;
  }
}

export default class PaletteSet{
  name = "";
  // <Light/Dark, <B1-F3, Color>>
  light = new Palette("#FFF","#000","#333","#000","#FFF","#FFF");
  dark =  new Palette("#000","#FFF","#333","#FFF","#000","#FFF");

  public getStoreObject(): Object{
    return {
      light: this.light.getStoreObject(),
      dark: this.dark.getStoreObject()
    }
  }

  public fromStoreObject(object: any){
    this.light.fromStoreObject(object.light);
    this.dark.fromStoreObject(object.dark);
  }

  public get(setName: string): Palette|undefined{
    switch(setName){
      case "Light":
        return this.light;
      case "Dark":
        return this.dark;
    }
  }

  public clone(): PaletteSet{
    const newPaletteSet = new PaletteSet(this.name);
    newPaletteSet.light = this.light.clone();
    newPaletteSet.dark = this.dark.clone();

    return newPaletteSet;
  }

  constructor(name: string){
    this.name = name;
  }
}