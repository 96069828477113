import { Button } from "@/components/elemental/button/Button";
import { BaseItem } from "@/components/shared/model/BaseItem";
import { Collection } from '../../Collection';

export default function useCollection(item: BaseItem) {
  const getCollection = (): Collection => {
    return item as Collection
  };
  const tryCreateAndInsertNewItemAt = (idx: number) => {
    getCollection().addNewElementAt(new Button("1"), idx);
  };
  const addNewItemAt = (item: BaseItem, index: number) => {
    getCollection().addNewElementAt(item, index);
  }
  return {
    getCollection,
    tryCreateAndInsertNewItemAt,
    addNewItemAt,
  };
}