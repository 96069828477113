import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fdda3f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ondragstart: "return false;",
  class: "ComponentContainer InheritSize Unselectable"
}
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: $setup.refItem.ref,
      style: {"position":"absolute","bottom":"0","top":"-50px"}
    }, null, 8, _hoisted_2),
    _renderSlot(_ctx.$slots, "body", {}, undefined, true),
    ($setup.ifHighlight())
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "HighlightLine ColorControlElement",
          style: _normalizeStyle([{"top":"-1px","left":"-1px","bottom":"-1px","width":"2px"}, {zIndex: $setup.zIndexEditorSelectionHighlight}])
        }, null, 4))
      : _createCommentVNode("", true),
    ($setup.ifHighlight())
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "HighlightLine ColorControlElement",
          style: _normalizeStyle([{"top":"-1px","right":"-1px","bottom":"-1px","width":"2px"}, {zIndex: $setup.zIndexEditorSelectionHighlight}])
        }, null, 4))
      : _createCommentVNode("", true),
    ($setup.ifHighlight())
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "HighlightLine ColorControlElement",
          style: _normalizeStyle([{"right":"-1px","left":"-1px","bottom":"-1px","height":"2px"}, {zIndex: $setup.zIndexEditorSelectionHighlight}])
        }, null, 4))
      : _createCommentVNode("", true),
    ($setup.ifHighlight())
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "HighlightLine ColorControlElement",
          style: _normalizeStyle([{"top":"-1px","left":"-1px","right":"-1px","height":"2px"}, {zIndex: $setup.zIndexEditorSelectionHighlight}])
        }, null, 4))
      : _createCommentVNode("", true)
  ]))
}