import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.refCurrentRange, (n, idx) => {
    return (_openBlock(), _createBlock(_component_ComponentWrapper, {
      key: _ctx.refCollection.elements[idx].ref,
      item: _ctx.refCollection.elements[idx],
      context: $setup.props.context,
      onLayoutInitialized: $setup.onChildLayoutInitialized
    }, null, 8, ["item", "context", "onLayoutInitialized"]))
  }), 128))
}