import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";

export default
class StyleNone extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "StyleNone", friendlyName: "Style None"};

  public getStoreObjectItem(): Object{ return {}; }
  public fromStoreObjectItem(item: any){}

  constructor (){
    super();
    this.setItemName("StyleNone");
    this.setStyleName("none");
  }

  public override from(item: BaseItem): void{
    super.from(item);
  }

  getMeta(): MetaInfo{
    return StyleNone.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new StyleNone();
  }
}