export enum Language{
  default = "default", // used for creating elements and blocks, cannot be selected by user.
  en = "en",
  zh = "zh",
  de = "de",
  jp = "jp",
}

export
const allLanguages = [Language.en, Language.zh, Language.de, Language.jp];

export class LanguageOption{
  default = Language.en; // what language is the default language.
  current = Language.en; // what language is selected.

  public from(languageOption: LanguageOption): void{
    this.default = languageOption.default;
    this.current = languageOption.current;
  }

  constructor(
    defaultLanguage: Language = Language.en,
    currentLanguage: Language = Language.default
  ){
    this.default = defaultLanguage;
    this.current = currentLanguage;
  }

  public getDefaultLanguage(): Language{
    return this.default;
    // const first = this.supported.at(0);
    // if (first === undefined){
    //   return Language.default;
    // }
    // return first;
  }
}