import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b01521a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "GridArea" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentWrapper = _resolveComponent("ComponentWrapper")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.refCollection.elements, (element) => {
      return (_openBlock(), _createBlock(_component_ComponentWrapper, {
        key: element.ref,
        item: element,
        context: $setup.props.context
      }, null, 8, ["item", "context"]))
    }), 128))
  ]))
}