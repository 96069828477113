import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentContainer = _resolveComponent("ComponentContainer")!

  return (_openBlock(), _createBlock(_component_ComponentContainer, {
    item: $setup.refSpacer,
    context: $setup.props.context
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", {
        class: "InheritSize",
        style: _normalizeStyle({backgroundColor: $setup.computedIfEditing ? 'rgb(59,255,197,0.25)' : 'transparent'})
      }, null, 4)
    ]),
    _: 1
  }, 8, ["item", "context"]))
}