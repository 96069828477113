import { BaseRef } from "@/components/shared/model/BaseRef";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class PageRef extends BaseRef{
  static readonly meta: MetaInfo = {typeName: "PageRef", friendlyName: "Page Ref"};
  refId = "";

  public getStoreObjectItem(): Object{
    return {
      refId: this.refId
    };
  }

  public fromStoreObjectItem(item: any){
    this.refId = item.refId;
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const pageRef = item as PageRef;
    this.refId = pageRef.refId;
  }

  getMeta(): MetaInfo{
    return PageRef.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new PageRef();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}