
import { Ref, toRef } from 'vue';
// import { onMounted, ref } from 'vue';

export default {
  name: "Modal",
  props:{
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    bottom: {
      type: String,
      required: false,
      default: "0px"
    },
    height: {
      type: String,
      required: true,
    },
    backdrop: {
      type: Boolean,
      required: false,
      default: true,
    },
    dismissed: {
      type: Function,
      required: true,
    },
  },
  setup(props: any){
    const refShow = toRef(props, "show") as Ref<boolean>;
    const refBottom = toRef(props, "bottom") as Ref<string>;
    const refHeight = toRef(props, "height") as Ref<string>;
    const refBackdrop = toRef(props, "backdrop") as Ref<boolean>;

    const dismiss = () => {
      props.dismissed();
      // (document.querySelector("body") as HTMLBodyElement).classList.remove("overflow-hidden");
    };

    return {
      refShow,
      refBottom,
      refHeight,
      refBackdrop,
      dismiss,
    };
  }
};
