import { Language, LanguageOption } from "../view/LanguageOption";
import { BaseItem } from "./BaseItem";

export abstract class BaseVars extends BaseItem{
  values = new Map<string, string>([]);

  public getStoreObject(): Object{
    return {
      ...super.getStoreObject(),
      item: {
        values: Object.fromEntries(this.values),
        ...this.getStoreObjectItem()
      },
    };
  }

  public fromStoreObject(object: any){
    if (object===undefined) return;
    super.fromStoreObject(object);
    const item = object.item;
    this.values = new Map(Object.entries(item.values));
  }

  constructor (){
    super();
  }

  public getValueOf(languageOption: LanguageOption): string {
    const value = this.values.get(languageOption.current);
    if (value !== undefined) return value;

    const defaultValue = this.values.get(Language.default);
    if (defaultValue !== undefined) return defaultValue;

    return "";
  }

  public setValueOf(language: Language, value: string) {
    return this.values.set(language, value);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    this.values = new Map((item as BaseVars).values);
  }
}