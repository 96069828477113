import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import Context from "@/components/shared/view/Context"
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import BaseViewFactory from "@/components/shared/view/BaseViewFactory";
import { defineAsyncComponent } from "vue";
import { Image } from "../Image";


export default
class ViewFactory extends BaseViewFactory{
  getItemMetaOfView(): MetaInfo {
    return Image.meta;
  }

  getNameOfRegisteredStyles(): string[] {
    return [
      "none",
      "default",
    ];
  }

  async getStyleByName(styleName: string): Promise<BaseStyle>{
    const Style = await import("./"+styleName+"/Style");
    return new Style.default;
  }

  getView(styleName: string, context: Context): any{
    const viewFile = this.getViewFileNameWithPath(styleName, context);
    return defineAsyncComponent({
      // Prefix ./ must be defined explicitely
      loader: () => import('/'+viewFile),
    })
  }
}