import { BaseRef } from "@/components/shared/model/BaseRef";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class ProductRef extends BaseRef{
  static readonly meta: MetaInfo = {typeName: "ProductRef", friendlyName: "Product"};

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);
  }

  public override from(item: BaseItem): void{
    super.from(item);
  }

  getMeta(): MetaInfo{
    return ProductRef.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new ProductRef();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}