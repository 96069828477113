
import { BaseDefinition } from '@/components/shared/model/BaseDefinition';
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import { Ref, computed, toRef } from 'vue';
import { Container } from '@/components/elemental/container/Container';

export default {
  mixins: [MixinProps],
  name: 'GenericDefinitionEditor',
  components: {ComponentWrapper},
  setup(props: any) {
    const refDefinition = toRef(props, 'item') as Ref<BaseDefinition>;

    const computedContainer = computed( () : Container | null => {
      if (refDefinition.value.getMeta().typeName==="Container")
        return refDefinition.value as Container;
      return null;
    });

    return {
      props,
      refDefinition,
      computedContainer
    }
  }
}
