
import MixinProps from '@/components/shared/view/MixinProps';
import { ref, Ref, toRef } from 'vue';
import Context from '@/components/shared/view/Context';
import { ImageGallery } from '../../ImageGallery';
import { Image } from '@/components/elemental/image/Image';
import PropertyEditor from "@/components/shared/util/PropertyEditor.vue";
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import { VueDraggable, SortableEvent } from 'vue-draggable-plus';

export default {
  mixins: [MixinProps],
  name: 'ButtonEditor',
  components: {PropertyEditor, ComponentWrapper, VueDraggable},
  setup(props: any){
    const refImageGallery = toRef(props, 'item') as Ref<ImageGallery>;
    const refContext = toRef(props, 'context') as Ref<Context>;
    const refShowImageSelector = ref(true);
    const refImageEditing = ref(null) as Ref<null|Image>;
    const scrollToImage = (image: Image)=>{
      setTimeout(()=>{
        const imageElement = window.document.getElementById(image.ref);
        if (imageElement){
          window.document.getElementById(image.ref)!.scrollIntoView();
        }
      }, 0);
    };

    const onChoose = (event: SortableEvent)=>{
      event.item.classList.add("Dragged");
    };

    const onUnchoose = (event: SortableEvent)=>{
      event.item.classList.remove("Dragged");
    };

    const addImage = (index: number)=>{
      const image = new Image();
      refImageGallery.value.images.splice(index, 0, image);
      refImageEditing.value = image;
      refShowImageSelector.value = false;
    };

    const deleteImage = (image: Image)=>{
      const index = refImageGallery.value.images.indexOf(image);
      if (index >= 0) {
        refImageGallery.value.images.splice(index, 1);
        if (image === refImageEditing.value){
          refImageEditing.value = null;
        }
        refShowImageSelector.value = true;
        }
    };

    return {
      refImageGallery,
      refContext,
      refImageEditing,
      refShowImageSelector,
      scrollToImage,

      onChoose,
      onUnchoose,

      addImage,
      deleteImage
    }
  }
}
