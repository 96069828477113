
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { Image } from '../../Image';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import { onMounted } from 'vue';

// variable
export default {
  name: 'ImageNoneViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
  },
  setup(props: any, context: any){
    const { getRefItem, getRefContext } = useAddReactivity();
    const refImage = getRefItem<Image>(props);
    const refContext = getRefContext(props);

    const { emitLayoutInitialized } = useSerialLayoutInitialization(refImage.value, context);

    onMounted(()=>{
      emitLayoutInitialized();
    });

    return {
      refImage,
      refContext,
      emitLayoutInitialized,
    };
  }
}
