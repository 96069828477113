import { BaseStyle } from "../../model/BaseStyle";

export
abstract class BaseItemPreset{
  map = new Map<string, BaseStyle>();

  get(name: string): BaseStyle{
    const item = this.map.get(name);
    if (item == undefined){
      throw new Error("ItemPreset.get(): cannot find: " + name);
    }
    return item.clone() as BaseStyle;
  }
}