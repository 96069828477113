import { createApp } from 'vue';
import App from './App.vue';
import './index.scss';
// import { createApp } from 'vue/dist/vue.esm-bundler';

import { createMetaManager } from 'vue-meta';
const metaManager = createMetaManager();

import router from './Router';

const VueResizeObserver = require("vue-resize-observer");

createApp(App).use(metaManager).use(VueResizeObserver).use(router).mount('#app');