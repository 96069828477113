
import MixinProps from '@/components/shared/view/MixinProps';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import { Ref, onMounted, ref } from 'vue';
import { PageRef } from '../../PageRef';
import { BaseItem } from '@/components/shared/model/BaseItem';
import { Page } from '@/components/page/page/Page';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';
import { useServerDelegate } from '@/shared/UseServerDelegate';
import { useRoute } from 'vue-router';

export default {
  name: 'PageRef',
  mixins: [MixinProps],
  components:{
    ComponentWrapper,
  },
  setup(props: any){
    const { getRefItem, getRefContext } = useAddReactivity();
    const refPageRef = getRefItem<PageRef>(props);
    const refContext = getRefContext(props);

    const refPage = ref(null) as Ref<BaseItem|null>;

    onMounted(async ()=>{
      // TODO: obsolete: done in App View.vue
      const {getPage} = useServerDelegate();
      getPage(
        useRoute().params.userId as string,
        refPageRef.value.refId,
        (value)=>{
          console.log("Page refId = ", refPageRef.value.refId);
          const page = new Page();

          const startTime = performance.now();
          try {
            page.fromStoreObject(value);
          } catch (e: unknown){
            console.log(refPageRef.value.refId, e);
          }
          const endTime = performance.now();
          const duration = endTime - startTime;
          console.log(`page.fromStoreObject took ${duration} milliseconds.`);

          refPage.value = page;
        },
        (error)=>{
          console.error('Error:', error);
        }
      );
    });

    return {
      refPage,
      refContext
    }
  }
}
