
import MixinProps from '@/components/shared/view/MixinProps';
import { Ref, toRef } from 'vue';
import Context from '@/components/shared/view/Context';
import PropertyEditor from "@/components/shared/util/PropertyEditor.vue";
import { Button } from '../../Button';
import Model from '@/shared/Model';

export default {
  mixins: [MixinProps],
  name: 'ButtonEditor',
  components: {PropertyEditor},
  setup(props: any){
    const refButton = toRef(props, 'item') as Ref<Button>;
    const refContext = toRef(props, 'context') as Ref<Context>;

    return {
      refButton,
      refContext,
      Model
    }
  }
}
