
import { Ref, toRef } from 'vue';
import ComponentContainer from '@/components/shared/util/ComponentContainer.vue';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue';
import Contained from '@/components/shared/util/Contained.vue';
import MixinProps from '@/components/shared/view/MixinProps';
import SerialLayoutInitialization, {useSerialLayoutInitialization} from '@/components/shared/view/SerialLayoutInitialization';
import { BaseItem } from '@/components/shared/model/BaseItem';
import StyleContainer from '../default/Style';
import { Container } from '../../Container';
import StyleContained from '@/components/shared/style/StyleContained';
import { useAddReactivity } from '@/components/shared/view/AddReactivity';

// variable
export default {
  name: 'ContainerDefaultViewer',
  mixins: [MixinProps, SerialLayoutInitialization],
  components:{
    ComponentContainer,
    ComponentWrapper,
    Contained,
  },
  setup(props: any, context: any){
    const { getRefItem, getRefContext, getRefStyle } = useAddReactivity();
    const refContainer = getRefItem<Container>(props);
    const refContext = getRefContext(props);
    const refStyleContainer = getRefStyle<StyleContainer>(props);
    const refStyleContained = toRef(refStyleContainer.value, 'contained') as Ref<StyleContained>;

    const { emitLayoutInitialized, emitLayoutMinHeightChanged, refDomContainer } = useSerialLayoutInitialization(refContainer.value, context);

    const onChildLayoutInitialized = ()=>{
      emitLayoutInitialized();
    }

    const onLayoutMinHeightChanged = (item: BaseItem, newHeight: number)=>{
      // Add possible padding height to the content height
      const containerStyle = window.getComputedStyle(refDomContainer.value! as HTMLElement);
      const paddingTop = containerStyle.getPropertyValue('padding-top');
      const paddingBottom = containerStyle.getPropertyValue('padding-bottom');
      const paddingTopPx = Number(paddingTop.substring(0, paddingTop.length-2));
      const paddingBottomPx = Number(paddingBottom.substring(0, paddingBottom.length-2));
      const sumHeight = newHeight + paddingTopPx + paddingBottomPx;

      emitLayoutMinHeightChanged(item, sumHeight);
    }

    return {
      refContainer,
      refContext,
      refStyleContained,
      onChildLayoutInitialized,
      onLayoutMinHeightChanged,
      emitLayoutMinHeightChanged,
      refDomContainer
    };
  }
}
