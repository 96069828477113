import { BaseItem } from "../model/BaseItem";

export
const scrollToItem = async (item: BaseItem)=>{
  const timer = (ms: number) => new Promise(res => setTimeout(res, ms))
  for (let i = 0; i < 50; ++i) {
    const itemHtmlElement = document.getElementById(item.ref);
    if (itemHtmlElement != null){
      itemHtmlElement.scrollIntoView({behavior: 'smooth', block: 'start'});
      break;
    }
    await timer(100); // then the created Promise can be awaited
  }
};