
import { Theme } from '@/components/shared/theme/Theme';
import { PropType, ref } from 'vue';
import ThemeSelector from './ThemeSelector.vue';
import FontSelector from './FontSelector.vue';
import PaletteSelector from './PaletteSelector.vue';
import Context from '@/components/shared/view/Context';
import { PaletteColor } from "@/components/shared/theme/PaletteSet";


export default {
  name: 'ThemeEditor',
  props: {
    theme: {
      type: Object as PropType<Theme>,
      required: true,
    },
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
  },
  components:{
    ThemeSelector,
    FontSelector,
    PaletteSelector
  },
  setup(props: any){
    const refTheme = props.theme as Theme;
    const refContext = props.context as Context;

    const refEditType = ref("Theme");

    return{
      refTheme,
      refContext,
      refEditType,

      PaletteColor,
    }
  },
}
