import { BaseDefinition} from "../../shared/model/BaseDefinition";
import { BaseItem, MetaInfo } from "../../shared/model/BaseItem";
import { PropertyString } from "@/components/shared/property/propertyString/PropertyString";
import Style from "./view/default/Style";
import { Language } from '@/components/shared/view/LanguageOption';
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import ViewFactory from "./view/ViewFactory";

export
class Location extends BaseDefinition{
  static readonly meta: MetaInfo = {typeName: "Location", friendlyName: "Location"};

  // static readonly nameLong: string = "Long";
  // static readonly nameLat: string = "Lat";
  static readonly nameAddress: string = "Address";

  // long = new PropertyString(Location.nameLong);
  // lat = new PropertyString(Location.nameLat);
  address = new PropertyString(Location.nameAddress);

  public getStoreObjectItem(): Object{
    return {
      // long: this.long.getStoreObject(),
      // lat: this.lat.getStoreObject(),
      address: this.address.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    // this.long.fromStoreObject(item.long);
    // this.lat.fromStoreObject(item.lat);
    this.address.fromStoreObject(item.address);
  }

  constructor (itemName = ""){
    super();
    this.setItemName(itemName);

    // this.lat.setValueOf(Language.default, "40.689247");
    // this.long.setValueOf(Language.default, "-74.044502");
    this.address.setValueOf(Language.default, "Arcaden");

    this.setStyle(new Style);
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const location = item as Location;
    // this.long.from(location.long);
    // this.lat.from(location.lat);
    this.address.from(location.address);
  }

  getMeta(): MetaInfo{
    return Location.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Location();
  }

  public async createStyle(name: string): Promise<BaseStyle|null>{ return (new ViewFactory()).getStyleByName(name); }
}