
import MixinProps from '@/components/shared/view/MixinProps';
import StylePadding from '../StylePadding';
import { Ref, ref, toRef } from 'vue';
import Context from '../../view/Context';
import ComponentWrapper from '@/components/shared/util/ComponentWrapper.vue'

export default {
  mixins: [MixinProps],
  name: 'EditorStylePadding',
  components: {ComponentWrapper},
  setup(props: any){
    const refStylePadding = toRef(props, 'item') as Ref<StylePadding>;
    const refContext = toRef(props, 'context') as Ref<Context>;

    const refCollapsed = ref(true);
    return {
      refStylePadding,
      refContext,
      refCollapsed
    }
  }
}
