import { BaseItem } from "@/components/shared/model/BaseItem";
import { PropType } from "vue";
import Context from "@/components/shared/view/Context"
import {defineComponent} from "vue"

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<BaseItem>,
      required: true,
    },
    context: {
      type: Object as PropType<Context>,
      required: true,
    },
    z: {
      type: Number,
      required: false,
      default: 0,
    },
  },
});