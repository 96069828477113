import { BaseItem, MetaInfo } from "@/components/shared/model/BaseItem";
import { BaseStyle } from "@/components/shared/model/BaseStyle";
import StyleFont from "@/components/shared/style/StyleFont"
import StyleContainer from "@/components/shared/style/StyleContained"
import { EnumPair } from "@/components/shared/style/StyleVariableString";
import Context from "@/components/shared/view/Context";

export default
class Style extends BaseStyle{
  static readonly meta: MetaInfo = {typeName: "Style", friendlyName: "Button Style"};

  static readonly nameMaxWidth: string = "Max Width";

  public font = new StyleFont;
  public contained = new StyleContainer;

  public getStoreObjectItem(): Object{
    return {
      font: this.font.getStoreObject(),
      contained: this.contained.getStoreObject()
    };
  }

  public fromStoreObjectItem(item: any){
    this.font.fromStoreObject(item.font);
    this.contained.fromStoreObject(item.contained);
  }

  getClassNames(): any{
    return {
      container: "ButtonContainer",
      button: "Button",
    }
  }

  constructor(){
    super();
    this.setItemName("Style");
    this.setStyleName("default");

    this.font.size.enums = [new EnumPair("S", "12px"), new EnumPair("M", "16px"), new EnumPair("L", "20px"), ];
    this.font.size.setEnum("M");

    this.contained.color.setValue('#FFF');
    this.contained.border.radius.setValue('100vw');
    this.contained.border.color.setValue('#FFFFFF00');
    this.contained.maxWidth.enums = [new EnumPair("S", "200px"), new EnumPair("M", "300px"), new EnumPair("L", "400px"), ];
    this.contained.maxWidth.setEnum("M");

    this.customStyle = '{"background-color": "#000000"}';
  }

  getMeta(): MetaInfo{
    return Style.meta;
  }

  public getDefaultInstance(): BaseItem{
    return new Style();
  }

  public override from(item: BaseItem): void{
    super.from(item);
    const style = item as Style;
    this.font.from(style.font);
    this.contained.from(style.contained);
  }

  getPredefinedFontStyleObject(context: Context) {
    const font = this.font.getPredefinedStyleObject(context);
    font.fontFamily = font.fontFamily!="" ? font.fontFamily : context.selection.app!.theme.fontButton;
    font.fontWeight = font.fontWeight!="" ? font.fontWeight : context.selection.app!.theme.fontButtonWeight;

    return {
      ...font,
      ...{
        textDecoration: 'none',
        padding: '0px 0px 0px 0px',
        textAlign: 'center',
        maxWidth: this.contained.contentMaxWidth.value,
        margin: 'auto',
      }
    };
  }
}